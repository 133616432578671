import { catchError, finalize, map, Observable, take, tap } from 'rxjs';
import { AbstractBusStop } from '../../patterns/bus-stop/abstract-bus-stop';
import { HttpClient } from '@angular/common/http';
import { Bus } from '../../patterns/bus-stop/bus';
import { inject } from '@angular/core';

export class AbstractCounterBusStop<TParams, TResponse> extends AbstractBusStop<TParams, TResponse> {

  #httpClient = inject(HttpClient);

  protected apiURL: string;
  protected registryIdFilterPropName?: string;
  protected registryUidFilterPropName?: string;
  protected singleRequestMethod: 'POST' | 'GET';
  protected batchRequestMethod: 'POST' | 'GET';
  protected singleRequestParamsType: 'query' | 'body';
  protected batchRequestParamsType: 'query' | 'body';
  protected idSingleRequestPath?: string | ((params: TParams) => string);
  protected uidSingleRequestPath?: string | ((params: TParams) => string);
  protected idBatchRequestPath: string | ((params: TParams) => string);
  protected uidBatchRequestPath: string | ((params: TParams) => string);
  protected idSingleParamName?: string;
  protected uidSingleParamName?: string;
  protected idBatchParamName?: string;
  protected uidBatchParamName?: string;

  constructor(config: {
    apiURL: string,
    registryIdFilterPropName?: string,
    registryUidFilterPropName?: string,
    singleRequestMethod?: 'POST' | 'GET',
    batchRequestMethod?: 'POST' | 'GET',
    singleRequestParamsType?: 'query' | 'body',
    batchRequestParamsType?: 'query' | 'body',
    idSingleRequestPath?: string | ((params: TParams) => string),
    uidSingleRequestPath?: string | ((params: TParams) => string),
    idBatchRequestPath: string | ((params: TParams) => string),
    uidBatchRequestPath?: string | ((params: TParams) => string),
    idSingleParamName?: string,
    uidSingleParamName?: string,
    idBatchParamName?: string,
    uidBatchParamName?: string,
  }) {
    super();
    this.apiURL = config.apiURL;
    this.registryIdFilterPropName = config.registryIdFilterPropName ?? config.registryUidFilterPropName ?? 'registryId';
    this.registryUidFilterPropName = config.registryUidFilterPropName ?? config.registryIdFilterPropName ?? 'registryUid';
    this.singleRequestMethod = config.singleRequestMethod ?? 'GET';
    this.batchRequestMethod = config.batchRequestMethod ?? 'GET';
    this.singleRequestParamsType = config.singleRequestParamsType ?? 'query';
    this.batchRequestParamsType = config.batchRequestParamsType ?? 'query';
    this.idSingleRequestPath = config.idSingleRequestPath;
    this.uidSingleRequestPath = config.uidSingleRequestPath;
    this.idBatchRequestPath = config.idBatchRequestPath;
    this.uidBatchRequestPath = config.uidBatchRequestPath;
    this.idSingleParamName = config.idSingleParamName;
    this.uidSingleParamName = config.uidSingleParamName;
    this.idBatchParamName = config.idBatchParamName;
    this.uidBatchParamName = config.uidBatchParamName;
  }

  protected onBusMovingStatusDispatch(bus: Bus<TParams, TResponse>): Observable<TResponse[]> {
    const ids = bus.ids;
    const params = bus.params;
    const isSingle = bus.ids.length < 2;
    const isId = bus.type === 'id';
    const requestParamsType = isSingle ? this.singleRequestParamsType : this.batchRequestParamsType;
    const requestMethod = isSingle ? this.singleRequestMethod : this.batchRequestMethod;
    const requestOptions = (() => {
      const idParamName = (isSingle
        ? (
          isId
            ? (this.idSingleParamName ?? this.uidSingleParamName ?? this.idBatchParamName ?? this.uidBatchParamName)
            : (this.uidSingleParamName ?? this.idSingleParamName ?? this.uidBatchParamName ?? this.idBatchParamName)
        )
        : (
          isId
            ? (this.idBatchParamName ?? this.uidBatchParamName ?? this.idSingleParamName ?? this.uidSingleParamName)
            : (this.uidBatchParamName ?? this.idBatchParamName ?? this.uidSingleParamName ?? this.idSingleParamName)
        )
      ) ?? 'referenceId';
      const defaultParams = Object.keys((params ?? {})).reduce(((_params, key) => {
        if (Array.isArray(params[key]) || ['number', 'string', 'boolean'].includes(typeof params[key])) {
          _params[key] = params[key];
        }
        return _params;
      }), {});
      if (requestParamsType === 'query') {
        return {
          params: {
            ...defaultParams,
            [idParamName]: ids,
          }
        };
      }
      return {
        body: ids.map((id) => ({
          ...defaultParams,
          [idParamName]: id,
        })),
      };
    })();
    let path = isSingle ? (isId ? (this.idSingleRequestPath ?? this.uidSingleRequestPath) : (this.uidSingleRequestPath ?? this.idSingleRequestPath)) : null;
    path = path ? path : (isId ? (this.idBatchRequestPath ?? this.uidBatchRequestPath) : (this.uidBatchRequestPath ?? this.idBatchRequestPath));
    const requestURL = `${this.apiURL}${typeof path === 'function' ? path(params) : path}`;

    return this.#httpClient.request<TResponse[]>(requestMethod, requestURL, requestOptions);
  }

  protected getCountByIdOrUid(registryId: number | string, params: TParams): Observable<TResponse[]> {
    return new Observable((subscriber) => {
      const bus = this.getAvailableBusAtTheStop(registryId, params);
      bus.addId(registryId);
      const subscription = bus.result$.pipe(
        map((result) => {
          if (result.length > 0 && typeof result[0] === 'object') {
            const isId = bus.type === 'id';
            let idPropName = isId ? this.registryIdFilterPropName : this.registryUidFilterPropName;
            if (idPropName in result[0]) {
              return result.filter((item) => {
                if (isId && typeof item[idPropName] === 'string' && !isNaN(parseInt(item[idPropName]))) {
                  item[idPropName] = parseInt(item[idPropName]);
                }
                return item[idPropName] === registryId;
              });
            }
          }
          return result;
        }),
        tap((result) => {
          subscriber.next(result);
        }),
        catchError((error) => {
          subscriber.error(error);
          return error;
        }),
        finalize(() => {
          subscriber.complete();
        })
      ).subscribe();

      return () => {
        if (!subscriber.closed) {
          bus.removeId(registryId);
          subscription.unsubscribe();
        }
      }
    }).pipe(
      take<TResponse[]>(1),
    );
  }
}

