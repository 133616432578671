import { Component, HostBinding, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
})
export class NotFound404Component {
  @HostBinding('class.svc-005F86') svcThemeClass = true;

  public isBacking: boolean;
  public termsLoaded = false;

  #router = inject(Router);
  #translocoService = inject(TranslocoService);

  constructor() {
    if (this.#translocoService.getTranslation().size > 0) {
      this.termsLoaded = true;
    } else {
      this.#translocoService.events$.subscribe((e) => {
        this.termsLoaded = e.type === 'translationLoadSuccess';
      });
    }
  }

  public back() {
    this.isBacking = true;
    this.#router.navigate(['/']);
  }
}
