import {deleteCookie, setCookie, setCookieWithOptions} from "./cookie-reader";

export function updateLastSiteIdCookie(cookieValue: string | number){
  setCookie('ABD1F88FED3A461FBA8796E0C68F69FD', cookieValue);
}

export function updateDateFormatCookie(cookieValue: string | number){
  setCookie('E778864F94F94C318242045395455149', cookieValue);
}

export function updateLanguageIdCookie(cookieValue: string | number){
  setCookie('36EB586E8D3A4C9FB40C10D86B49EE98', cookieValue);
}

export function updateUserIdCookie(cookieValue: string | number){
  setCookie('7BA02437128B4F328A80DF78676E65E0', cookieValue);
}

export function updateEnvironmentFullURLCookie(cookieValue: string | number){
  setCookie('E9A21238833049C3907623DD86D2E732', cookieValue);
}

export function updateProductAPITokenCookie(cookieValue: string | number){
  setCookie('EE3B69BC-D275-4D89-ACCD-D91136DAD45C', cookieValue);
}

export function updateIntegrationAPITokenCookie(cookieValue: string | number){
  setCookie('2906AC24C7324A2EA1BC2FF27CB08F9A', cookieValue);
}

export function updateIntegrationAPIURLCookie(cookieValue: string | number){
  setCookie('F446E9D07E364203B3DCD9590D559A02', cookieValue);
}

export function updateReloadSessionCookie(cookieValue: string | number){
  setCookie('5E32D867A42C48358F909F85F78056CE', cookieValue);
}

export function updateIdleTimeoutCookie(cookieValue: string | number){
  setCookie('399E3DB191D941ACA3B84EBD5B69F167', cookieValue);
}

export function updateLastAccessCookie(cookieValue: Date){
  setCookie('A9673308967047B883713587A240F0DF', encodeURIComponent(`${cookieValue.getUTCDate().toString().padStart(2, '0')}/${(cookieValue.getUTCMonth() + 1).toString().padStart(2, '0')}/${cookieValue.getUTCFullYear()}+${cookieValue.getUTCHours().toString().padStart(2, '0')}:${cookieValue.getUTCMinutes().toString().padStart(2, '0')}:${cookieValue.getUTCSeconds().toString().padStart(2, '0')}`));
}

export function updateBookmarkAPIUrlCookie(cookieValue: string | number){
  setCookie('11B105E6-4058-4612-849D-8EEDF634FF1E', cookieValue);
}

export function updateDocumentAPIUrlCookie(cookieValue: string | number){
  setCookie('A5114064-A94D-4B9F-8E58-C1F37F854F19', cookieValue);
}

export function updateNotificationAPIUrlCookie(cookieValue: string | number){
  setCookie('D15593BF-75C0-4288-802A-FADB1F04AD4C', cookieValue);
}

export function updateAuthenticationAPIUrlCookie(cookieValue: string | number){
  setCookie('FF08A2A8-3AB1-4080-B735-19F4192DD884', cookieValue);
}

export function updateHashtagAPIUrlCookie(cookieValue: string | number){
  setCookie('212471D9-6ABD-4193-B278-1BE0BB2CFD79', cookieValue);
}

export function updatePraiseAPIUrlCookie(cookieValue: string | number){
  setCookie('B6BA4E5C-130F-48F3-9703-4A4FA6C5C371', cookieValue);
}

export function updateReactionAPIUrlCookie(cookieValue: string | number){
  setCookie('5DEE69EA-97B9-4323-81BF-F3C932986859', cookieValue);
}

export function updateCommentsAPIUrlCookie(cookieValue: string | number){
  setCookie('5F222681-52F4-4E64-A5A6-EE6475A1DDCB', cookieValue);
}

export function updateActionPlanAPIUrlCookie(cookieValue: string | number){
  setCookie('CCE63EEF-DAE0-4CF7-B8F2-10FB9115DDF6', cookieValue);
}

export function updateChecklistAPIUrlCookie(cookieValue: string | number){
  setCookie('260B3A2F-54E8-4C74-8ADE-19DFBCADDEF4', cookieValue);
}

export function updateCilAPIUrlCookie(cookieValue: string | number){
  setCookie('793D1C4D-467F-4B6F-9604-135F3E39710B', cookieValue);
}

export function updateDefectTagAPIUrlCookie(cookieValue: string | number){
  setCookie('9F922998-2C18-48FE-8F95-B8AF93E70347', cookieValue);
}

export function updateAdministrationAPIUrlCookie(cookieValue: string | number){
  setCookie('EC53C247-61C3-4BEA-ACBB-CB280D204A22', cookieValue);
}

export function updateCommentManagerAPIUrlCookie(cookieValue: string | number){
  setCookie('0FEBDDC3-F01B-471F-BC0D-E0C90D10D26C', cookieValue);
}

export function updateMultilingualAPIUrlCookie(cookieValue: string | number){
  setCookie('BE51A3FB-50C1-4DD7-9C63-E6FF2EDE577E', cookieValue);
}

export function updateCloudFrontPolicyCookie(cookieValue: string | number, cookieExpiryTime: string, cookieDomain: string){
  setCookieWithOptions('CloudFront-Policy', cookieValue, cookieExpiryTime, cookieDomain);
  setCookieWithOptions('env-CloudFront-Policy', cookieValue, cookieExpiryTime, '');
}

export function updateCloudFrontSignatureCookie(cookieValue: string | number, cookieExpiryTime: string, cookieDomain: string){
  setCookieWithOptions('CloudFront-Signature', cookieValue, cookieExpiryTime, cookieDomain);
  setCookieWithOptions('env-CloudFront-Signature', cookieValue, cookieExpiryTime, '');
}

export function updateCloudFrontKeyPairIdCookie(cookieValue: string | number, cookieExpiryTime: string, cookieDomain: string){
  setCookieWithOptions('CloudFront-Key-Pair-Id', cookieValue, cookieExpiryTime, cookieDomain);
  setCookieWithOptions('env-CloudFront-Key-Pair-Id', cookieValue, cookieExpiryTime, '');
}

export function deleteLegacyCookies(){
  const cookiesToDelete = [
    'ABD1F88FED3A461FBA8796E0C68F69FD',
    'E778864F94F94C318242045395455149',
    '36EB586E8D3A4C9FB40C10D86B49EE98',
    '7BA02437128B4F328A80DF78676E65E0',
    'E9A21238833049C3907623DD86D2E732',
    '2906AC24C7324A2EA1BC2FF27CB08F9A',
    '5E32D867A42C48358F909F85F78056CE',
    'F446E9D07E364203B3DCD9590D559A02',
    '399E3DB191D941ACA3B84EBD5B69F167',
    'A9673308967047B883713587A240F0DF',
    'EE3B69BC-D275-4D89-ACCD-D91136DAD45C',
    '11B105E6-4058-4612-849D-8EEDF634FF1E',
    'A5114064-A94D-4B9F-8E58-C1F37F854F19',
    'D15593BF-75C0-4288-802A-FADB1F04AD4C',
    'FF08A2A8-3AB1-4080-B735-19F4192DD884',
    '212471D9-6ABD-4193-B278-1BE0BB2CFD79',
    'B6BA4E5C-130F-48F3-9703-4A4FA6C5C371',
    '5DEE69EA-97B9-4323-81BF-F3C932986859',
    '5F222681-52F4-4E64-A5A6-EE6475A1DDCB',
    'CloudFront-Signature',
    'CloudFront-Policy',
    'CloudFront-Key-Pair-Id',
  ];

  cookiesToDelete.forEach((cookie) => deleteCookie(cookie));
}
