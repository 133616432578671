import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { forkJoin, map, Observable } from 'rxjs';

import { AppEnvironmentConfig } from '../../../../config/model/environment.config.model';
import { SvcBookmarkListById, SvcBookmarkListByUid, SvcBookmarkRegistry } from '../interfaces/svc-bookmark.interface';
import { SvcBookmarkStatusService } from './counters/bookmark/svc-bookmark-status.service';

@Injectable({
  providedIn: 'root'
})
export class SvcBookmarkService {

  private readonly _apiUrlBookmark: string = this._appConfig.APIs.apiUrlBookmark;

  constructor(
    private _appConfig: AppEnvironmentConfig,
    private _httpClient: HttpClient,
    private _bookmarkStatusService: SvcBookmarkStatusService,
  ) { }

  public getBookmarkListByIds(applicationId: string, siteId: number, registryUniqueIds: number[]): Observable<SvcBookmarkListById[]> {
    return forkJoin(registryUniqueIds.map((registryId) => this._bookmarkStatusService.getStatusByRegistryId(
      registryId,
      {
        applicationId,
        siteId,
      }
    ))).pipe(
      map((response) => {
        return response.map((item) => (<SvcBookmarkListById>{
          ...item,
        }));
      }),
    );
  }

  public getBookmarkListByUIds(applicationId: string, siteId: number, registryUniqueUIds: string[]): Observable<SvcBookmarkListByUid[]> {
    return forkJoin(registryUniqueUIds.map((registryUId) => this._bookmarkStatusService.getStatusByRegistryId(
      registryUId,
      {
        applicationId,
        siteId,
      }
    ))).pipe(
      map((response) => {
        return response.map((item) => (<SvcBookmarkListByUid>{
          ...item,
        }));
      }),
    );
  }

  public deleteBookmarkRegistryId(registryUniqueId: number, applicationId: string, siteId: number): Observable<SvcBookmarkRegistry[]> {
    return this._httpClient.delete<SvcBookmarkRegistry[]>(`${this._apiUrlBookmark}/RegistryId`, {
      body: {
        registryUniqueId,
        applicationId,
        siteId
      }
    });
  }

  public deleteBookmarkRegistryUid(registryUniqueUId: string, applicationId: string, siteId: number): Observable<SvcBookmarkRegistry[]> {
    return this._httpClient.delete<SvcBookmarkRegistry[]>(`${this._apiUrlBookmark}/RegistryUid`, {
      body: {
        registryUniqueUId,
        applicationId,
        siteId
      }
    });
  }

  public addBookmarkRegistryId(registryUniqueId: number, applicationId: string, siteId: number): Observable<SvcBookmarkRegistry[]> {
    return this._httpClient.post<SvcBookmarkRegistry[]>(`${this._apiUrlBookmark}/RegistryId`, {
      registryUniqueId,
      applicationId,
      siteId
    });
  }

  public addBookmarkRegistryUid(registryUniqueUId: string, applicationId: string, siteId: number): Observable<SvcBookmarkRegistry[]> {
    return this._httpClient.post<SvcBookmarkRegistry[]>(`${this._apiUrlBookmark}/RegistryUid`, {
      registryUniqueUId,
      applicationId,
      siteId
    });
  }

  public getBookmarkListId(applicationId: string, siteId: number): Observable<SvcBookmarkListById[]> {
    return this._httpClient.get<SvcBookmarkListById[]>(`${this._apiUrlBookmark}/ListId`, {
      params: {
        applicationId,
        siteId
      }
    });
  }

  public getBookmarkListUid(applicationId: string, siteId: number): Observable<SvcBookmarkListByUid[]> {
    return this._httpClient.get<SvcBookmarkListByUid[]>(`${this._apiUrlBookmark}/ListUId`, {
      params: {
        applicationId,
        siteId
      }
    });
  }
}
