import { Component, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { SvcTreeBranchComponent } from '../svc-tree-branch/svc-tree-branch.component';
import { SvcTreeComponent, SvcTreeItem, SvcTreeOrientation } from '../svc-tree.component';
import { isNullOrUndefined } from 'projects/lib-shared-common/src/lib/functions/helper-functions';

@Component({
  selector: 'svc-tree-entry',
  templateUrl: './svc-tree-entry.component.html',
  styleUrls: ['./svc-tree-entry.component.scss']
})
export class SvcTreeEntryComponent implements OnInit, OnChanges, OnDestroy {

  @HostBinding('class.svc-tree-entry') protected svcClass = true;
  @HostBinding('class.is-vertical') protected isVertical = true;
  @HostBinding('class.is-horizontal') protected isHorizontal = false;

  @Input() input: SvcTreeItem<any>;
  @Input('orientation') set setOrientation(value: SvcTreeOrientation) {
    this.orientation = value;
    this.#onOrientationChanged(value);
  }

  protected orientation = SvcTreeOrientation.VERTICAL;
  public entry: SvcTreeBranchComponent;
  public showBranches: boolean = true;

  constructor(
    @Inject(forwardRef(() => SvcTreeComponent))
    public parentTree: SvcTreeComponent,
    @Inject(forwardRef(() => SvcTreeBranchComponent))
    public parentBranch: SvcTreeBranchComponent,
  ){
    this.entry = this as any;
  }

  public ngOnInit(): void {
    this.showBranches = !isNullOrUndefined(this.input?.forceStartOpened)
      ? this.input?.forceStartOpened
      : this.parentTree.startOpened && (this.input?.children ?? []).length > 0;
  }

  public ngOnChanges(): void {
  }

  public toggleVisibility() {
    if (this.input.children?.length) {
      this.showBranches = !this.showBranches;
    }
  }
  
  #onOrientationChanged(orientation: SvcTreeOrientation) {
    this.isVertical = orientation === SvcTreeOrientation.VERTICAL;
    this.isHorizontal = orientation === SvcTreeOrientation.HORIZONTAL;
  }

  public addChildren(children: SvcTreeItem<any>[]) {
    if (!children?.length) return;
    if (!this.input.children?.length) this.input.children = [];
    this.input.children.push(...children);
  }

  public ngOnDestroy(): void {
  }
}
