import { catchError, finalize, Observable, Subscription, tap } from 'rxjs';
import { Bus, OnMovingStatusResult } from './bus';

export abstract class AbstractBusStop<TParams, TResponse> {

  #buses: Bus<TParams, TResponse>[] = [];

  constructor() {}

  protected abstract onBusMovingStatusDispatch(bus: Bus<TParams, TResponse>): Observable<TResponse[]>;

  protected getAvailableBusAtTheStop(id: number | string, params?: TParams): Bus<TParams, TResponse> {
    const type = this.getTypeFromId(id);
    let bus = this.#buses.find(b =>
      (b.type === type && JSON.stringify(b.params ?? {}) === JSON.stringify(params ?? {})) &&
      (b.status === 'awaiting' || (b.status === 'moving' && b.ids.includes(id)))
    );
    if (!bus) {
      bus = this.#requestNewBus(type, params);
    }
    return bus;
  }

  protected getBuses(): Bus<TParams, TResponse>[] {
    return this.#buses;
  }

  protected getRegistryIdBus(id: number | string, params?: TParams): Bus<TParams, TResponse> {
    const type = this.getTypeFromId(id);
    let bus = this.#buses.find(b =>
      (b.type === type && JSON.stringify(b.params ?? {}) === JSON.stringify(params ?? {})) &&
      b.ids.includes(id)
    );
    return bus;
  }

  protected getTypeFromId(id: number | string): 'uid' | 'id' {
    return typeof id === 'string' ? 'uid' : 'id';
  }

  #requestNewBus(type: 'id' | 'uid', params: TParams): Bus<TParams, TResponse> {
    let bus = new Bus<TParams, TResponse>({ type, params });
    bus.onMovingStatusDispatch((success, error, complete): Subscription => {
      return this.onBusMovingStatusDispatch(bus).pipe(
        tap((response) => {
          response = Array.isArray(response) ? response : [response];
          success(response);
        }),
        catchError((e) => {
          error(e);
          throw e;
        }),
        finalize(() => {
          complete();
          this.#removeDeliveredBuses();
        }),
      ).subscribe();
    });
    this.#buses.push(bus);
    return bus;
  }

  #removeDeliveredBuses() {
    this.#buses = this.#buses.filter(b => b.status !== 'delivered');
  }
}

