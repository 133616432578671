<!-- Toast manager -->
<svc-toast></svc-toast>

<!-- Loading bar -->
<loading-bar></loading-bar>

<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden" *screenSizes="let size">
  <div class="relative flex flex-0 items-center w-full h-16 z-49 shadow bg-primary text-on-primary">
    <div class="flex w-full px-2 lg:px-4" [style.maxWidth.px]="(feedIsEnabled && windowWidth >= 1782) ? 1782 : 1440" [style.margin]="'auto'">
      <button class="md:hidden mr-3" mat-icon-button (click)="toggleNavigation()">
        <mat-icon class="text-current" svgIcon="heroicons_solid:bars-3"></mat-icon>
      </button>

      <div class="flex" [style.width.px]="size.isAboveSM ? 250 : null" [class.-ml-2]="size.isAboveSM && size.isBelowLG">
        <img class="max-w-28 m-auto" [src]="environment.logoImageNegative"/>
      </div>

      <!-- Components -->
      <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2" *transloco="let t">
        <svc-notifications></svc-notifications>
        <sites *ngIf="!size.isBelowMD"></sites>
        <user [showAvatar]="!size.isBelowMD" [showSites]="size.isBelowMD"></user>
        <button *ngIf="!size.isBelowLG" mat-icon-button (click)="toggleMenu()">
          <mat-icon class="text-current" [svgIcon]="'mat_solid:apps'"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="flex flex-auto w-full m-auto overflow-y-auto" *screenSizes="let size" [style.maxWidth.px]="(feedIsEnabled && windowWidth >= 1782) ? 1782 : 1440" [style.margin]="'auto'">
    <div class="flex flex-col overflow-y-auto lg:pl-4 lg:pt-4">
      <!-- Navigation -->
      <vertical-navigation class="bg-primary-200 overflow-auto h-full lg:rounded-t-lg lg:z-49"
        [mode]="size.isBelowMD ? 'over' : 'side'"
        [opened]="!size.isBelowMD"
        [headerWithOverflow]="false"
      >
        <!-- Navigation header hook -->
        <ng-container verticalNavigationContentHeader>
          <div class="flex md:hidden justify-end">
            <button
              mat-icon-button
              (click)="toggleNavigation()"
            >
              <mat-icon
                class="text-on-primary-200 icon-size-4"
                [svgIcon]="'mat_solid:close'"
              ></mat-icon>
            </button>
          </div>

          <!-- User -->
          <div class="mt-3">
            <user-card></user-card>
          </div>
          <div class="mt-3">
            <ng-container *ngIf="!navigationGotError">
              <user-navigation class="flex flex-col gap-y-2 py-1" *ngIf="!navigationIsLoading"
                [navigation]="navigation?.workspace"
                (openPraiseReceived)="openPraiseReceived()"
                (emitCounters)="getCounters($event)">
              </user-navigation>

              <!-- <div *ngIf="navigationIsLoading" class="flex justify-center">
                <mat-spinner class="mt-10" diameter="30"></mat-spinner>
              </div> -->
            </ng-container>

            <div *ngIf="navigationGotError" class="flex justify-center items-center rounded-lg border shadow p-5 bg-white">
              <div *ngIf="!navigationIsLoading" class="flex gap-2 justify-center items-center font-normal cursor-pointer" (click)="reloadUserNavigation()">
                <mat-icon class="icon-size-4 text-primary" svgIcon="fontawesome_solid:triangle-exclamation"></mat-icon>
                <span class="text-base text-primary font-bold underline">{{ 'Atualize a página' | transloco }}</span>
              </div>
            </div>

            <div class="flex justify-center">
              <mat-spinner class="my-3" diameter="18" *ngIf="navigationIsLoading"></mat-spinner>
            </div>

          </div>
          <user-hashtags class="mt-3 overflow-hidden"></user-hashtags>
        </ng-container>
        <ng-container verticalNavigationFooter>
          <div class="flex flex-col items-center justify-center mt-2 mb-3">
            <hr class="w-full m-0 border-b-0"/>
            <div class="flex px-5 pt-3">
              <img
                class="max-w-40"
                src="https://solvacelabs-webcomponents.s3.amazonaws.com/images/company-logo-ng.svg"
              />
            </div>
          </div>
        </ng-container>
      </vertical-navigation>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
            Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!--Apps panel-->
    <apps-panel #appsPanel="appsPanel"></apps-panel>
    <float-add-button #floatAddButton bottomCssClass="bottom-20 lg:bottom-10"></float-add-button>
    <sidebar-navigation
      #sidebarNavigationComponent
      class="lg:pr-4"
      [class.pt-2]="feedIsEnabled && windowWidth < 1782"
      [class.pt-4]="!feedIsEnabled || windowWidth >= 1782"
      [mode]="size.isAboveMD ? 'side' : 'over'"
      [opened]="size.isAboveMD"
      [showSideBySide]="feedIsEnabled && windowWidth >= 1782"
      (onKaizenResumeClicked)="onKaizenResumeClicked()"
    ></sidebar-navigation>

  </div>

  <mobile-menu
    (menuClick)="mobileMenuClick($event)"
    [totalTasks]="sidebarNavigationComponent?.totalTasks"
    [loadingTasks]="sidebarNavigationComponent?.loadingTasks ?? true"
    [totalFeeds]="sidebarNavigationComponent?.totalFeeds"
    [loadingFeeds]="sidebarNavigationComponent?.loadingFeeds ?? true"
  ></mobile-menu>
</div>

<router-outlet name="modal"></router-outlet>
