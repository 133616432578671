import { Injectable } from '@angular/core';
import { CommonCounter, CommonIndicator } from 'projects/lib-shared-model/src/public-api';
import { Observable, Subject, catchError, map, of, tap, throwError } from 'rxjs';
import { ActionPlanCategorizedDrillDown } from './models/actionplan-drilldown';
import { ActionPlanIndicator } from './models/actionplan-indicator';
import { ModuleHttpClient } from './module-http-client';
import { ActionPlanCountersByIdsFromModule } from './models/action-plan-counters.interface';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class LibServiceActionplanAnalyticsService {

  private _indicator: Subject<ActionPlanIndicator> = new Subject<ActionPlanIndicator>();
  private _approvalsIndicator: Subject<CommonCounter[]> = new Subject<CommonCounter[]>();
  private _subordinatesIndicator: Subject<ActionPlanIndicator> = new Subject<ActionPlanIndicator>();
  private _categorizedDrillDown: Subject<ActionPlanCategorizedDrillDown[]> = new Subject<ActionPlanCategorizedDrillDown[]>();

  constructor(
    private _httpClient: ModuleHttpClient,
  ) {}

  public _indicator$: Observable<ActionPlanIndicator> = this._indicator.asObservable();
  public _subordinatesIndicator$: Observable<ActionPlanIndicator> = this._subordinatesIndicator.asObservable();
  public _categorizedDrillDown$: Observable<ActionPlanCategorizedDrillDown[]> = this._categorizedDrillDown.asObservable();
  public _approvalsIndicator$: Observable<CommonCounter[]> = this._approvalsIndicator.asObservable();

  getActionPlanIndicators(): Observable<ActionPlanIndicator> {
    this._indicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/actionplan').pipe(
      map((response: CommonIndicator) => new ActionPlanIndicator(response)),
      tap((indicator) => {
        this._indicator.next(indicator);
      }),
      catchError((error) => {
        this._indicator.next(new ActionPlanIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getActionPlanSubordinatesIndicators(): Observable<ActionPlanIndicator> {
    this._subordinatesIndicator.next(null);
    return this._httpClient.get<CommonIndicator>('/Counters/actionplan/subordinates').pipe(
      map((response: CommonIndicator) => new ActionPlanIndicator(response)),
      tap((indicator) => {
        this._subordinatesIndicator.next(indicator);
      }),
      catchError((error) => {
        this._subordinatesIndicator.next(new ActionPlanIndicator({ hasError: true }));
        return throwError(() => error);
      }),
    );
  }

  getActionPlanApprovalsIndicator(): Observable<CommonCounter[]> {
    return this._httpClient.get<CommonCounter[]>('/Counters/actionplan/approvals').pipe(
        tap((indicator) => {
          this._approvalsIndicator.next(indicator);
        })
    );
  }

  getActionPlanCategorizedDrillDown(): Observable<ActionPlanCategorizedDrillDown[]> {
    return this._httpClient.get<ActionPlanCategorizedDrillDown[]>('/Analytics/actionplan/details').pipe(
      map((response: ActionPlanCategorizedDrillDown[]) => response.map(r => {
        return {
          ...r,
          indicatorItems: r.indicatorItems.map(i => ({
            ...i,
            owners: [i['owner']]
          }))
        };
      })),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }

  getActionPlanCategorizedDrillDownSubordinates(): Observable<ActionPlanCategorizedDrillDown[]> {
    return this._httpClient.get<ActionPlanCategorizedDrillDown[]>('/Analytics/actionplan/subordinates/details').pipe(
        map((response: ActionPlanCategorizedDrillDown[]) => response.map(r => {
          return {
            ...r,
            indicatorItems: r.indicatorItems.map(i => ({
              ...i,
              owners: [i['owner']]
            }))
          };
        })),
      tap((indicator) => {
        this._categorizedDrillDown.next(indicator);
      })
    );
  }

  public getCountersByIdsFromModule(applicationId: string, ids: number[]): Observable<ActionPlanCountersByIdsFromModule[]> {
    let params = new HttpParams();

    params = params.append('ApplicationId', applicationId);

    ids?.forEach(id =>
      params = params.append('Ids', id)
    );

    return this._httpClient.get(`/Counters/actionplan/CountersByIdsFromModule`, {
      params
    });
  }
}
