import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError, finalize, Subscription, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import _ from 'lodash';
import { ISvcChipGroupedOption, ISvcChipOption, ISvcUserAvatar } from 'projects/lib-shared-component/src/public-api';
import { SettingsService, SvcUserAvatarService } from 'projects/lib-shared-core/src/public-api';
import { getCustomThemeColor } from 'projects/lib-shared-common/src/public-api';
import { UserInfo } from './models/user-info.model';

@Component({
  selector: 'svc-user-picker',
  templateUrl: './svc-user-picker.component.html',
  styleUrls: ['./svc-user-picker.component.scss'],
})
export class SvcUserPickerComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public formControl: FormControl;
  @Input() public set ids(value: string | string[] | { usersIds: string[], teamsIds: number[], areasIds: number[], }) {
    value = value ?? [];
    let usersIds = [], teamsIds = [], areasIds = [];
    if (Array.isArray(value) || typeof value === 'string') {
      usersIds = (Array.isArray(value) ? value : [value]).filter(v => !!(v));
      teamsIds = [];
      areasIds = [];
    }
    else {
      usersIds = value?.usersIds?.filter(v => !!(v)) ?? [];
      teamsIds = value?.teamsIds?.filter(v => !!(v)) ?? [];
      areasIds = value?.areasIds?.filter(v => !!(v)) ?? [];
    }
    if (JSON.stringify({ userIds: usersIds, teamsIds, areasIds }) !== JSON.stringify({ userIds: this.usersIds, teamsIds: this.teamsIds, areasIds: this.areasIds })) {
      this.usersIds = usersIds;
      this.teamsIds = teamsIds;
      this.areasIds = areasIds;
      this.length = (usersIds ?? []).length + (teamsIds ?? []).length + (areasIds ?? []).length;
      this.#loadUserInfo();
    }
  }
  @Input() label: string | { text: string, icon: string };
  @Input() icon: string;
  @Input() type: 'single' | 'multiple' = 'multiple';
  @Input() readonly: boolean = false;
  @Input() inputId: string;
  @Input() placeholder: string;
  @Input() fallbackTextValue: string = '-';
  @Output() public onLoadingChange = new EventEmitter<boolean>();

  #service = inject(SvcUserAvatarService);
  #translocoService = inject(TranslocoService);
  #requestSubscription: Subscription;
  #onInitWasCalled = false;
  #fallbackInitialColors = getCustomThemeColor(inject(SettingsService).theme$, 500);

  public isLoading: boolean = false;
  public isGrouped: boolean = false;
  protected length: number = 0;
  protected usersIds: string[] = [];
  protected teamsIds: number[] = [];
  protected areasIds: number[] = [];
  protected chipOptions: ISvcChipOption[] = [];
  protected chipGroupedOptions: ISvcChipGroupedOption[] = [];

  public ngOnInit(): void {
    this.#onInitWasCalled = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.#onInitWasCalled) {
    }
  }

  public refresh() {
    if (!this.isLoading) {
      this.#loadUserInfo();
    }
  }

  #loadUserInfo() {
    this.#requestSubscription?.unsubscribe();
    if (this.length > 0) {
      this.isLoading = true;
      this.#requestSubscription = this.#service.getUserInfoByIds({
        userIds: this.usersIds,
        teamIds: this.teamsIds,
        areaIds: this.areasIds,
      }).pipe(
        tap((res) => {
          this.isGrouped = (Math.min((this.usersIds ?? []).length, 1) + Math.min((this.teamsIds ?? []).length, 1) + Math.min((this.areasIds ?? []).length, 1)) > 1;
          if (this.isGrouped) {
            this.#mountChipGroupedOption(res);
          }
          else {
            this.#mountChipOption(res);
          }
        }),
        catchError((error) => {
          this.isGrouped = false;
          this.#mountChipOption([]);
          return error;
        }),
        finalize(() => {
          this.#requestSubscription = null;
          this.isLoading = false;
        }),
      ).subscribe();
    }
    else {
      this.isLoading = false;
      this.isGrouped = false;
      this.chipGroupedOptions = [];
      this.chipOptions = [];
    }
  }

  #mountChipOption(usersInfo: UserInfo[]) {
    this.chipGroupedOptions = [];
    this.chipOptions = usersInfo.map((ui) => ({
      label: ui.name,
      value: ui.id,
      data: this.#generateUserAvatarObject(ui),
    }));
  }

  #mountChipGroupedOption(usersInfo: UserInfo[]) {
    this.chipOptions = [];
    const groupedByType = _.groupBy(usersInfo, 'type');
    let groups: ISvcChipGroupedOption[] = [];
    for (const type of Object.keys(groupedByType).sort((type) => parseInt(type ?? '0'))) {
      const usersInfo = groupedByType[type];
      groups.push({
        group: this.#translocoService.translate(type === '3' ? 'Times' : (type === '2' ? 'Áreas' : 'Usuários')),
        options: usersInfo.map((ui) => ({
          label: ui.name,
          value: ui.id,
          data: this.#generateUserAvatarObject(ui),
        })),
      });
    }
    this.chipGroupedOptions = groups;
  }

  #generateUserAvatarObject(userInfo: UserInfo): ISvcUserAvatar {
    return {
      name: userInfo.name,
      picture: userInfo.pictureFile,
      initialsColor: (userInfo.initialColor ?? '') !== '' ? userInfo.initialColor : this.#fallbackInitialColors,
      type: userInfo.type,
      size: 24,
    };
  }

  ngOnDestroy(): void {
    this.#requestSubscription?.unsubscribe();
  }
}
