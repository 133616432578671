import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'svc-audio-to-text-wrapper',
  templateUrl: './svc-audio-to-text-wrapper.component.html',
  styleUrls: ['./svc-audio-to-text-wrapper.component.scss'],
})
export class SvcAudioToTextWrapperComponent implements OnInit {

  @Input()
  public control!: FormControl;
  @Input()
  public small: boolean = true;
  @Input()
  public disabled: boolean = false;
  @Input()
  public showVoiceToTextButton: boolean = true;
  @Input()
  public canChooseRecording: boolean = false;
  @Input()
  public shadow: boolean = false;
  @Input()
  public customClass: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
