import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { fishbonePartDelayStep, getPropNameByType } from './svc-fishbone-functions';
import { SvcFishboneItemsByType } from './interfaces/svc-fishbone-items-by-type.class';
import { SvcFishboneItem } from './interfaces/svc-fishbone-item.interface';
import { SvcFishboneAddEvent, SvcFishboneDeleteEvent, SvcFishboneEditEvent, SvcFishboneEffectEditEvent } from './interfaces/svc-fishbone-event.interface';

export enum SvcFishboneType {
  MATERIAL = 1,
  MACHINE = 2,
  ENVIRONMENT = 3,
  METHOD = 4,
  PEOPLE = 5,
  MEASUREMENTS = 6,
}

@Component({
  selector: 'svc-fishbone',
  templateUrl: './svc-fishbone.component.html',
  styleUrls: ['./svc-fishbone.component.scss'],
  animations: [
    trigger('arrowFadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-30px)' }),
        animate('{{time}}ms {{delay}}ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class SvcFishboneComponent {

  @HostBinding('class.svc-fishbone') private internalClass: boolean = true;

  @Input() editMode = false;
  @Input('items') set setItems(value: SvcFishboneItemsByType[]) {
    this.material = value?.find(item => item.type === SvcFishboneType.MATERIAL)?.items?.slice(0, 10) ?? [];
    this.machine = value?.find(item => item.type === SvcFishboneType.MACHINE)?.items?.slice(0, 10) ?? [];
    this.environment = value?.find(item => item.type === SvcFishboneType.ENVIRONMENT)?.items?.slice(0, 10) ?? [];
    this.method = value?.find(item => item.type === SvcFishboneType.METHOD)?.items?.slice(0, 10) ?? [];
    this.people = value?.find(item => item.type === SvcFishboneType.PEOPLE)?.items?.slice(0, 10) ?? [];
    this.measurements = value?.find(item => item.type === SvcFishboneType.MEASUREMENTS)?.items?.slice(0, 10) ?? [];
  }
  @Input() effect: string;
  @Output() onItemAddRequested = new EventEmitter<SvcFishboneAddEvent>();
  @Output() onItemEditRequested = new EventEmitter<SvcFishboneEditEvent>();
  @Output() onItemDeleteRequested = new EventEmitter<SvcFishboneDeleteEvent>();
  @Output() onEffectEditRequested = new EventEmitter<SvcFishboneEffectEditEvent>();

  protected material: SvcFishboneItem[] = [];
  protected machine: SvcFishboneItem[] = [];
  protected environment: SvcFishboneItem[] = [];
  protected method: SvcFishboneItem[] = [];
  protected people: SvcFishboneItem[] = [];
  protected measurements: SvcFishboneItem[] = [];
  protected topParts = [
    SvcFishboneType.PEOPLE,
    SvcFishboneType.ENVIRONMENT,
    SvcFishboneType.MATERIAL,
  ].map((type) => ({ type, propName: getPropNameByType(type) }));
  protected bottomParts = [
    SvcFishboneType.MEASUREMENTS,
    SvcFishboneType.METHOD,
    SvcFishboneType.MACHINE,
  ].map((type) => ({ type, propName: getPropNameByType(type) }));

  public get items(): SvcFishboneItem[] {
    return [
      ...(this.topParts.reduce((list, part) => [...list, ...(this[part.propName] ?? [])], [])),
      ...(this.bottomParts.reduce((list, part) => [...list, ...(this[part.propName] ?? [])], [])),
    ];
  }

  protected isLoading = false;
  protected time = fishbonePartDelayStep;
  protected delay = this.time * 6;

  protected onItemAddWasRequested(fromType: SvcFishboneType) {
    if (this.editMode) {
      this.onItemAddRequested.emit({
        type: fromType,
        setLoading: (loading) => this.isLoading = loading,
        onAdded: (values: { item: SvcFishboneItem, onTop?: boolean }) => {
          this.insertItemByType({
            item: values.item,
            onTop: values.onTop,
            type: fromType,
          });
        }
      });
    }
  }

  protected onItemEditWasRequested(item: SvcFishboneItem, fromType: SvcFishboneType) {
    if (this.editMode) {
      this.onItemEditRequested.emit({
        item,
        type: fromType,
        setLoading: (loading) => this.isLoading = loading,
        onEdited: (item: SvcFishboneItem) => this.updateItemByType(item, fromType),
      });
    }
  }

  protected onItemDeleteWasRequested(item: SvcFishboneItem, fromType: SvcFishboneType) {
    if (this.editMode) {
      const propName = getPropNameByType(fromType);
      this.onItemDeleteRequested.emit({
        item,
        type: fromType,
        setLoading: (loading) => this.isLoading = loading,
        onDeleted: () => this.deleteItemByType(item, fromType),
      });
    }
  }

  protected editEffect() {
    if (this.editMode) {
      this.onEffectEditRequested.emit({
        effect: this.effect,
        setLoading: (loading) => this.isLoading = loading,
        onEdited: (effect: string) => {
          this.setEffect(effect);
        }
      });
    }
  }

  public setEffect(effect: string) {
    this.effect = effect;
  }

  public insertItemByType(values: { item: SvcFishboneItem, type: SvcFishboneType, onTop?: boolean }) {
    const propName = getPropNameByType(values.type);
    this[propName] = [
      ...((values.onTop ?? false) ? [] : this[propName]),
      values.item,
      ...(!(values.onTop ?? false) ? [] : this[propName]),
    ];
  }

  public updateItemByType(item: SvcFishboneItem, type: SvcFishboneType) {
    const propName = getPropNameByType(type);
    const index = this[propName].indexOf(item) || (item.id ? this[propName].findIndex((i) => i.id && item.id) : null);
    if (index >= 0 && index < this[propName].length) {
      this[propName][index] = item;
    }
  }

  public deleteItemByType(item: SvcFishboneItem, type: SvcFishboneType) {
    const propName = getPropNameByType(type);
    this[propName] = this[propName].filter((i) => item.id ? item.id !== i.id : i !== item);
  }
}
