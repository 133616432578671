<div class="w-full">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="flex flex-col pb-2">
      <svc-select
        inputId="access-permission-user"
        class="w-full"
        [label]="'Usuário' | transloco"
        [formControl]="form.controls.userId"
        [options]="isEditMode ? temporaryListPermission : listPermission"
        [placeholder]="'Digite aqui' | transloco"
        [style.maxWidth.px]="400"
      ></svc-select>
    </div>
    <div class="flex flex-col pb-2">
      <ng-container *ngFor="let role of roles">
        <mat-checkbox [id]="'access-permission-role-'+role.propName" [formControlName]="role.propName" class="-ml-2">
          <span class="text-base">{{role.roleName | transloco}}</span>
        </mat-checkbox>
      </ng-container>
    </div>
    <div class="flex space-x-2">
      <svc-button *ngIf="!isEditMode" type="reset" mode="stroked" color="primary" (click)="form.reset()" [disabled]="loading" buttonId="access-permission-clear">
        {{ 'Limpar' | transloco }}
      </svc-button>
      <svc-button type="submit" mode="flat" color="primary" [processing]="loading" buttonId="access-permission-add">
        {{ (isEditMode ? 'Alterar' : 'Adicionar') | transloco }}
      </svc-button>
      <svc-button *ngIf="isEditMode" mode="flat" color="warn" (click)="remove()" [disabled]="loading" buttonId="access-permission-remove">
        {{ 'Remover' | transloco }}
      </svc-button>
      <svc-button *ngIf="isEditMode" mode="stroked" color="primary" (click)="addNew({ focus: true })" [disabled]="loading" buttonId="access-permission-new">
        {{ 'Novo' | transloco }}
      </svc-button>
    </div>
  </form>
</div>
