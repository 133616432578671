import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError, finalize, Subscription, tap } from 'rxjs';
import { ISvcUserAvatar } from 'projects/lib-shared-component/src/public-api';
import { SvcUserAvatarService } from 'projects/lib-shared-core/src/public-api';
import { UserInfo } from '../svc-user-picker/models/user-info.model';

@Component({
  selector: 'svc-smart-user-avatar',
  templateUrl: './svc-smart-user-avatar.component.html',
  styleUrls: ['./svc-smart-user-avatar.component.scss'],
})
export class SvcSmartUserAvatarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public set ids(value: string | string[] | { usersId: string[], teamsIds: number[], areasIds: number[], }) {
    value = value ?? [];
    let userIds = [], teamsIds = [], areasIds = [];
    if (Array.isArray(value) || typeof value === 'string') {
      userIds = (Array.isArray(value) ? value : [value]).filter(v => !!(v));
      teamsIds = [];
      areasIds = [];
    }
    else {
      userIds = value?.usersId?.filter(v => !!(v)) ?? [];
      teamsIds = value?.teamsIds?.filter(v => !!(v)) ?? [];
      areasIds = value?.areasIds?.filter(v => !!(v)) ?? [];
    }
    if (JSON.stringify({ userIds, teamsIds, areasIds }) !== JSON.stringify({ userIds: this.userIds, teamsIds: this.teamsIds, areasIds: this.areasIds })) {
      this.userIds = userIds;
      this.teamsIds = teamsIds;
      this.areasIds = areasIds;
      this.length = ((userIds ?? []).length + (teamsIds ?? []).length + (areasIds ?? []).length);
      this.isSingle = this.length === 1;
      this.isMultiple = this.length > 1;
      this.#loadUserInfo();
    }
  }
  @Input() size: number = 30;
  @Input() maxSize: number = 1;
  @Input() borderColor: string = 'white';
  @Output() public onLoadingChange = new EventEmitter<boolean>();

  #service = inject(SvcUserAvatarService);
  #requestSubscription: Subscription;
  #onInitWasCalled = false;

  public isLoading: boolean = false;
  public isSingle: boolean = false;
  public isMultiple: boolean = false;
  protected infos: UserInfo[] = [];
  protected avatars: ISvcUserAvatar[] = [];
  protected length: number = 0;
  protected userIds: string[] = [];
  protected teamsIds: number[] = [];
  protected areasIds: number[] = [];

  public info: UserInfo;
  public avatar: ISvcUserAvatar;

  public ngOnInit(): void {
    this.#onInitWasCalled = true;
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.#onInitWasCalled) {
      if ('ids' in changes && JSON.stringify(changes.ids.previousValue ?? []) !== JSON.stringify(changes.ids.currentValue ?? [])) {
        this.#loadUserInfo();
      }
      if ('size' in changes) {
        this.#applySizeInCurrentAvatars();
      }
    }
  }

  public refresh() {
    if (!this.isLoading) {
      this.#loadUserInfo();
    }
  }

  #loadUserInfo() {
    this.#requestSubscription?.unsubscribe();
    if (this.length > 0) {
      this.isLoading = true;
      this.#requestSubscription = this.#service.getUserInfoByIds({
        userIds: this.userIds,
        teamIds: this.teamsIds,
        areaIds: this.areasIds,
      }).pipe(
        tap((res) => {
          this.infos = res;
          this.avatars = this.infos.map((ui) => (<ISvcUserAvatar>{
            name: ui.name,
            picture: ui.pictureFile,
            initialsColor: ui.initialColor,
            type: Math.max(ui.type, 1),
            size: this.size
          }));
          this.info = this.infos?.length > 0 ? this.infos[0] : null;
          this.avatar = this.avatars?.length > 0 ? this.avatars[0] : null;
        }),
        catchError((error) => {
          this.infos = [];
          this.avatars = [];
          this.info = null;
          this.avatar = null;
          return error;
        }),
        finalize(() => {
          this.#requestSubscription = null;
          this.isLoading = false;
        }),
      ).subscribe();
    }
    else {
      
    }
  }

  #applySizeInCurrentAvatars() {
    this.avatars = this.avatars.map((a) => ({
      ...a,
      size: this.size,
    }));
    if (this.isSingle) {
      this.avatar = this.avatars?.length > 0 ? this.avatars[0] : null;
    }
  }

  ngOnDestroy(): void {
    this.#requestSubscription?.unsubscribe();
  }
}
