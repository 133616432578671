import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { GetCounterByIdResponse, GetCounterByIdParams } from './actionplan-counter-classes';
import { AbstractCounterBusStop } from '../abstract-counter-bus-stop';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvcActionPlanCounterService extends AbstractCounterBusStop<GetCounterByIdParams, GetCounterByIdResponse> {

  constructor(
    protected appConfig: AppEnvironmentConfig,
  ) {
    super({
      apiURL: appConfig.APIs.apiUrlActionPlan,
      idBatchParamName: 'ids',
      registryIdFilterPropName: 'id',
      idBatchRequestPath: '/Counters/actionplan/CountersByIdsFromModule',
    });
  }

  public getCountByRegistryId(registryId: number | string, params: GetCounterByIdParams): Observable<number> {
    return this.getCountByIdOrUid(registryId, params).pipe(
      map((result) => result.length > 0 ? result[0].total : 0),
    );
  }
}

