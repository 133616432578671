import { Component, ContentChild, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Optional, TemplateRef } from '@angular/core';

export interface SvcTreeItem<T> {
  name: string;
  data?: T,
  children?: SvcTreeItem<T>[];
  forceStartOpened?: boolean;
}

export enum SvcTreeOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

@Component({
  selector: 'svc-tree',
  templateUrl: './svc-tree.component.html',
  styleUrls: ['./svc-tree.component.scss']
})
export class SvcTreeComponent implements OnInit, OnChanges, OnDestroy {

  @HostBinding('class.svc-tree') protected svcClass = true;
  @HostBinding('class.is-vertical') protected isVertical = true;
  @HostBinding('class.is-horizontal') protected isHorizontal = false;

  @Optional()
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() inputs: SvcTreeItem<any>[] = [];
  @Input() orientation: SvcTreeOrientation = SvcTreeOrientation.VERTICAL;
  @Input() startOpened = true;
  @Input() showIndicator = true;

  constructor(
    public element: ElementRef
  ) { }

  public ngOnInit(): void {
    this.inputs.forEach((x) => {
      x.children = (x.children ?? []).map(y => ({
        ...y,
      }));
    });
    this.isVertical = this.orientation === SvcTreeOrientation.VERTICAL;
    this.isHorizontal = this.orientation === SvcTreeOrientation.HORIZONTAL;
  }

  public ngOnChanges(): void {
    this.ngOnInit();
  }

  public ngOnDestroy(): void {
  }
}
