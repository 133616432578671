import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { catchError, finalize, map, Observable, of, Subscription, tap } from 'rxjs';
import { SvcUserInfo } from './user-info.model';
import { AbstractBusStop } from '../../patterns/bus-stop/abstract-bus-stop';
import { Bus } from '../../patterns/bus-stop/bus';

@Injectable({
	providedIn: 'root'
})
export class SvcUserAvatarService extends AbstractBusStop<void, SvcUserInfo> {

	#appConfig = inject(AppEnvironmentConfig);
	#httpClient = inject(HttpClient);
	#cachedUsersInfo: { users: SvcUserInfo[], teams: SvcUserInfo[], areas: SvcUserInfo[] } = {
		users: [],
		teams: [],
		areas: [],
	};

	constructor(
	) {
		super();
	}

	public getUserInfoByUserId(userId: string): Observable<SvcUserInfo> {
		return this.#getUserInfoByIds({ users: [userId] }).pipe(
			map((response) => {
				const info = response?.find((info => info.id === userId));
				return info;
			}),
		);
	}

	public getUserInfoByAreaId(areaId: number): Observable<SvcUserInfo> {
		return this.#getUserInfoByIds({ areas: [areaId] }).pipe(
			map((response) => {
				const info = response?.find((info => info.id === areaId));
				return info;
			}),
		);
	}

	public getUserInfoByTeamId(teamId: number): Observable<SvcUserInfo> {
		return this.#getUserInfoByIds({ teams: [teamId] }).pipe(
			map((response) => {
				const info = response?.find((info => info.id === teamId));
				return info;
			}),
		);
	}

	public getUserInfoByUserIds(userIds: string[]): Observable<SvcUserInfo[]> {
		return this.#getUserInfoByIds({ users: userIds }).pipe(
			map((response) => {
				return response.filter((info) => (typeof info.id === 'string') && userIds.includes(info.id));
			}),
		);
	}

	public getUserInfoByAreaIds(areaIds: number[]): Observable<SvcUserInfo[]> {
		return this.#getUserInfoByIds({ areas: areaIds }).pipe(
			map((response) => {
				return response.filter((info) => (typeof info.id === 'number') && areaIds.includes(info.id));
			}),
		);
	}

	public getUserInfoByTeamIds(teamIds: number[]): Observable<SvcUserInfo[]> {
		return this.#getUserInfoByIds({ teams: teamIds }).pipe(
			map((response) => {
				return response.filter((info) => (typeof info.id === 'number') && teamIds.includes(info.id));
			}),
		);
	}

	public getUserInfoByIds(ids: { userIds?: string[], teamIds?: number[], areaIds?: number[] }): Observable<SvcUserInfo[]> {
		return this.#getUserInfoByIds({ users: ids?.userIds, teams: ids?.teamIds, areas: ids?.areaIds }).pipe(
			map((response) => {
				return response.filter((info) => {
					return (typeof info.id === 'number' && (ids.teamIds?.includes(info.id) || ids.areaIds?.includes(info.id))) ||
						(typeof info.id === 'string' && ids.userIds?.includes(info.id));
				});
			}),
		);
	}

	protected onBusMovingStatusDispatch(bus: Bus<void, SvcUserInfo>): Observable<SvcUserInfo[]> {
		return this.#httpClient.post<SvcUserInfo[]>(`${this.#appConfig.APIs.apiUrlUsers}/Avatar/ListAvatar`, {
			users: bus.ids.filter((id: string) => id.startsWith('users')).map((id: string) => id.replace(/^users-/g, '')),
			teams: bus.ids.filter((id: string) => id.startsWith('teams')).map((id: string) => id.replace(/^teams-/g, '')),
			areas: bus.ids.filter((id: string) => id.startsWith('areas')).map((id: string) => id.replace(/^areas-/g, '')),
		}).pipe(
			map((response) => this.#handleResponse(response)),
		);
	}
	
	#getUserInfoByIds(ids: { users?: string[], areas?: number[], teams?: number[] }): Observable<SvcUserInfo[]> {
		let filteredParams = { ...ids };
		let existingUsersInfo: SvcUserInfo[] = [];
		if (filteredParams.users?.length > 0 && this.#cachedUsersInfo.users.length > 0) {
			filteredParams.users = filteredParams.users.filter(id => {
				const existingUserInfo = this.#cachedUsersInfo.users.find(y => y.id === id);
				if (existingUserInfo) {
					existingUsersInfo.push(existingUserInfo);
					return false;
				}
				return true;
			});
		}
		if (filteredParams.teams?.length > 0 && this.#cachedUsersInfo.teams.length > 0) {
			filteredParams.teams = filteredParams.teams.filter(id => {
				const existingUserInfo = this.#cachedUsersInfo.teams.find(y => y.id === id);
				if (existingUserInfo) {
					existingUsersInfo.push(existingUserInfo);
					return false;
				}
				return true;
			});
		}
		if (filteredParams.areas?.length > 0 && this.#cachedUsersInfo.areas.length > 0) {
			filteredParams.areas = filteredParams.areas.filter(id => {
				const existingUserInfo = this.#cachedUsersInfo.areas.find(y => y.id === id);
				if (existingUserInfo) {
					existingUsersInfo.push(existingUserInfo);
					return false;
				}
				return true;
			});
		}
		if (filteredParams.users?.length > 0 || filteredParams.areas?.length > 0 || filteredParams.teams?.length > 0) {
			return new Observable((subscriber) => {
				let buses: {
					bus: Bus<void, SvcUserInfo>,
					loading: boolean,
					subscription?: Subscription,
					ids: (string | number)[],
					result: SvcUserInfo[],
				}[] = [];
				for(const key in filteredParams) {
					filteredParams[key]?.forEach((registryId: string | number) => {
						const joinedId = `${key}-${registryId}`
						const bus = this.getAvailableBusAtTheStop(joinedId);
						const busObject = buses.find(b => b.bus === bus);
						if (!busObject) {
							buses.push({ bus, loading: true, result: [], ids: [joinedId] });
						}
						else if(!busObject.ids.includes(joinedId)) {
							busObject.ids.push(joinedId);
						}
						bus.addId(joinedId);
					});
				}
	
				for(const busObject of buses) {
					const bus = busObject.bus;
					const originalIds = bus.ids.map((id: string) => {
						const originalId = id.replace(/^(users|teams|areas)-/g, '');
						return /^\d+$/.test(originalId) ? parseInt(originalId) : originalId;
					});
					const everyDone = () => buses.every((b) => !b.loading);
					busObject.subscription = bus.result$.pipe(
						map((response) => {
							response = response.filter((info) => originalIds.includes(info.id));
							busObject.loading = false;
							busObject.result = response;
							return response;
						}),
						tap(() => {
							if (everyDone()) {
								const response = buses.flatMap((b) => b.result);
								this.#cachedUsersInfo.users = [
									...this.#cachedUsersInfo.users,
									...response.filter(x => x.type === 1 && !this.#cachedUsersInfo.users.find(y => y.id === x.id)),
								];
								this.#cachedUsersInfo.areas = [
									...this.#cachedUsersInfo.areas,
									...response.filter(x => x.type === 2 && !this.#cachedUsersInfo.areas.find(y => y.id === x.id)),
								];
								this.#cachedUsersInfo.teams = [
									...this.#cachedUsersInfo.teams,
									...response.filter(x => x.type === 3 && !this.#cachedUsersInfo.teams.find(y => y.id === x.id)),
								];
								const finalResponse = [
									...response,
									...existingUsersInfo,
								];
								subscriber.next(finalResponse.sort((a, b) => a.name.localeCompare(b.name)));
							}
						}),
						catchError((error) => {
							busObject.loading = false;
							if (everyDone())
								subscriber.error(error);
							return error;
						}),
						finalize(() => {
							if (everyDone())
								subscriber.complete();
						})
					).subscribe();
				}
	
				return () => {
					buses.forEach((b) => {
						b.bus.removeId(b.ids);
						b.subscription?.unsubscribe();
					});
				}
			});
		}
		return of(existingUsersInfo);
	}

	#handleResponse(response: SvcUserInfo[]) {
		let availableInfos = [];
		response.forEach((ui => {
			if ((ui.name ?? '') !== '') {
				let type = ui.type;
				if (typeof type === 'string') {
					switch (type) {
						case 'User': type = 1; break;
						case 'Area': type = 2; break;
						case 'Team': type = 3; break;
						default: type = undefined;
					}
				}
				availableInfos.push({
					...ui,
					id: /^\d+$/g.test(ui.id?.toString()) ? parseInt(ui.id as string) : ui.id,
					type: Math.max(type ?? 1, 1) as (1 | 2 | 3),
				});
			}
		}));
		return availableInfos;
	}
}
