<!-- Feed Content -->
<div class="relative flex flex-col flex-auto h-full bg-primary-300 px-4 sm:pb-4 rounded-t-lg" [ngClass]="{ 'shadow-md': expanded, 'pt-4': !selectedFeed && !expanded, 'pt-2': selectedFeed || expanded }" *screenSizes="let size">

  <!-- Selected Feed Header -->
  <div *ngIf="expanded || selectedFeed" class="flex items-center justify-end gap-x-2 pb-1.5 empty:hidden">
    <div *ngIf="!expanded && selectedFeed" class="flex-auto flex items-center gap-2">
      <button mat-button class="icon-only text-white w-8 min-w-8 h-8 min-h-8" (click)="expanded ? toggleExpanded() : selectedFeed = null">
        <mat-icon class="text-current icon-size-10" [svgIcon]="'mat_solid:chevron_left'"></mat-icon>
      </button>
    </div>
    <!-- Expand Toggle -->
    <svc-button *ngIf="size.isAboveXS && (expanded || selectedFeed)" class="expand-button -ml-1" icon-only size="sm" mode="simple" (click)="toggleExpanded({ dontClearSelectedFeed: true })">
      <mat-icon class="icon-size-6 text-current" [svgIcon]="expanded ? 'feather:minimize' : 'feather:maximize'"></mat-icon>
    </svc-button>
  </div>

  <div class="flex flex-auto overflow-auto">
    <!-- Feed -->
    <div #feedContainer class="flex flex-col overflow-y-auto" [class.hidden]="!expanded && selectedFeed" [class.flex-auto]="!expanded" [style.width.px]="expanded ? 280 : null" [style.minWidth.px]="expanded ? 318 : null">
      <!-- Feeds Header -->
      <div class="flex items-center gap-x-2 pb-4">
        <!-- Search -->
        <div class="flex-auto">
          <mat-form-field class="search-text-field svc-mat-no-subscript svc-mat-dense grow w-full" (click)="closeFilters()">
            <input [formControl]="searchCtrl" matInput [placeholder]="'Pesquisar' | transloco" type="text"/>
            <mat-icon class="icon-size-4" svgIcon="heroicons_solid:magnifying-glass"></mat-icon>
          </mat-form-field>
        </div>

        <!-- Filter -->
        <svc-button class="filter-button" icon-only size="sm" [ngClass]="{ 'white-color': !hasFiltersDefined }" [mode]="hasFiltersDefined ? 'flat' : 'stroked'" (click)="openFilters()">
          <mat-icon class="icon-size-4 text-current" svgIcon="heroicons_solid:funnel"></mat-icon>
        </svc-button>

        <!-- Expand -->
        <svc-button *ngIf="size.isAboveXS && !expanded" class="expand-button -ml-1" icon-only mode="simple" (click)="toggleExpanded()">
          <mat-icon class="icon-size-6 text-current" [svgIcon]="expanded ? 'feather:minimize' : 'feather:maximize'"></mat-icon>
        </svc-button>
      </div>
      <div class="relative flex-auto flex flex-col overflow-y-auto bg-white rounded-tl-md rounded-tr-md sm:rounded-md" infiniteScroll (scrollEnd)="nextPage()">
        <ng-container *ngIf="!isLoading || feeds.length > 0">
          <svc-comments-list #svcCommentsList *ngIf="feeds.length > 0" class="block" [feeds]="feeds" (onFeedClick)="onFeedClick($event)"></svc-comments-list>
          <div *ngIf="feeds.length <= 0" class="mx-auto pt-20 px-5 text-center max-w-50 text-md">
            <div class="flex items-center justify-center m-auto rounded-full w-14 h-14 bg-primary-50 text-primary-400 mb-2">
              <mat-icon class="text-current" svgIcon="fontawesome_solid:comment-slash"></mat-icon>
            </div>
            <span *ngIf="!hasFiltersDefined">
              {{ 'Ainda não existem comentários para serem exibidos' | transloco }}
            </span>
            <span *ngIf="hasFiltersDefined">
              {{ 'Não há resultados para sua pesquisa' | transloco }}
            </span>
          </div>
        </ng-container>
        <div *ngIf="isLoading" class="flex items-center justify-center" [ngClass]="feeds.length ? 'p-2' : 'p-4'">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- Space -->
    <div [class.hidden]="!expanded" [style.width.px]="24"></div>

    <!-- Feed comments -->
    <div [class.hidden]="!expanded && !selectedFeed" class="relative flex-auto flex flex-col bg-white rounded-md overflow-y-auto">
      <svc-comments-viewer class="flex-auto block" [selectedFeed]="selectedFeed"></svc-comments-viewer>
      <div *ngIf="isLoading" class="flex items-center justify-center absolute inset-0">
        <div class="absolute inset-0 bg-white opacity-60"></div>
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
  </div>

  <!-- FILTERS -->
  <div class="absolute bottom-4 left-4 flex flex-col bg-white rounded-lg shadow overflow-x-hidden overflow-y-auto z-99" [ngClass]="{ 'hidden': !showFilters, 'fadeIn': showFilters, 'top-[46px]': expanded, 'top-3 right-4': !expanded }" [style.width.px]="expanded ? feedContainer.clientWidth : null">
    <div class="flex pl-3 pr-2 py-1 items-center justify-between border-b border-inherit ">
      <div class="flex justify-center gap-2 text-default">
        <mat-icon class="self-center icon-size-6 text-current" svgIcon="heroicons_solid:funnel"></mat-icon>
        <div class="self-center text-base font-semibold tracking-tight">
          {{ 'Busca detalhada' | transloco }}
        </div>
      </div>
      <button mat-icon-button (click)="closeFilters()">
        <mat-icon class="text-current" svgIcon="mat_solid:close"></mat-icon>
      </button>
    </div>
    <div class="overflow-y-auto flex-auto p-5">
      <svc-filters *ngIf="!isFiltersLoading"
        #svcFilters
        [filters]="filtersField"
        (onFilterChanged)="checkIfHasFiltersToDo($event)"
      ></svc-filters>
      <div *ngIf="isFiltersLoading" class="py-5 flex items-center justify-center">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </div>
    <div class="flex flex-row px-7 py-4 items-center justify-center border-t border-inherit">
      <div class="flex gap-2">
        <svc-button mode="simple" icon-only (click)="clearFilters()" [disabled]="isFiltersLoading || !hasFiltersDefined" [matTooltip]="'Limpar filtros' | transloco" matTooltipPosition="above">
          <mat-icon class="text-current" svgIcon="heroicons_solid:trash"></mat-icon>
        </svc-button>
        <svc-button mode="stroked" (click)="closeFilters()">
          {{ 'Cancelar' | transloco }}
        </svc-button>
        <svc-button mode="flat" (click)="svcFilters?.isValid && !isFiltersLoading && hasFiltersPending && doFilter({ fields: svcFilters.fields })" [disabled]="isFiltersLoading || !hasFiltersPending || !svcFilters?.isValid">
          {{ 'Buscar' | transloco }}
        </svc-button>
      </div>
    </div>
  </div>

  <div *ngIf="isCheckingFeedCanBeLoaded || svcCommentsList?.isCheckingRegistryCanBeOpened" class="absolute inset-1 flex items-center justify-center text-primary z-99 fadeIn">
    <div class="absolute inset-0 bg-white opacity-50 rounded-md"></div>
    <mat-spinner
      class="absolute left-1/2 top-1/2 z-99 rounded-full bg-white text-current"
      diameter="30"
      [style.margin-left.px]="expanded ? 180 : null"
      [style.transform]="'translate(-50%, -50%)'"
      [style.box-shadow]="'0 0 80px 80px white'"
    ></mat-spinner>
  </div>
</div>
