import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

import { AppEnvironmentConfig } from "projects/config/model/environment.config.model";
import { SvcHttpClient } from "projects/lib-shared-common/src/lib/classes/svc-http-client";
import { SvcAppSettings } from 'projects/lib-shared-core/src/lib/settings/svc-app-settings';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SvcCustomFieldHttpClient extends SvcHttpClient {

  private _userService: UserService = inject(UserService);
  private _appSettings: SvcAppSettings = inject(SvcAppSettings);

  public siteId: number = this._userService.user$.lastSiteId;
  public applicationId: string = this._appSettings.applicationId;

  constructor(
    protected appConfig: AppEnvironmentConfig,
    protected httpClient: HttpClient,
  ) {
    super(appConfig.APIs.apiUrlCustomField, httpClient);
  }
}
