import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ISvcStatusBadge, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { CommunicationStatus, CommunicationStatusDescription, CommunicationStatusTyped } from './enums/status-communication.enum';
import { environment } from 'projects/environments/environment';
import { CommunicationCardModel } from './models/card-model';
import { StaticApplicationId, SvcAppSettings, SvcModule } from 'projects/lib-shared-core/src/public-api';
import { SvcTotalsStatusComponent } from '../../general/svc-totals-status/svc-totals-status.component';

@Component({
  selector: 'communication-card',
  templateUrl: './communication-card.component.html',
  styleUrls: ['./communication-card.component.scss'],
})
export class CommunicationCardComponent implements OnChanges {

  @ViewChild(SvcTotalsStatusComponent) public svcTotalStatus: SvcTotalsStatusComponent;

  @Input() public communication: CommunicationCardModel;
  @Output() public onCardClick = new EventEmitter<void>();
  @Output() public onLinkClicked = new EventEmitter<void>();

  public isReactionLoading = false;
  public isReactionDone = false;

  public CommunicationStatusDescription = CommunicationStatusDescription;
  public CommunicationStatus = CommunicationStatus;
  public CommunicationStatusTyped = CommunicationStatusTyped;
  public statusBadge?: ISvcStatusBadge;
  public fromCommunicationMobule = true;

  public env = environment;
  public applicationId = StaticApplicationId.communication;
  public featureName = 'view-communication';
  public configType = '1B8DB746-99B0-45A0-9677-67AA8F102B6A';

  constructor(
    public activatedRoute: ActivatedRoute,
    private _svcAppSettings: SvcAppSettings,
    private _translocoService: TranslocoService,
    private _svcToastService: SvcToastService,
  ) {
    this.fromCommunicationMobule = this._svcAppSettings.module === SvcModule.Communication;
  }

  ngOnChanges() {
    if (this.communication?.statusId) {
      this.statusBadge = {
        type: CommunicationStatusTyped[this.communication.statusId] as any,
        description: this._translocoService.translate(CommunicationStatusDescription[CommunicationStatus[this.communication.statusId]]),
      };
    }
  }

  public onBookmarkChanged(value: boolean) {
    const messageKey = value ? 'adicionado aos' : 'removido dos';
    this._svcToastService.success(this._translocoService.translate(`Este comunicado foi ${messageKey} favoritos com sucesso!`));
  }
}
