import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from "@ngneat/transloco";
import { NgxPermissionsService } from 'ngx-permissions';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { generateModuleMenuURL, getModuleMenuType } from 'projects/lib-shared-common/src/public-api';
import { Observable, ReplaySubject, catchError, finalize, map, of, throwError } from 'rxjs';
import { StaticApplicationId } from '../../../Constants/static-application-id.enum';
import { ICONMENU } from './icon-menu-map';
import { Navigation, SvcDefaultNavigationItem, SvcMenuNavigation, SvcMenuNavigationItemType, SvcWorkspaceNavigationItem } from './navigation.types';
import { AuthService } from 'projects/lib-shared-core/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  private navigation: Navigation;
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  public error: any;
  public isLoading: boolean = true;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _translocoService: TranslocoService,
    private permissionsService: NgxPermissionsService,
    private _appConfig: AppEnvironmentConfig,
    private _authService: AuthService,
  ) {
    this._authService.signIn$.subscribe(logged => {
      if (logged?.success) this.navigation = null;
    })
  }

  ngOnDestroy(): void {
    this._authService.signIn$.unsubscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(aplicationId?: StaticApplicationId): Observable<Navigation> {
    const defaultSvc: any[] = [];
    const defaultSvcWorkspace: SvcWorkspaceNavigationItem[] = [];

    const defaultNavigation = {
      default: defaultSvc,
      workspace: defaultSvcWorkspace
    };

    if (!aplicationId) {
      this._navigation.next(defaultNavigation);
      return of(defaultNavigation);
    }

    if (this.navigation) {
      return of(this.navigation);
    }

    this.error = null;
    this.isLoading = true;
    return this._httpClient
      .get<SvcMenuNavigation[]>(
        `${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${aplicationId}`
      )
      .pipe(
        map((items: SvcMenuNavigation[]) => {
          const menuItems: SvcDefaultNavigationItem[] = this.mapMenuNavigationItem(items);
          const workspaceItems: SvcWorkspaceNavigationItem[] = this.mapMenuToSvcWorkspaceNavigationItem(items);

          this.navigation = {
            default: menuItems,
            workspace: workspaceItems
          };
          this._navigation.next(this.navigation);

          return this.navigation;
        }),
        catchError((error) => {
          this.error = error;
          return throwError(() => error);
        }),
        finalize(() => this.isLoading = false)
      );
  }
  /**
   * Get all navigation data
   */
  isAvailable(aplicationId: string): Observable<boolean> {
    return this._httpClient.get<boolean>(`${this._appConfig.APIs.apiUrlAdministration}/MenuGroup/module/${aplicationId}/accessible`);
  }

  private mapMenuNavigationItem(
    menuItems: SvcMenuNavigation[]
  ): SvcDefaultNavigationItem[] {
    let navigationItem: SvcDefaultNavigationItem[] = [];

    menuItems.forEach((item) => {
      navigationItem.push(<SvcDefaultNavigationItem>{
        title: this._translocoService.translate(item.menuGroupName),
        icon: ICONMENU.getValue(item.menuGroupId) || '',
        children: this.hasChildren(item)
          ? item.menus.map((c) => {
            const itemType = c.menuCategoryProvider ? getModuleMenuType(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType]) : null;
            return {
              title: c.menuDesc,
              url: (itemType === 'link')
                ? generateModuleMenuURL(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType], c.menuCategoryProvider.url, c.menuCategoryProvider.route, c.menuCategoryProvider.params)
                : null,
            };
          })
          : [],
      });
    });

    return navigationItem;
  }

  private mapMenuToSvcWorkspaceNavigationItem(
    menuItems: SvcMenuNavigation[]
  ): SvcWorkspaceNavigationItem[] {
    let navigationItem: SvcWorkspaceNavigationItem[] = [];

    menuItems.forEach((item) => {
      if (this.hasChildren(item)) {
        item.menus.map((c) => {
          navigationItem.push({
            icon: ICONMENU.getValue(item.menuGroupId) || '',
            url: c.menuCategoryProvider ?
              generateModuleMenuURL(SvcMenuNavigationItemType[c.menuCategoryProvider.applicationType], c.menuCategoryProvider.url, c.menuCategoryProvider.route, c.menuCategoryProvider.params)
              : null,
            title: c.menuDesc,
            params: c.menuCategoryProvider.params
          });
        });
      }
    });
    return navigationItem;
  }

  private hasChildren(menuItem: SvcMenuNavigation): boolean {
    return !!menuItem.menus;
  }

}
