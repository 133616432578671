import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { catchError, finalize, Subject, Subscription, takeUntil, tap } from 'rxjs';

import { ISvcSelectOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.component';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { SvcCustomFieldService } from './svc-custom-field.service';
import { HttpErrorService } from 'projects/lib-shared-common/src/lib/services/http-error.service';
import { SvcCustomField } from './svc-custom-field.interface';

@Component({
  selector: 'svc-custom-fields',
  templateUrl: './svc-custom-fields.component.html',
  styleUrls: ['./svc-custom-fields.component.scss'],
})

export class SvcCustomFieldsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  public options: ISvcSelectOption[] = [];
  public isOptionsLoading = true;

  private subscriptions: Subscription[] = [];

  @AutoDestroy private _destroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _customfieldService: SvcCustomFieldService,
    private _errorService: HttpErrorService
  ) {
    this.form = this._fb.group({
      id: [null],
      fields: this._fb.array([])
    });
    this._customfieldService.form = this.form;
  }

  ngOnInit(): void {
    this._clearForm();
    this._getFormByReferenceId();
    this._createCustomField();
    this._updateCustomField();
    this._getCustomFieldTypes();
    this.initializeCustomTypeListeners();
  }

  ngOnDestroy(): void {
    this._unsubcribeForm();
  }

  private _unsubcribeForm(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private _getCustomFieldTypes(): void {
    this.isOptionsLoading = true;
    this._customfieldService.getCustomFieldTypes().pipe(
      tap((options: ISvcSelectOption[]) =>
        this.options = options
      ),
      catchError(err => {
        this._errorService.showErrorInToast(err);
        return err;
      }),
      finalize(() => this.isOptionsLoading = false),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  get customFields(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  private initializeCustomTypeListeners(): void {
    this.customFields.controls.forEach((field, index) =>
      this.subscribeToCustomTypeChange(field, index)
    );
  }

  private subscribeToCustomTypeChange(field: AbstractControl, index: number): void {
    const sub = field.get('customFieldTypeId')?.valueChanges.subscribe(value =>
      this.handleCustomTypeChange(value, index)
    );

    if (sub)
      this.subscriptions.push(sub);
  }

  private handleCustomTypeChange(customType: number, fieldIndex: number): void {
    const options = this.getOptions(fieldIndex);

    if (customType !== 4)
      options.clear();
  }

  getOptions(fieldIndex: number): FormArray {
    return this.customFields.at(fieldIndex).get('options') as FormArray;
  }

  addOption(fieldIndex: number): void {
    const options = this.getOptions(fieldIndex);
    options.push(this.createOptionGroup());
  }

  removeOption(fieldIndex: number, optionIndex: number): void {
    const options = this.getOptions(fieldIndex);
    options.removeAt(optionIndex);
  }

  private createOptionGroup(): FormGroup {
    return this._fb.group({
      id: [null],
      fieldOption: ['', Validators.required]
    });
  }

  addCustomField(): void {
    const customFieldGroup = this._fb.group({
      id: [null],
      fieldName: ['', Validators.required],
      customFieldTypeId: ['', Validators.required],
      mandatory: [false],
      options: this._fb.array([this.createOptionGroup()])
    });

    const index = this.customFields.length;
    this.customFields.push(customFieldGroup);
    this.subscribeToCustomTypeChange(customFieldGroup, index);
  }

  removeCustomField(index: number): void {
    this.customFields.removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>, fieldIndex: number): void {
    const options = this.getOptions(fieldIndex).controls;
    moveItemInArray(options, event.previousIndex, event.currentIndex);

    this.getOptions(fieldIndex).setValue(options.map(control => control.value));
  }

  private _cleanForm(): void {
    this.form.reset({
      customFields: this._fb.array([])
    });
    this.customFields?.clear();
    this._unsubcribeForm();
  }

  private _clearForm(): void {
    this._customfieldService.clearForm$
      .pipe(
        tap(() => this._cleanForm()),
        takeUntil(this._destroy$)
      ).subscribe();
  }

  private _addCustomField(id: number, isUpdate = false): void {
    if (this.form?.valid) {
      const customFieldRequest: SvcCustomField = {
        ...this.form.value,
        feature: id
      };
      const request$ = isUpdate ?
        this._customfieldService.updateCustomFields(customFieldRequest) :
        this._customfieldService.createCustomFields(customFieldRequest);

      request$.pipe(
        tap(() => this._cleanForm()),
        catchError(err => {
          this._errorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this._customfieldService.completeCreateCustomField$.next()),
        takeUntil(this._destroy$)
      ).subscribe();
    }
  }

  private _createCustomField(): void {
    this._customfieldService.createCustomField$.pipe(
      tap((id: number) => this._addCustomField(id)),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  private _updateCustomField(): void {
    this._customfieldService.updateCustomField$.pipe(
      tap((id: number) => this._addCustomField(id, true)),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  private _getCustomField(id: number) {
    this._customfieldService.getCustomField(id).pipe(
      // TODO: get custom field and set the form
      // tap(()),
      catchError(err => {
        this._errorService.showErrorInToast(err);
        return err;
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  private _getFormByReferenceId(): void {
    this._customfieldService.getFormByReferenceId$.pipe(
      tap((id: number) =>
        this._getCustomField(id)
      ),
      takeUntil(this._destroy$),
    ).subscribe();
  }
}
