import { Component, EventEmitter, forwardRef, HostBinding, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SvcFishboneComponent } from '../svc-fishbone.component';
import { SvcFishbonePartComponent } from '../svc-fishbone-part/svc-fishbone-part.component';
import { SvcFishboneItem } from '../public-api';
import { fishbonePartDelayStep } from '../svc-fishbone-functions';

@Component({
  selector: 'svc-fishbone-part-item',
  templateUrl: './svc-fishbone-part-item.component.html',
  styleUrls: ['./svc-fishbone-part-item.component.scss'],
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-10px)' }),
        animate('250ms {{delay}}ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('250ms', style({ opacity: 0, transform: 'translateX(-10px)' })),
      ]),
    ]),
  ]
})
export class SvcFishbonePartItemComponent implements OnInit, OnChanges, OnDestroy {

  @HostBinding('@fadeInAndOut') protected animation = null;
  @HostListener('@fadeInAndOut.start') protected animationStart() {
    this.updateHostWidth();
  }
  @HostBinding('class.svc-fishbone-part-item') protected internalClass: boolean = true;
  @HostBinding('style.padding-right') protected hostPaddingRight: string = null;

  @Input() item!: SvcFishboneItem;
  @Input() index!: number;
  @Output() onItemEditRequested = new EventEmitter<SvcFishboneItem>();
  @Output() onItemDeleteRequested = new EventEmitter<SvcFishboneItem>();

  constructor(
    @Inject(forwardRef(() => SvcFishboneComponent)) public parent: SvcFishboneComponent,
    @Inject(forwardRef(() => SvcFishbonePartComponent)) public parentPart: SvcFishbonePartComponent,
  ) { }

  public ngOnInit(): void {
    this.animation = {
      value: '',
      params: {
        delay: !this.parentPart.animationsDidFinish
          ? (this.parentPart.diffDelay + fishbonePartDelayStep + (150 * this.index))
          : 0,
      }
    };
  }

  public ngOnChanges(): void {
    this.updateHostWidth();
  }

  updateHostWidth() {
    const isDown = this.parentPart.isDown;
    const items = this.parentPart.getItems();
    const index = this.index;
    const constant = 8.8;
    const remainingLength = 10 - items.length;
    const indexIndicator = isDown ? (items.length - (index + 1)) : index;
    this.hostPaddingRight = `${(constant * indexIndicator) + ((constant * remainingLength) / 2)}px`;
  }

  public ngOnDestroy(): void {
  }
}
