<div class="flex items-center justify-end sticky right-0 py-2.5 px-3.5">
	<p class="pr-1 text-xs">
		{{ 'Itens por página' | transloco }}:
	</p>
	<mat-form-field *ngIf="canChangePageSize" class="svc-mat-no-subscript svc-mat-dense px-2">
		<mat-select (selectionChange)="optionChanged($event)" [(ngModel)]="pageSize" [disabled]="loading || disabled" class="text-xs">
			<mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
		</mat-select>
	</mat-form-field>
	<p class="px-1 text-xs">
		{{startFrom}} - {{endFrom}} {{ 'de' | transloco }} {{from}}
	</p>
	<div class="flex items-center">
		<mat-icon class="icon-size-8 p-1" svgIcon="mat_solid:chevron_left" [ngClass]="hasPrevPage && !loading && !disabled ? 'cursor-pointer' : 'opacity-50'" (click)="prevPage()"></mat-icon>
		<mat-icon class="icon-size-8 p-1" svgIcon="mat_solid:chevron_right" [ngClass]="hasNextPage && !loading && !disabled ? 'cursor-pointer' : 'opacity-50'" (click)="nextPage()"></mat-icon>
	</div>	
</div>
