import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { dateIsValid, dateStringToDate } from "projects/lib-shared-common/src/public-api";

export class DateValidators {
    public static isValid(format: string): ValidatorFn  {
        return (control: AbstractControl) : ValidationErrors | null => {

            if (control.value) {
                if (Array.isArray(control.value)) {
                    if ((control.value[0] && !control.value[0]) || (!control.value[0] && control.value[0])) {
                        return { invalidDate: true };
                    }
                    else if (control.value[0] && control.value[0]) {
                        if (!dateIsValid(control.value[0], format) && !dateIsValid(control.value[1], format)) {
                            return { invalidDate: true };
                        }
                    }
                }
                else if (control.value && !dateIsValid(control.value, 'yyyy-MM-dd')) {
                    return { invalidDate: true };
                }
            }

            return null;
        };
    }

    public static min(date: Date, format: string): ValidatorFn  {
        return (control: AbstractControl) : ValidationErrors | null => {

            if (Array.isArray(control.value) && control.value[0] && control.value[1]) {
                const startControlDate = dateStringToDate(control.value[0], format).withoutTimespan();
                if (startControlDate.isBefore(date)) {
                    return { minDate: { date, format } };
                }

                const endControlDate = dateStringToDate(control.value[1], format).withoutTimespan();
                if (endControlDate.isBefore(date)) {
                    return { minDate: { date, format } };
                }
            }
            else if (dateIsValid(control.value, 'yyyy-MM-dd')) {
                const controlDate = dateStringToDate(control.value, 'yyyy-MM-dd').withoutTimespan();
                if (controlDate.isBefore(date)) {
                    return { minDate: { date, format } };
                }
            }


            return null;
        };
    }

    public static max(date: Date, format: string): ValidatorFn  {
        return (control: AbstractControl) : ValidationErrors | null => {

            if (Array.isArray(control.value) && control.value[0] && control.value[1]) {
                const startControlDate = dateStringToDate(control.value[0], format).withoutTimespan();
                if (startControlDate.isAfter(date)) {
                    return { minDate: { date, format } };
                }

                const endControlDate = dateStringToDate(control.value[1], format).withoutTimespan();
                if (endControlDate.isAfter(date)) {
                    return { minDate: { date, format } };
                }
            }
            else if (dateIsValid(control.value, 'yyyy-MM-dd')) {
                const controlDate = dateStringToDate(control.value, 'yyyy-MM-dd').withoutTimespan();
                if (controlDate.isAfter(date)) {
                    return { maxDate: { date, format } };
                }
            }

            return null;
        };
    }
}