import { HttpErrorResponse } from '@angular/common/http';
import { uuid } from 'projects/lib-shared-common/src/public-api';
import { debounceTime, filter, shareReplay, Subject, Subscription } from 'rxjs';

export class Bus<TParams, TResponse> {
  public id: string;
  public status: 'awaiting' | 'moving' | 'delivered' = 'awaiting';
  public type: 'id' | 'uid';
  public params: TParams;

  private _resultSubsctiption: Subscription;
  public resultWithSuccess: boolean = false;
  public resultWithError: boolean = false;
  public resultFinished: boolean = false;
  public result$ = new Subject<TResponse[]>();

  public get resultHadSomeResponse() {
    return this.resultWithSuccess ||
    this.resultWithError ||
    this.resultFinished;
  }

  public get ids() { return this._ids.map(_ => _.id); }
  private _ids: { id: (string | number), count: number }[] = [];
  private _ids$ = new Subject<(string | number)[]>();

  constructor(obj: {
    type: 'id' | 'uid',
    params: TParams,
  }) {
    Object.assign(this, obj);
    this.id = uuid();
  }

  public addId(id: (number | string) | (number | string)[]): Bus<TParams, TResponse> {
    const ids: (number | string)[] = (Array.isArray(id) ? id : [id]);
    if (this.status === 'awaiting' && ids.length) {
      let someNew = false;
      ids.forEach((id) => {
        const currentId = this._ids.find(_ => _.id == id);
        if (!currentId) {
          someNew = true;
          this._ids.push({ id, count: 1 });
        }
        else {
          currentId.count += 1;
        }
      });
      if (someNew) this._ids$.next(this._ids.map(_ => _.id));
    }
    return this;
  }

  public removeId(id: (number | string) | (number | string)[]): Bus<TParams, TResponse> {
    const ids: (number | string)[] = (Array.isArray(id) ? id : [id]);
    if (['awaiting', 'moving'].includes(this.status) && ids.length) {
      let someWithZero = false;
      this._ids = this._ids.map((id) => {
        if (ids.includes(id.id)) {
          id.count -= 1;
          if (id.count <= 0) someWithZero = true;
        }
        return id;
      }).filter((id) => id.count <= 0);

      if (this.status === 'awaiting') {
        this._ids$.next(this._ids.map(_ => _.id));
      }

      if (this._ids.length === 0) {
        this.status = 'delivered';
        this._resultSubsctiption?.unsubscribe();
      }
    }
    return this;
  }

  public onMovingStatusDispatch(fn: (next: (response: TResponse[]) => void, error: (error: HttpErrorResponse | Error) => void, complete: () => void) => Subscription) {
    if (this.status === 'awaiting') {
      const obs = this._ids$.asObservable();
      const idsSubscription = obs.pipe(
        debounceTime(10),
        shareReplay(1),
        filter((ids) => ids.length > 0)
      ).subscribe(() => {
        idsSubscription.unsubscribe();
        this.status = 'moving';
        const onGotSomeResult = () => {
          this.status = 'delivered';
          this._resultSubsctiption = null;
        };
        this._resultSubsctiption = fn(
          (response) => {
            onGotSomeResult();
            this.resultWithSuccess = true;
            this.result$.next(response);
          },
          (error) => {
            onGotSomeResult();
            this.resultWithError = true;
            this.result$.error(error);
          },
          () => {
            onGotSomeResult();
            this.resultFinished = true;
            this.result$.complete();
          },
        );
      });
    }
  }
}

export interface OnMovingStatusResult<TResponse> {
  success: (response: TResponse[]) => void;
  error: (error: HttpErrorResponse | Error) => void
  complete: () => void;
}