import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { fromEventPattern, Observable } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { SettingsService } from "projects/lib-shared-core/src/public-api";
import { getCustomThemeColor } from "projects/lib-shared-common/src/public-api";

@Injectable({
    providedIn: 'root'
})
export class LegacyService {

    onLegacyCallback$: Observable<Event>;
    private _renderer2: Renderer2;

    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _renderer: RendererFactory2,
        private _settingsService: SettingsService
    ) {
        this._renderer2 = this._renderer.createRenderer(null, null);
        this._createLegacyCallbackObservable();
    }

    addLegacySupport() {
        this._addLegacyModalSupport();
    }

    private _createLegacyCallbackObservable() {
        let removeLegacyCallbackListener: () => void;
        const legacyCallbackListener = (handler: (e: Event) => boolean | void) => { removeLegacyCallbackListener = this._renderer2.listen('window', 'legacyCallback', handler); }
        this.onLegacyCallback$ = fromEventPattern<Event>(legacyCallbackListener, () => removeLegacyCallbackListener());
    }

    private _addLegacyModalSupport() {
        const link = this._renderer2.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'assets/legacy-support/iframe-modal.css';
        this._renderer2.appendChild(this._document.querySelector('head'), link);

        const script = this._renderer2.createElement('script');
        script.type = 'text/javascript';
        script.src = 'assets/legacy-support/iframe-modal-functions.js';
        this._renderer2.appendChild(this._document.body, script);

        if(this._document.querySelector('#legacy-modal-support')){
          this._renderer2.removeChild(this._document.body, this._document.querySelector('#legacy-modal-support'));
        }

        const preventCache = Math.floor(Math.random() * 999999);
        const html: string = `<iframe id="legacy-modal-support" src="assets/legacy-support/main.html?themeColor=${getCustomThemeColor(this._settingsService.theme$, 500).replace('#', '')}&cache=${preventCache}"></iframe>`;
        const template = this._renderer2.createElement('template');
        template.innerHTML = html.trim();
        this._renderer2.appendChild(this._document.body, template.content.firstChild);
    }

  public _addLegacyModalSupportSearchActionplan(): void {
      const links: string[] = ['https://cdn.jsdelivr.net/npm/toastify-js/src/toastify.min.css', 'assets/legacy-support/search-actionplan/iframe-modal.css'];
      links.forEach((href: string) => {
        const link = this._renderer2.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = href;
        this._renderer2.appendChild(this._document.querySelector('head'), link);
      });

      const scripts: string[] = ['https://cdn.jsdelivr.net/npm/toastify-js', 'assets/legacy-support/search-actionplan/iframe-modal-functions.js'];

      scripts.forEach((src: string) => {
        const script = this._renderer2.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        this._renderer2.appendChild(this._document.body, script);
      });
  }
}
