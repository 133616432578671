import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { SvcToastService } from "projects/lib-shared-component/src/public-api";

@Injectable({
	providedIn: 'root'
})
export class HttpErrorService {
	constructor(
		private translocoService: TranslocoService,
		private toastService: SvcToastService,
	) {
	}

	public showErrorInToast(error: HttpErrorResponse): void {
    const msg = this.getErrorMessage(error);
    this.toastService.error(msg);
	}

	public getErrorMessage(error: HttpErrorResponse): string {
    const errors = error?.error?.errors;

    if (errors && typeof errors === 'object'){
			for (const key in errors) {
				if (errors[key]) {
					const value = errors[key];
					const originalMsg = Array.isArray(value) ? (value.length ? value[0] : '') : value;
					if (value && this.checkIfTermExists(originalMsg)) {
						return this.translocoService.translate(originalMsg);
					}
				}
			}
		}
		
		return this.getDefaulfErrorMessage();
	}

	private getDefaulfErrorMessage(): string {
		return this.translocoService.translate('Ocorreu um erro, tente novamente em outro momento');
	}

	private checkIfTermExists(term: string) {
		try {
			const translation = this.translocoService.getTranslation().entries().next().value;
			const mappedTerms = (translation.length > 1 ? translation[1] : null) ?? {};
			return term && mappedTerms && (term in mappedTerms);
		}
		catch {
			return false;
		}
	}
}