<div class="fbp-add-button">
  <button
    [@headerFadeIn]="{ value: '', params: { delay: delay + diffDelay, time } }"
    mat-icon-button
    class="w-5 min-w-5 h-5 min-h-5 bg-primary text-on-primary rounded-full remove-text-disabled-color disabled:opacity-60"
    [class.invisible]="!parentItemsPropName || !parent.editMode"
    [disabled]="parentItemsPropName && parent[parentItemsPropName].length >= 10"
    (click)="onItemAddRequested.emit()"
  >
    <mat-icon class="text-current icon-size-3" svgIcon="fontawesome_solid:plus"></mat-icon>
  </button>
  <div
    [@headerFadeIn]="{ value: '', params: { delay: delay + diffDelay + 100, time } }"
    class="text-base font-medium"
  >
    {{ name | transloco }}
  </div>
</div>
<div
  class="fbp-arrow-and-items"
  [@arrowFadeIn]="{ value: '', params: { delay, time: time * 3 } }"
  (@arrowFadeIn.done)="onAnimationsFinished()"
>
  <div class="fbp-arrow">
    <div class="fbp-line"></div>
    <mat-icon
      class="relative icon-size-5 text-current"
      [ngClass]="isUp ? 'translate-y-3' : '-translate-y-3'"
      [svgIcon]="'heroicons_solid:' + (isUp ? 'chevron-up' : 'chevron-down')"
    ></mat-icon>
  </div>
  <div *ngIf="parentItemsPropName && animationsWillFinish" class="fbp-items">
    <svc-fishbone-part-item *ngFor="let item of parent[parentItemsPropName]; let i = index;"
      [item]="item"
      [index]="i"
      (onItemEditRequested)="onItemEditRequested.emit($event)"
      (onItemDeleteRequested)="onItemDeleteRequested.emit($event)"
    ></svc-fishbone-part-item>
  </div>
</div>