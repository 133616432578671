<div class="flex-auto flex flex-col items-center">
  <div class="fb-part-1">
    <svc-fishbone-part *ngFor="let part of topParts"
      pointing="down"
      [type]="part.type"
      (onItemAddRequested)="onItemAddWasRequested(part.type)"
      (onItemEditRequested)="onItemEditWasRequested($event, part.type)"
      (onItemDeleteRequested)="onItemDeleteWasRequested($event, part.type)"
    ></svc-fishbone-part>
  </div>
  <div class="fb-effect-line text-black" [@arrowFadeIn]="{ value: '', params: { delay, time } }">
    <mat-icon class="icon-size-5 text-current -ml-3" svgIcon="heroicons_solid:chevron-right"></mat-icon>
  </div>
  <div class="fb-part-2">
    <svc-fishbone-part *ngFor="let part of bottomParts"
      pointing="up"
      [type]="part.type"
      (onItemAddRequested)="onItemAddWasRequested(part.type)"
      (onItemEditRequested)="onItemEditWasRequested($event, part.type)"
      (onItemDeleteRequested)="onItemDeleteWasRequested($event, part.type)"
    ></svc-fishbone-part>
  </div>
</div>
<div class="fb-effect-part" [@arrowFadeIn]="{ value: '', params: { delay: delay + 200, time } }">
  <div>
    {{ 'Efeito' | transloco }}
  </div>
  <div *ngIf="editMode">
    <button
      class="flex items-center justify-center w-5 min-w-5 h-5 min-h-5 text-primary cursor-pointer"
      [matTooltip]="'Editar' | transloco"
      matTooltipPosition="right"
      [matTooltipShowDelay]="500"
      (click)="editEffect()"
    >
      <mat-icon class="text-current icon-size-4" svgIcon="heroicons_solid:pencil-square"></mat-icon>
    </button>
  </div>
  <div *ngIf="effect" class="fb-effect-value">
    {{ effect }}
  </div>
</div>
<div *ngIf="isLoading" class="absolute inset-0 flex items-center justify-center text-primary z-99" @fadeInOut>
  <div class="absolute inset-0 bg-white opacity-70 rounded-md"></div>
  <mat-spinner
    class="absolute left-1/2 top-1/2 z-99 rounded-full bg-white text-current"
    diameter="30"
    [style.transform]="'translate(-50%, -50%)'"
    [style.box-shadow]="'0 0 80px 80px white'"
  ></mat-spinner>
</div>