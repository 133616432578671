import { SvcFishboneType } from "./svc-fishbone.component";

export const fishbonePartDelayStep = 100;

export function getNameByType(type: SvcFishboneType) {
  switch(type) {
    case SvcFishboneType.PEOPLE: return 'Pessoas';
    case SvcFishboneType.ENVIRONMENT: return 'Ambiente';
    case SvcFishboneType.MATERIAL: return 'Material';
    case SvcFishboneType.MEASUREMENTS: return 'Medidas';
    case SvcFishboneType.METHOD: return 'Método';
    case SvcFishboneType.MACHINE: return 'Máquina';
  }
}

export function getPropNameByType(type: SvcFishboneType) {
  switch(type) {
    case SvcFishboneType.PEOPLE: return 'people';
    case SvcFishboneType.ENVIRONMENT: return 'environment';
    case SvcFishboneType.MATERIAL: return 'material';
    case SvcFishboneType.MEASUREMENTS: return 'measurements';
    case SvcFishboneType.METHOD: return 'method';
    case SvcFishboneType.MACHINE: return 'machine';
  }
}

export function getAnimationDelayByType(type: SvcFishboneType) {
  switch(type) {
    case SvcFishboneType.PEOPLE: return 0;
    case SvcFishboneType.ENVIRONMENT: return  fishbonePartDelayStep;
    case SvcFishboneType.MATERIAL: return 2 * fishbonePartDelayStep;
    case SvcFishboneType.MEASUREMENTS: return 3 * fishbonePartDelayStep;
    case SvcFishboneType.METHOD: return 4 * fishbonePartDelayStep;
    case SvcFishboneType.MACHINE: return 5 * fishbonePartDelayStep;
  }
}

export function getAnimationDiffDelayByType(type: SvcFishboneType) {
  switch(type) {
    case SvcFishboneType.PEOPLE: return (5 * fishbonePartDelayStep) - getAnimationDelayByType(type);
    case SvcFishboneType.ENVIRONMENT: return (5 * fishbonePartDelayStep) - getAnimationDelayByType(type);
    case SvcFishboneType.MATERIAL: return (5 * fishbonePartDelayStep) - getAnimationDelayByType(type);
    case SvcFishboneType.MEASUREMENTS: return (5 * fishbonePartDelayStep) - getAnimationDelayByType(type);
    case SvcFishboneType.METHOD: return (5 * fishbonePartDelayStep) - getAnimationDelayByType(type);
    case SvcFishboneType.MACHINE: return (5 * fishbonePartDelayStep) - getAnimationDelayByType(type);
  }
}