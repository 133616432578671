<svc-dialog #dialog [hasActions]="false" [hideCloseButton]="true">
  <div svc-dialog-title class="flex items-center justify-end w-full">
    <button class="-mr-2" mat-icon-button (click)="closeDialog()">
      <mat-icon class="text-current icon-size-5" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
  </div>

  <div svc-dialog-content class="m-4">
    <div *ngIf="isLoading || setAsViewsIsLoading || setViewsIncrementIsLoading" class="flex items-center justify-center py-30">
      <mat-spinner diameter="30"></mat-spinner>
    </div>

    <ng-container *ngIf="!isLoading && !setAsViewsIsLoading && !setViewsIncrementIsLoading">
      <svc-carousel
        [height]="300"
        [slides]="info?.midiasCarousel"
      ></svc-carousel>

      <h1
        class="text-xl font-bold text-center text-primary leading-tight text-ellipsis pt-2"
        [style.wordWrap]="'break-word'"
        [style.wordBreak]="'break-word'"
      >
        {{ info?.title | transloco }}
      </h1>

      <quill-editor
        *ngIf="info?.editor"
        class="text-viewer text-base font-light w-full px-2 pt-4"
        [modules]="{ toolbar: false, clipboard: { matchVisual: false } }"
        [(ngModel)]="info.editor"
        [readOnly]="true"
      ></quill-editor>

      <div class="px-2 pt-10">
        <div class="flex flex-row items-center pb-1">
          <div class="pb-1">
            <svc-total-reactions
              [registryUniqueId]="communicationId"
              [applicationId]="applicationId"
              [reaction]="svcReaction"
              [mockMode]="isPreview"
            ></svc-total-reactions>
          </div>
          <div class="flex-auto flex items-center justify-end">
            <svc-totals-status #totalStatus
              class="h-fit w-fit"
              [referenceId]="communicationId"
              [applicationId]="applicationId"
              [configTypeId]="configTypeId"
              [featureName]="featureName"
              [mockMode]="isPreview"
              [available]="{ comments: true, views: true }"
              type="text"
            ></svc-totals-status>
          </div>
        </div>

        <hr class="m-0">
      </div>

      <div mat-dialog-actions>
        <div class="flex flex-col w-full">
          <div class="flex">
            <div class="flex flex-row justify-evenly">
              <svc-reaction #svcReaction
                class="flex flex-auto"
                [registryUniqueId]="communicationId"
                [applicationId]="applicationId"
                [disabled]="isPreview || isReactionLoading"
                positionX="left"
                (onLoadingChange)="isReactionLoading = $event"
                (onReactionChange)="isReactionDone = !!($event)"
              >
                <button class="flex flex-auto rounded-none cursor-pointer" mat-flat-button type="button" [ngClass]="isReactionDone ? 'text-primary' : 'text-default'" (click)="!isPreview && !isReactionLoading && (isReactionDone ? svcReaction?.removeCurrentReaction() : svcReaction?.makeReactionByEnum('LIKE'))">
                  <ng-container *ngIf="isPreview || !isReactionLoading">
                    <mat-icon class="align-middle icon-size-4.5 text-current" svgIcon="heroicons_solid:hand-thumb-up"></mat-icon>
                    <span class="align-middle ml-1 text-base font-medium leading-tight">{{ 'Reagir' | transloco }}</span>
                  </ng-container>
                  <div class="flex align-center justify-center p-2" *ngIf="!isPreview && isReactionLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                  </div>
                </button>
              </svc-reaction>
            </div>
            <button *ngIf="env.isDEV || env.isQA" class="flex rounded-none text-default" mat-flat-button type="button" (click)="showComments()">
              <mat-icon class="align-middle icon-size-4.5 text-current" svgIcon="mat_solid:chat"></mat-icon>
              <span class="align-middle ml-1 text-base font-medium leading-tight">{{ "Comentar" | transloco }}</span>
            </button>
          </div>
          <svc-comments-chat *ngIf="showCommentsChat"
            #svcCommentsChat
            class="block mt-5"
            [referenceId]="communicationId"
            [configTypeId]="configTypeId"
            [applicationId]="applicationId"
            (onCommentAdded)="totalStatus?.refresh()"
            (onCommentRemoved)="totalStatus?.refresh()"
          ></svc-comments-chat>
        </div>
      </div>
    </ng-container>
  </div>
</svc-dialog>
