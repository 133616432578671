import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';
import { NavigationService } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.service';
import { Navigation, SvcDefaultNavigationItem } from 'projects/lib-shared-core/src/lib/features/navigation/services/navigation.types';
import { User, UserEnvironment } from 'projects/lib-shared-core/src/lib/features/user/services/user.types';
import { UserService } from 'projects/lib-shared-core/src/lib/features/user/services/user.service';
import { VerticalNavigationComponent } from '../components/vertical-navigation/vertical-navigation.component';
import { SvcMediaQuery } from 'projects/lib-shared-common/src/lib/services/svc-media-query.service';
import { LayoutService } from '../../layout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RequestCollectorService } from 'projects/lib-shared-common/src/public-api';
import { SidebarNavigationComponent } from "../components/sidebar-navigation/sidebar-navigation.component";
import { environment } from 'projects/environments/environment';
import { MainTasksService } from 'projects/lib-shared-core/src/lib/features/main-tasks/main-tasks.service';
import { SvcFeatureToggleService } from 'projects/lib-shared-core/src/public-api';

@UntilDestroy()
@Component({
  selector: 'svc-custom-layout',
  templateUrl: './solvace-custom-layout.component.html',
  styleUrls: ['./solvace-custom-layout.component.scss'],
})
export class SolvaceCustomLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  navigation: Navigation;
  user: User;
  environment: UserEnvironment;
  urlHome = `${location.origin}/myworkspace/`;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  showTableDatail = false;
  currentNavigationItem: SvcDefaultNavigationItem;
  public env = environment;
  public feedIsEnabled = false;

  @ViewChild(VerticalNavigationComponent)
  verticalNavigation: VerticalNavigationComponent;

  @ViewChild('appsPanel') appsPanel: any;
  @ViewChild('sidebarNavigationComponent') sidebarNavigationComponent: SidebarNavigationComponent;
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _layoutService: LayoutService,
    private _mediaQuery: SvcMediaQuery,
    private _requestCollectorService: RequestCollectorService,
    private _mainTasksService: MainTasksService,
    private _featureToggleService: SvcFeatureToggleService,
  ) {
    this.feedIsEnabled = this._featureToggleService.feedIsEnabled;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._router.events.pipe(
      untilDestroyed(this),
      tap((event: any) => {
        if (event instanceof NavigationStart) {
          const currentUrl = this._router.url.replace('/', '');
          const nextUrl = event.url.replace('/', '');
          if (currentUrl !== nextUrl) {
            this._requestCollectorService.cancelAll();
          }
        }
      }),
    ).subscribe();

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
        if (this.navigation.default.length) {
          for (let item of this.navigation.default) {
            for (let childItem of item.children) {
              if (typeof childItem.url === 'string') {
                if (this._router.isActive(childItem.url, true)) {
                  item['expanded'] = true;
                  this.currentNavigationItem = item;
                  break;
                }
              }
            }
            if (this.currentNavigationItem != null) {
              break;
            }
          }
        }
      });

    // Subscribe to the user service
    this.user = this._userService.user$;
    this.environment = this._userService.environment$;

    this._mainTasksService.getUserDrillDowns();
  }

  /**
   * AfterViewInit
   */
  ngAfterViewInit(): void {
    this._layoutService.setVerticalNavigationComponent(this.verticalNavigation);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public toggleNavigation(): void {
    if (this._mediaQuery.currentSize.isBelowMD) {
      this.verticalNavigation.toggle();
    }
  }

  public toggleNavigationRetraction() {
    this.verticalNavigation?.toggleRetraction();
    if (this.currentNavigationItem) {
      this.currentNavigationItem['expanded'] = !this.currentNavigationItem;
      this.currentNavigationItem = null;
    }
  }

  public toggleCurrentNavigationItem(navigationItem: SvcDefaultNavigationItem) {
    if (navigationItem != this.currentNavigationItem) {
      if (this.currentNavigationItem != null) {
        this.currentNavigationItem['expanded'] = false;
      }
      navigationItem['expanded'] = true;
      this.currentNavigationItem = navigationItem;
    }
    else if (!this.verticalNavigation.isRetracted) {
      navigationItem['expanded'] = !this.currentNavigationItem;
      this.currentNavigationItem = null;
    }
    if (this.verticalNavigation.isRetracted) {
      this.verticalNavigation.toggleRetraction();
    }
  }

  mobileMenuClick(type) {
    if (type === 'modules')
      this.appsPanel?.toggle();
    if (type === 'tasks')
      this.sidebarNavigationComponent.open('tasks');
    if (type === 'feed')
      this.sidebarNavigationComponent.open('feed');
  }
}
