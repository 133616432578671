<div
  class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 h-full"
  *transloco="let t"
>
  <div
    class="relative hidden md:flex flex-auto items-center justify-center h-full overflow-hidden bg-[#21295C]"
  >
    <div
      class="w-full h-full bg-cover"
      [style.background-image]="backgroundImg"
    ></div>
  </div>
  <div
    class="md:flex md:justify-end w-full md:w-1/4 min-w-96 h-full py-8 px-4 sm:p-12 md:p-16 md:pt-24 rounded-none shadow-none bg-card"
  >
    <div class="w-full max-w-80 mx-auto">
      <!-- Logo -->
      <div class="mt-8 w-60">
        <img class="w-full"
          [src]="solvaceLogo$ | async"
        />
      </div>

      <!-- Title -->
      <div class="mt-8 text-[#21295C] text-4xl font-extrabold tracking-tight leading-tight">
        {{ t("Esqueceu a senha?") }}
      </div>
      <div class="mt-0.5 text-[#21295C] font-medium">{{ t("Nós te mandaremos um código de verificação") }}</div>

      <!-- Alert -->
      <svc-alert
        class="mt-8 -mb-4"
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'"
      >
        {{ alert.message }}
      </svc-alert>

      <!-- Forgot password form -->
      <form class="space-y-6 mt-6" [formGroup]="forgotPasswordForm" #forgotPasswordNgForm="ngForm">
        <!-- User field -->
        <svc-text class="w-full"
          [label]="t('Usuário')"
          [formControl]="forgotPasswordForm.controls.user"
        ></svc-text>

        <!-- Submit button -->
        <button
          type="button"
          class="svc-mat-button-large w-full bg-[#21295C] text-white text-ellipsis"
          mat-flat-button
          (click)="sendResetLink()"
        >
          <ng-container *ngIf="!forgotPasswordForm.disabled">{{ t("Enviar código de verificação") }}</ng-container>
          <mat-progress-spinner
            *ngIf="forgotPasswordForm.disabled"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
        </button>

        <button
          type="button"
          [routerLink]="['/sign-in']"
          class="w-full bg-zinc-100 text-black"
          mat-flat-button
        >
          {{ t("Voltar") }}
        </button>

      </form>
    </div>
  </div>
</div>
