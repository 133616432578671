/*
 * Public API Surface of lib-shared-core
 */

import {AuthCheckService} from './lib/auth/auth-check.service';

export * from './lib/lib-shared-core.module';

export * from './lib/resolver/root-resolver';

export * from './lib/auth/auth.service';
export * from './lib/auth/auth-check.service';
export * from './lib/auth/guards/auth.guard';
export * from './lib/auth/guards/noAuth.guard';
export * from './lib/auth/guards/amplify.guard';
export * from './lib/auth/guards/available-route.guard';
export * from './lib/auth/auth-functions';

export * from './lib/features/user/services/user.service';
export * from './lib/features/user/services/user-indicators.service';
export * from './lib/features/user/services/user.types';
export * from './lib/features/user/user-password/password-validator';
export * from './lib/features/user/user.module';
export * from './lib/features/navigation/services/navigation.service';
export * from './lib/features/navigation/services/navigation.types';
export * from '../../lib-shared-feature/src/lib/general/svc-notifications/services/notifications.service';
export * from '../../lib-shared-feature/src/lib/general/svc-notifications/services/notifications.types';
export * from './lib/features/sites/services/sites.service';
export * from './lib/features/sites/services/sites.types';
export * from './lib/features/apps/services/apps.service';
export * from './lib/features/apps/services/apps.types';
export * from './lib/features/apps/services/parameter.service';
export * from './lib/features/settings/settings.module'
export * from './lib/features/settings/services/settings.service';

export * from './lib/features/404/404.module';
export * from './lib/features/404/404.component';

export * from './lib/apps/svc-router-outlet/svc-router-outlet.component';

export * from './lib/features/page-cant-be-load/page-cant-be-load.module';
export * from './lib/features/page-cant-be-load/page-cant-be-load.component';

export * from './lib/features/main-tasks/main-tasks.module';
export * from './lib/features/main-tasks/main-tasks/main-tasks.component';

export * from './lib/features/transloco/transloco.module';

export * from './lib/features/user/models/user-avatar';
export * from './lib/features/user/models/svc-user-preferences-events.model';
export * from './lib/features/user/models/svc-user-preferences.model';
export * from './lib/features/user/services/user-preferences.service';

export * from './lib/Constants/static-application-id.enum';

export * from './lib/settings/svc-app-settings';
export * from './lib/settings/svc-app-settings.token';
export * from './lib/settings/enums/svc-module.enum';

export * from './lib/services/svc-views.service';

export * from './lib/services/svc-feature-toggle/svc-feature-toggle.service';

export * from './lib/services/counters/public-api';

export * from './lib/services/user-avatar/public-api';

export * from './lib/features/gamification/public-api';

