import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { debounceTime, distinctUntilChanged, filter } from 'rxjs';

import { SvcDataTableAccordion, SvcDataTableAccordionColumn } from './interfaces/svc-data-table-accordion.interface';

@Component({
  selector: 'svc-data-table-accordion',
  templateUrl: './svc-data-table-accordion.component.html',
  styleUrls: ['./svc-data-table-accordion.component.scss']
})
export class SvcDataTableAccordionComponent implements OnChanges {

  @Input() public showSearchControl = true;
  @Input() public showOpenAllAccordions = true;
  @Input() public showDownloadOptions = true;
  @Input() public isAllExpanded: boolean;
  @Input() public dataTable: SvcDataTableAccordion;
  @Input() public isLoading: boolean;
  @Input() public isExporting: boolean;
  @Input() public items: any[];
  @Input() public classContainer: string = 'mx-6';

  @Output() public exportFile = new EventEmitter<'pdf' | 'excel'>();
  @Output() public onSubRowClicked = new EventEmitter<any>();
  @Output() public barClicked = new EventEmitter<any>();

  public sort: MatSort;
  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    if (matSort) {
      this.sort = matSort;
      this.sort.start = 'desc';
      this.setDataSourceAttributes();
    }
  }

  public dataSource = new MatTableDataSource<any>();
  public searchControl = new FormControl();
  public expandedRows = new Set<any>();

  constructor() {
    this.onSearchByText();
  }

  public ngOnChanges(): void {
    this.updateData();
  }

  private onSearchByText(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        filter((text: string) => text?.length >= 3 || text?.length === 0),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => this.searchItems()
      });
  }

  private setDataSourceAttributes(): void {
    this.dataSource.sort = this.sort;
  }

  private updateData(): void {
    this.dataSource.data = this.items;
  }

  public getColumnLabels(): string[] {
    return this.dataTable?.columns?.map((column: SvcDataTableAccordionColumn, index: number) => column?.label + index);
  }

  public getColumnLabelsAccordion(): string[] {
    return this.dataTable?.columns?.map((column: SvcDataTableAccordionColumn, index: number) =>
      this.dataTable?.showHeaderRowSubItems && column?.accordion?.label ? column?.accordion?.label : column?.label + (index + this.dataTable?.columns?.length)
    );
  }

  public toggleExpanded(row: any): void {
    if (!this.isAllExpanded)
      this.expandedRows.has(row) ? this.expandedRows.delete(row) : this.expandedRows.add(row);
  }

  public toggleIsAllExpanded(): void {
    this.isAllExpanded = !this.isAllExpanded;
    this.expandedRows.clear();
  }

  public searchItems(): void {
    const searchText = this.searchControl.value.trim().toLowerCase();
    const filteredItems = this.items.filter((item: any) =>
      this.dataTable.columns.some((column: SvcDataTableAccordionColumn) =>
        column?.isSearchable && item[column.property]?.toString().toLowerCase().includes(searchText)
      )
    );

    this.dataSource.data = filteredItems;
  }

  public barClick(pointerEvent: PointerEvent, row: any): void {
    pointerEvent.stopPropagation();
    this.barClicked.emit(row);
  }
}
