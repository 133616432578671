<div class="truncate">
  <div #textEl
    class="truncate border text-primary border-primary bg-primary-100 px-2 py-0.5 text-sm leading-none font-medium rounded-full"
    [matTooltip]="(textEl.scrollWidth > textEl.clientWidth) ? item.text : null"
    matTooltipPosition="right"
    [matTooltipShowDelay]="750"
  >
    {{ item.text }}
  </div>
</div>
<div class="flex text-black items-center min-w-16">
  <div class="flex pl-1">
    <button *ngIf="parent.editMode"
      class="flex items-center justify-center -ml-0.5 w-4 min-w-4 h-4 min-h-4 text-primary cursor-pointer"
      [matTooltip]="'Editar' | transloco"
      matTooltipPosition="right"
      [matTooltipShowDelay]="500"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon class="text-current icon-size-4" svgIcon="heroicons_solid:pencil-square"></mat-icon>
    </button>
    <mat-menu #menu>
      <button mat-menu-item class="text-default" (click)="onItemEditRequested.emit(item)">
        <mat-icon class="text-current icon-size-5" svgIcon="heroicons_solid:pencil-square"></mat-icon>
        <span class="text-base">{{ 'Editar' | transloco }}</span>
      </button>
      <button mat-menu-item class="text-default" (click)="onItemDeleteRequested.emit(item)">
        <mat-icon class="text-current icon-size-5" svgIcon="heroicons_solid:trash"></mat-icon>
        <span class="text-base">{{ 'Excluir' | transloco }}</span>
      </button>
    </mat-menu>
  </div>
  <div class="flex-auto h-0.5 bg-black"></div>
  <mat-icon class="icon-size-5 text-current -ml-3" svgIcon="heroicons_solid:chevron-right"></mat-icon>
</div>