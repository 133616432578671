import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorService, OrderByPipe } from 'projects/lib-shared-common/src/public-api';
import { SvcDataTableComponent, SvcSearchFieldComponent } from 'projects/lib-shared-component/src/public-api';
import { SvcAppSettings } from 'projects/lib-shared-core/src/public-api';
import { Observable, Subject, catchError, debounceTime, finalize, map, startWith, takeUntil, tap } from 'rxjs';
import { UserRole } from './models/user-role.model';
import { SvcAccessPermissionFormComponent } from './svc-access-permission-form/svc-access-permission-form.component';
import { SvcAccessPermissionService } from './svc-access-permission.service';

@Component({
  selector: 'svc-access-permission',
  templateUrl: './svc-access-permission.component.html',
  styleUrls: ['./svc-access-permission.component.scss']
})
export class SvcAccessPermissionComponent implements OnInit, OnDestroy {

  @ViewChild(SvcDataTableComponent) svcDataTable: SvcDataTableComponent;
  @ViewChild(SvcSearchFieldComponent) searchField: SvcSearchFieldComponent;

  filteredUsers: Observable<UserRole[]>;
  applicationId: string;
  users: UserRole[];
  roles: { roleId: string, roleName: string, propName: string }[];
  moduleName: string;
  loadingPermissions: boolean = true;
  public exporting = false;
  public sortColumns = {
    'firstLastName': 'FirstLastName',
    'email': 'Email',
    'userName': 'UserName',
  };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild(SvcAccessPermissionFormComponent) permissionForm: SvcAccessPermissionFormComponent;

  constructor(
    private _accessPermissionService: SvcAccessPermissionService,
    private _translocoService: TranslocoService,
    private _appSettings: SvcAppSettings,
    private _httpErrorService: HttpErrorService
  ) {
    this.moduleName = this._translocoService.translate(_appSettings.applicationName);
    this.applicationId = this._appSettings.applicationId;
  }

  ngOnInit(): void {
    this.loadingPermissions = true;
    this._accessPermissionService.getRoles(this.applicationId).pipe(
      tap(roles => {
        roles = new OrderByPipe().transform(roles, 'roleName', true);
        this.roles = roles.map((role) => {
          const propName = role.roleName.replace(/\s/g, '').toLowerCase();
          this.sortColumns[propName] = role.roleName;
          return {
            ...role,
            propName: propName,
          };
        });
      }),
      catchError((error) => {
        this.roles = [];
        return error;
      }),
      finalize(() => this.loadPermissions()),
      catchError(err => {
        this._httpErrorService.showErrorInToast(err);
        return err;
      }),
    ).subscribe();
  }

  public onUserEdit(user: UserRole) {
    this.permissionForm.editUser(user);
  }

  public loadPermissions() {
    this.loadingPermissions = true;
    this._accessPermissionService.getUsersPerRole(this.applicationId).pipe(
      tap((users) => {
        this.users = users;
        if (!this.filteredUsers) {
          this.filteredUsers = this.searchField?.onChanged.pipe(
            startWith(''),
            takeUntil(this._unsubscribeAll),
            map((value) => {
              let users = this.users ?? [];
              if (value) {
                value = value.toLowerCase();
                users = users.filter(x => x.email?.toLowerCase().includes(value) ||
                  x.firstLastName?.toLowerCase().includes(value) ||
                  x.userName?.toLowerCase().includes(value));
              }
              return users.map(user => ({
                ...user,
                ...this.roles.reduce((object, role) => {
                  const propName = role.roleName.replace(/\s/g, '').toLowerCase();
                  object[propName] = user.roleItems.some(x => x.roleName.replace(/\s/g, '').toLowerCase() == propName);
                  return object;
                }, {}),
              }));
            })
          );
        }
        else {
          this.searchField.setValue('', { emitEvent: true });
        }
        this.svcDataTable?.setCurrentPage(1);
      }),
      catchError(err => {
        this._httpErrorService.showErrorInToast(err);
        return err;
      }),
      finalize(() => this.loadingPermissions = false)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public exportFile(fileType: 'pdf' | 'excel'): void {
    if (this.users?.length) {
      this.exporting = true;
      const colSorting = this.svcDataTable.colSorting;
      let params = {
        pageIndex: this.svcDataTable.currentPage,
        pageSize: this.svcDataTable.paginate,
      } as any;
      if (colSorting) {
        params.sortColumnText = this.sortColumns[colSorting?.col.field];
        params.sortDirection = colSorting?.asc ? '1' : '2';
      }
      if (this.searchField.value) {
        params.searchText = this.searchField.value;
      }
      this._accessPermissionService.exportFile(params, this.applicationId, fileType).pipe(
        tap((responseBlob: HttpResponse<Blob>) => this.downloadFileFromResponse(responseBlob)),
        catchError(err => {
          this.exporting = false;
          this._httpErrorService.showErrorInToast(err);
          return err;
        }),
        finalize(() => this.exporting = false)
      ).subscribe();
    }
  }

  private downloadFileFromResponse(responseBlob: HttpResponse<Blob>) {
    const fileName = responseBlob.headers.get('content-disposition')?.split(';')[1].split('=')[1];
    const blobFile: Blob = responseBlob.body as Blob
    const link = document.createElement('a');
    link.download = fileName ?? this._translocoService.translate('Permissão de Acesso');
    link.href = URL.createObjectURL(blobFile);
    link.click();
  }
}

