
<div class="relative p-5">
	<div class="flex flex-col">
		<div class="flex gap-3">
			<svc-button size="sm" [mode]="example?.id === 1 ? 'flat' : 'stroked'" (click)="setExample(1)" class="mb-5">
				EXAMPLE 01 - CLIENT SIDE (PAGINATE)
			</svc-button>
			<svc-button size="sm" [mode]="example?.id === 2 ? 'flat' : 'stroked'" (click)="setExample(2)" class="mb-5">
				EXAMPLE 02 - SERVER SIDE (PAGINATE)
			</svc-button>
			<svc-button size="sm" [mode]="example?.id === 3 ? 'flat' : 'stroked'" (click)="setExample(3)" class="mb-5">
				EXAMPLE 03 - SERVER SIDE (INFINITY)
			</svc-button>
		</div>
		<div class="flex flex-col">

			<div *ngIf="example?.id === 1" class="mt-10 relative">
				<svc-data-table #dt paginate="10" [currentPage]="currentPage" [items]="items" [loading]="loading" [totalRegisters]="" minWidth="1500px">
					<svc-data-header headerColorClass="bg-[#17457A] text-white">
						<svc-data-col field="id" width="100px">ID 1</svc-data-col>
						<svc-data-col field="id" width="150px" freezeUntilHere>ID 2</svc-data-col>
						<svc-data-col field="name" placeholder="Informe o nome">Nome</svc-data-col>
						<svc-data-col field="dateRef" width="200px">Data</svc-data-col>
						<svc-data-col field="type" [options]="types" placeholder="Informe o Tipo" width="150px">
							Tipo
						</svc-data-col>
					</svc-data-header>
					<svc-data-body>
						<svc-data-row *ngFor="let teste of dt.items">
							<svc-data-cell>{{ teste.id }} - 1</svc-data-cell>
							<svc-data-cell>{{ teste.id }} - 2</svc-data-cell>
							<svc-data-cell>
								{{ teste.name }}
							</svc-data-cell>
							<svc-data-cell>{{ teste.dateRef | dateFormat }}</svc-data-cell>
							<svc-data-cell>{{ teste.type }}</svc-data-cell>
						</svc-data-row>
					</svc-data-body>
				</svc-data-table>
			</div>

			<div *ngIf="example?.id === 2" class="mt-10 relative">
				<svc-data-table #dt [currentPage]="currentPage" [items]="items" server-side [dataLength]="dataLength" [loading]="loading" (onGetData)="getData($event)">
					<svc-data-header>
						<svc-data-col field="id" placeholder="ID" width="100px">ID</svc-data-col>
						<svc-data-col field="name" placeholder="Informe o nome">Nome</svc-data-col>
						<svc-data-col field="dateRef" width="200px">Data</svc-data-col>
						<svc-data-col field="type" [options]="types" placeholder="Informe o Tipo" width="150px">
							Tipo
						</svc-data-col>
						<svc-data-col no-sort width="50px"></svc-data-col>
					</svc-data-header>
					<svc-data-body drag-and-reorder (onDragReorder)="onDragReorder($event)">
						<svc-data-row *ngFor="let teste of dt.items">
							<svc-data-cell>{{ teste.id }}</svc-data-cell>
							<svc-data-cell>
								{{ teste.name }}
							</svc-data-cell>
							<svc-data-cell>{{ teste.dateRef | dateFormat }}</svc-data-cell>
							<svc-data-cell>{{ teste.type }}</svc-data-cell>
							<svc-data-cell (click)="removeItem(teste)" class="text-center cursor-pointer">
								<i class="sbi-delete-payment"></i>
							</svc-data-cell>
						</svc-data-row>
					</svc-data-body>
				</svc-data-table>
			</div>

			<div *ngIf="example?.id === 3" class="mt-10 relative">
				<svc-data-table #dt height="500px" [infinite]="pageSize" [currentPage]="currentPage" [items]="items" [dataAreOver]="dataAreOver" server-side [loading]="loading" (onGetData)="getData($event)">
					<svc-data-header>
						<svc-data-col field="id" placeholder="ID" width="100px">ID</svc-data-col>
						<svc-data-col field="name" placeholder="Informe o nome">Nome</svc-data-col>
						<svc-data-col field="dateRef" width="200px">Data</svc-data-col>
						<svc-data-col field="type" [options]="types" placeholder="Informe o Tipo" width="150px">Tipo</svc-data-col>
					</svc-data-header>
					<svc-data-body>
						<svc-data-row *ngFor="let teste of dt.items">
							<svc-data-cell>{{ teste.id }}</svc-data-cell>
							<svc-data-cell>
								{{ teste.name }}
							</svc-data-cell>
							<svc-data-cell>{{ teste.dateRef | dateFormat }}</svc-data-cell>
							<svc-data-cell>{{ teste.type }}</svc-data-cell>
						</svc-data-row>
					</svc-data-body>
				</svc-data-table>
			</div>

		</div>
	</div>
</div>
