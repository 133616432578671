import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { GetCounterReactionResponse, GetCounterReactionsParams } from './reactions-counter-classes';
import { AbstractCounterBusStop } from '../abstract-counter-bus-stop';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvcReactionsCounterService extends AbstractCounterBusStop<GetCounterReactionsParams, GetCounterReactionResponse> {

  constructor(
    protected appConfig: AppEnvironmentConfig,
  ) {
    super({
      apiURL: appConfig.APIs.apiUrlReaction,
      registryIdFilterPropName: 'registryUniqueId',
      registryUidFilterPropName: 'registryUniqueUId',
      idSingleParamName: 'registryUniqueId',
      uidSingleParamName: 'registryUniqueUId',
      idBatchParamName: 'registryUniqueIds',
      uidBatchParamName: 'registryUniqueUIds',
      idBatchRequestPath: '/CountersId/RegistryUniqueIds',
      uidBatchRequestPath: '/CountersUid/RegistryUniqueUIds',
      idSingleRequestPath: '/CountersId',
      uidSingleRequestPath: '/CountersUid',
    });
  }

  public getCountByRegistryId(registryId: number | string, params: GetCounterReactionsParams): Observable<GetCounterReactionResponse[]> {
    return this.getCountByIdOrUid(registryId, params);
  }
}

