export function offsetToParent(child: HTMLElement, parent: HTMLElement): number {
	const parentBeforePosition = parent?.style.position ?? '';
	if (parent) parent.style.position = 'relative';

	let curTop = 0;
	if (child.offsetParent) {
		curTop = child.offsetTop;
		while ((child = child.offsetParent as HTMLElement)) {
			if (child === parent) {
				break;
			}
			curTop += child.offsetTop;
		}
	}

	if (parent) parent.style.position = parentBeforePosition;

	return curTop;
}

export function srollBottom(element: HTMLElement, animated: boolean = false) {
	if (element) {
		if (!animated) element.scrollTo(element.scrollLeft, element.scrollHeight);
		else {
			const initialScrollHeight = element.scrollHeight;
			let currentScroll = element.scrollTop;

			const loop = setInterval(() => {
				if (element.scrollHeight === initialScrollHeight) {
					currentScroll = element.scrollTop + 10;
					element.scrollTo(element.scrollLeft, currentScroll);

					if (currentScroll < initialScrollHeight) return;
				}
				clearInterval(loop);
			}, 20);
		}
	}
}

export function reachedBottom(element: HTMLElement, percentProximity?: number) {
	const scrollHeight = element.scrollHeight;
	const scrolledTop = element.scrollTop + element.clientHeight;
	return (scrolledTop + (!isNaN(percentProximity) ? (scrollHeight * percentProximity) / 100 : 0)) >= scrollHeight;
}