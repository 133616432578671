import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
import { SitesService } from '../../../sites/services/sites.service';
import { AvailableHashtags, Hashtag, PostHashtag, UserHashtags } from './user-hashtags.types';


@Injectable({
  providedIn: 'root'
})
export class UserHashtagsService {

  private userHashtags: Hashtag[] = [];
  private allHashtags: AvailableHashtags[] = [];
  private sites: any;
  private _hashtags: BehaviorSubject<Hashtag[]> = new BehaviorSubject<Hashtag[]>(this.userHashtags);

  hashtags$ = this._hashtags.asObservable();

  private _filteredHashtagClicked = new BehaviorSubject<Hashtag[]>([]);
  public filteredHashtagClicked$ = this._filteredHashtagClicked.asObservable();

  private _clearHashtagPreferenceFilter = new ReplaySubject<boolean>(1);
  public clearHashtagPreferenceFilter$: Observable<boolean> = this._clearHashtagPreferenceFilter.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppEnvironmentConfig,
    private _siteService: SitesService
  ) {
    this._siteService.sites$.subscribe(r => this.sites = r)
  }

  getUserHashtags(): Observable<UserHashtags[]> {
    return this._httpClient.get<UserHashtags[]>(`${this._appConfig.APIs.apiUrlHashtag}/Hashtag/ByUser`)
      .pipe(
        map((response) => response.map((group) => <UserHashtags>({
          siteName: group.siteName,
          hashtags: <Hashtag[]>group.hashtags.map((h, i) => ({
            ...h,
            name: '#' + h.name.replace(/^#/g, ''),
            order: i + 1,
          }))
        }))),
        tap(response => {
          this.userHashtags = this.formatHashtags(response);
          this._hashtags.next(this.userHashtags)
        })
      )
  }

  filteredHashtagClicked(hashTagId: Hashtag[]) {
    this._filteredHashtagClicked.next(hashTagId)
  }

  clearHashtagPreferenceFilter(clean: boolean) {
    this._clearHashtagPreferenceFilter.next(clean)
  }

  getAvailableHashtags(siteId?: number): Observable<AvailableHashtags[]> {
    const params: { [key: string]: any } = {
      globalhashtags: true,
      active: true
    };
    if (siteId) {
      params.siteId = siteId;
    }
    return this._httpClient.get<AvailableHashtags[]>(`${this._appConfig.APIs.apiUrlHashtag}/Hashtag/DropList`, {
      params,
    })
  }

  addHashtag(hashtagIds: number[]): Observable<any> {
    return this._httpClient.post(`${this._appConfig.APIs.apiUrlHashtag}/HashtagUserSubscribe/Subscribe`, hashtagIds).pipe(
      tap(r => {
        hashtagIds.forEach(h => {
          let hashtag = null
          this.allHashtags.forEach(v => {
            v.types.forEach(t => {
              const tempHash = t.hashtags.find(x => x.hashtagId === h)
              if (tempHash) {
                hashtag = tempHash
              }
            })
          })
          if (hashtag) {
            this.userHashtags.push(hashtag)
            this._hashtags.next(this.userHashtags)
          }
        })
      })
    )
  }

  removeHashtag(hashtagId: number): Observable<any> {
    return this._httpClient.delete(`${this._appConfig.APIs.apiUrlHashtag}/HashtagUserSubscribe/RemoveHashtagUserSubscribeByHashtagIdAndUserId/${hashtagId}`).pipe(
      tap(r => {
        const index = this.userHashtags.findIndex(i => i.hashtagId === hashtagId)
        if (index >= 0) {
          this.userHashtags.splice(index, 1)
          this._hashtags.next(this.userHashtags)
        }
      }
      )
    )
  }

  private formatHashtags(items: UserHashtags[]): Hashtag[] {
    const hashtags = [];
    items.forEach((item) => item.hashtags.forEach((hashtag) => {
      hashtags.push({ ...hashtag, isGlobal: item.siteName === 'Global' })
    })
    );
    return hashtags;
  }

  getPostHashtags(term: string): Observable<PostHashtag> {
    return this._httpClient.get<PostHashtag>(`${this._appConfig.APIs.apiUrlHashtag}/Post/Paginated`, {
      params: {
        pageIndex: 0,
        pageSize: 8,
        name: term,
        active: true
      }
    })
  }

  updateHashtagsOrder(hashtags: { id: number, order: number }[]): Observable<PostHashtag> {
    return this._httpClient.put<PostHashtag>(`${this._appConfig.APIs.apiUrlHashtag}/HashtagUserSubscribe`, {
      hashtags
    })
  }
}

