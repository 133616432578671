import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { environment } from 'projects/environments/environment';
import { GetCounterCommentParams, SitesService, SvcActionPlanCounterService, SvcCommentCounterService, SvcReactionsCounterService, SvcViewCounterService } from 'projects/lib-shared-core/src/public-api';
import { Observable, Subscription, finalize, map, take, tap } from 'rxjs';

interface GetReactionTotalsParams {
	applicationId: string;
	registryUniqueId: number | string;
	siteId: number;
}

interface GetCommentsTotalsParams {
	referenceId: number | string;
	configTypeId: string;
	externalSiteId?: number;
}

interface GetViewsTotalsParams {
	referenceId: number | string;
	applicationId: string;
	featureName: string;
}

interface GetActionPlanTotalsParams {
	registryId: number;
	applicationId: string;
}

interface GetTotalsParams {
	applicationId: string;
	siteId: number;
	referenceId: number | string;
	configTypeId: string;
	featureName: string;
	avoid?: {
		reactions: boolean,
		comments: boolean,
		views: boolean,
		actionplans: boolean,
	}
}

@Injectable()
export class SvcTotalsStatusService {

	constructor(
		private _httpClient: HttpClient,
		private _appConfig: AppEnvironmentConfig,
		private _sitesService: SitesService,
		private _reactionsCounterService: SvcReactionsCounterService,
		private _actionPlanCounterService: SvcActionPlanCounterService,
		private _viewCounterService: SvcViewCounterService,
		private _commentCounterService: SvcCommentCounterService,
	) {
	}

	public getTotals(params: GetTotalsParams, external: boolean = false): Observable<{ reactions: number, comments: number, views: number, actionplans: number }> {
		return new Observable<{ reactions: number, comments: number, views: number, actionplans: number }>((subscriber) => {
			const requests: { [key: string]: { loading: boolean, success: boolean, subscription: Subscription, request: Observable<number> } } = {};
			let gotResponse = false;
			let wasCancelled = false;
			if (!(params.avoid?.reactions)) {
				requests['reactions'] = {
					loading: true,
					success: false,
					subscription: null,
					request: this.getReactionsTotals({
						applicationId: params.applicationId,
						registryUniqueId: params.referenceId,
						siteId: params.siteId ?? this._sitesService.currentSite.siteId,
					}),
				};
			}
			if (!(params.avoid?.comments)) {
				requests['comments'] = {
					loading: true,
					success: false,
					subscription: null,
					request: this.getCommentsTotals({
						referenceId: params.referenceId,
						configTypeId: params.configTypeId,
						externalSiteId: params?.siteId,
					}, external),
				};
			}
			if (!(params.avoid?.views)) {
				requests['views'] = {
					loading: true,
					success: false,
					subscription: null,
					request: this.getViewsTotals({
						referenceId: params.referenceId,
						applicationId: params.applicationId,
						featureName: params.featureName,
					}),
				};
			}
			if (!(params.avoid?.actionplans)) {
				requests['actionplans'] = {
					loading: true,
					success: false,
					subscription: null,
					request: this.getActionPlanTotals({
						registryId: params.referenceId as number,
						applicationId: params.applicationId,
					}),
				};
			}
			const response = {
				reactions: 0,
				comments: 0,
				views: 0,
				actionplans: 0,
			};

			if (Object.keys(requests).length <= 0) {
				subscriber.next(response);
				subscriber.complete();
				return;
			}

			const allFinished = () => Object.keys(requests).every(key => !requests[key].loading);
			for (const key of Object.keys(requests)) {
				requests[key].subscription = requests[key].request.pipe(
					tap((total) => {
						requests[key].success = true;
						response[key] = total;
					}),
					finalize(() => {
						requests[key].loading = false;
						if (!wasCancelled && allFinished()) {
							gotResponse = true;
							subscriber.next(response);
							subscriber.complete();
						}
					})
				).subscribe();
			}

			return () => {
				wasCancelled = true;
        if (!gotResponse) {
					for(const key of Object.keys(requests)) {
						requests[key].subscription?.unsubscribe();
					}
				}
			};
		}).pipe(take(1));
	}

	public getReactionsTotals(params: GetReactionTotalsParams): Observable<number> {
		const id = params.registryUniqueId;
		if (environment.isDEV || environment.isQA) {
			return this._reactionsCounterService.getCountByRegistryId(
				id,
				{
					applicationId: params.applicationId,
					siteId: params.siteId,
				}
			).pipe(
				map((response) => {
					return response.reduce((p, c) => p + c.count, 0);
				}),
			);
		}

		const url = `${this._appConfig.APIs.apiUrlReaction}/${typeof id === 'string' ? 'CountersUid' : 'CountersId'}`;
		let queryParams = {
			applicationId: params.applicationId,
			siteId: params.siteId,
		} as any;
		if (typeof id === 'string') {
			queryParams.registryUniqueUId = id;
		}
		else {
			queryParams.registryUniqueId = id;
		}
		return this._httpClient.get<{ count: number }[]>(`${url}`, {
			params: queryParams,
		}).pipe(
			map((response) => {
				return response.reduce((prev, curr) => {
					return prev + curr.count;
				}, 0)
			}),
		);
	}

	public getCommentsTotals(params: GetCommentsTotalsParams, external: boolean): Observable<number> {
		if (environment.isDEV || environment.isQA) {
			let _params: GetCounterCommentParams = {
				commentConfigTypeId: params.configTypeId,
			};
			if (external) {
				_params.externalSiteId = params.externalSiteId;
			}
			return this._commentCounterService.getCountByRegistryId(params.referenceId, _params);
		}

		const id = params.referenceId;
		const url = `${this._appConfig.APIs.apiUrlComments}/${typeof id === 'string' ? 'CommentByReferenceUId' : 'CommentByReferenceId'}${external ? '/external' : ''}/counter`;
		return this._httpClient.get<number>(`${url}`, {
			params: {
				referenceId: id,
				referenceUid: id,
				commentConfigTypeId: params.configTypeId,
				externalSiteId: params?.externalSiteId,
			},
		});
	}

	public getViewsTotals(params: GetViewsTotalsParams): Observable<number> {
		if (environment.isDEV || environment.isQA) {
			return this._viewCounterService.getCountByRegistryId(
				params.referenceId,
				{
					applicationId: params.applicationId,
					featureName: params.featureName,	
				}
			);
		}

		const url = `${this._appConfig.APIs.apiUrlViews}/View/count`;
		return this._httpClient.get<{ count: number }>(`${url}`, {
			params: {
				applicationId: params.applicationId,
				registryId: params.referenceId,
				featureName: params.featureName,
			},
		}).pipe(
			map((response) => response?.count ?? 0),
		);
	}

	public getActionPlanTotals(params: GetActionPlanTotalsParams): Observable<number> {
		return this._actionPlanCounterService.getCountByRegistryId(
			params.registryId,
			{ applicationId: params.applicationId }
		);
	}
}
