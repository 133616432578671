import { Inject, Injectable } from '@angular/core';
import { ISvcAppSettingsValue, SVC_APP_SETTINGS, SvcAppSettingsValue } from './svc-app-settings.token';
import { SvcDelimiterType } from './enums/svc-delimiter-type.enum';

@Injectable({
	providedIn: 'root'
})
export class SvcAppSettings implements ISvcAppSettingsValue {

	private _settings: SvcAppSettingsValue;

	public get applicationId() { return this._settings.applicationId; }
	public get applicationName() { return this._settings.applicationName; }
	public get module() { return this._settings.module; }
	public get dateFormat() { return this._settings.dateFormat; }
	public get delimiterSymbol() { return this._settings.delimiterSymbol; }
	public get dateFormatAsMask() {
		const separatorSymbol = this.dateFormat.replace(/[aA-zZ]/g, '')[0] ?? '';
		return this.dateFormat?.split('').map((char) => char !== separatorSymbol ? '0' : separatorSymbol).join('');
	}
	public get currencyAcronym() { return this._settings.currencyAcronym; }

	constructor(@Inject(SVC_APP_SETTINGS) settings: SvcAppSettingsValue) {
		this._settings = settings ?? <SvcAppSettingsValue>{};
	}

	public setCurrencyAcronym(symbol: string) {
		this._settings.currencyAcronym = symbol;
	}

	public setDateFormat(format: string) {
		const separatorSymbol = format === 'YYYYMMDD' ? '-' : '/';
		format = format.split('').reduce((prev, curr, index) => {
			curr = /^[DY]$/g.test(curr) ? curr.toLowerCase() : curr;
			const lastChar = prev.replace(/\//g, '').split('').pop();
			return prev + (index > 0 && lastChar !== curr ? separatorSymbol : '') + curr;
		}, '');
		this._settings.dateFormat = format;
	}

	public setDelimiterSymbol(symbol: SvcDelimiterType) {
		switch(symbol) {
			case SvcDelimiterType.DOT: {
				this._settings.delimiterSymbol = '.';
				break;
			}
			default: {
				this._settings.delimiterSymbol = ',';
				break;
			}
		}
	}
}
