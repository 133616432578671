import {Component, Input, OnInit} from '@angular/core';
import { SvcViewPanelExportInterface } from "../../interfaces/svc-view-panel-export.interface";
import { HttpClient} from "@angular/common/http";
import { finalize } from 'rxjs';

@Component({
  selector: 'svc-view-panel-export',
  template: `
  <ng-container>
    <button *ngIf="showExportMenu" mat-button class="text-primary font-bold min-w-8 w-8 h-8 min-h-8" [matMenuTriggerFor]="exportMatMenu" [disabled]="exporting" id="view-panel-export-menu">
      <mat-icon *ngIf="!exporting" class="text-current icon-size-5" svgIcon="heroicons_solid:ellipsis-vertical"></mat-icon>
      <mat-spinner *ngIf="exporting" [diameter]="20"></mat-spinner>
    </button>

    <mat-menu #exportMatMenu>

      <button
        *ngIf="config?.xls && config?.xls.active"
        mat-menu-item
        (click)="exportAsXls()"
        class="text-default"
        id="view-panel-export-xls"
      >
        <mat-icon class="text-current"
          svgIcon="heroicons_solid:document-text"
        ></mat-icon>
        <span>{{ 'Export XLS' | transloco }}</span>
      </button>

      <button
        *ngIf="config?.pdf && config?.pdf.active"
        mat-menu-item
        (click)="exportAsPdf()"
        class="text-default"
        id="view-panel-export-pdf"
      >
        <mat-icon  class="text-current"
          svgIcon="mat_solid:picture_as_pdf"
        ></mat-icon>
        <span> {{ 'Export PDF' | transloco }}</span>
      </button>

      <button
        *ngIf="config?.print"
        mat-menu-item
        class="text-default"
        id="view-panel-export-print"
      >
        <mat-icon class="text-current"
          svgIcon="heroicons_solid:printer"
        ></mat-icon>
        <span>{{ 'Print' | transloco }}</span>
      </button>

    </mat-menu>
  </ng-container>
  `,
  styles: [`
    :host {
      display: inline-block;
    }
  `]
})
export class SvcViewPanelExportComponent implements OnInit {

  @Input() config: SvcViewPanelExportInterface;

  exporting: boolean;
  showExportMenu: boolean;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.showExportMenu = this.config
      && (
        this.config.pdf.active === true
        || this.config.xls.active === true
        || this.config.print
      )
  }

  exportAsXls() {
    if(!this.config.xls.url)  {
      if (this.config.xls.onClick)
        this.config.xls.onClick.emit();
      return;
    }

    this.exporting = true;
    this.http.get(this.config.xls.url,{ observe:'response', responseType:'blob'})
      .pipe(
        finalize(() => this.exporting = false)
      )
      .subscribe( response => {
        this._downloadFile(this.config.xls.fileName, response.body);
      });
  }

  private _downloadFile(fileName: string, file: Blob): void {
    let _elementLink = document.createElement('a');
    _elementLink.download = fileName;
    _elementLink.href = URL.createObjectURL(file);
    _elementLink.click();
  }

  exportAsPdf(){
    if(!this.config.pdf.url)  {
      this.config.pdf.onClick.emit();
      return;
    }

    this.exporting = true;
    this.http.get(this.config.pdf.url,{ observe:'response', responseType:'blob'})
      .pipe(
        finalize(() => this.exporting = false)
      )
      .subscribe(response => {
        this._downloadFile(this.config.pdf.fileName, response.body);
      });
  }
}
