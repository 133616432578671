<div class="flex flex-col sm:flex-row gap-y-2" *screenSizes="let size">
  <div class="flex flex-row grow basis-1/2 space-x-2 items-center" [class.px-1]="size.isXS">
    <svc-search-field inputId="post-filter-text" class="max-w-full" (onChanged)="filterByText($event)"></svc-search-field>
    <svc-button icon-only color="primary" [mode]="hasFilter ? 'flat' : 'stroked'" (click)="toggleFilters()" buttonId="post-filter-button">
      <mat-icon class="icon-size-4" color="primary" svgIcon="heroicons_solid:funnel"></mat-icon>
    </svc-button>
    <svc-button *ngIf="false" icon-only color="primary" [mode]="filterFavorites ? 'flat' : 'stroked'" (click)="toggleFavorites()" buttonId="post-filter-favorites">
      <mat-icon class="icon-size-4" [ngClass]="[filterFavorites ? 'text-on-primary' : 'text-primary' ]" svgIcon="heroicons_solid:star"></mat-icon>
    </svc-button>
  </div>

  <div class="flex flex-row grow">
    <hr class="w-30 my-0 self-center grow ml-1"/>
    <p class="ml-2 self-center whitespace-nowrap text-default font-light">{{ 'Classificar por' | transloco }}:</p>
    <button class="text-default text-base" mat-button [matMenuTriggerFor]="postSorter" id="post-filter-sort">
      <span class="mr-1">{{ sortByOptions[selectedSortByIndex].title | transloco }}</span>
      <mat-icon class="icon-size-3 ml-2" iconPositionEnd svgIcon="heroicons_solid:chevron-down"></mat-icon>
    </button>
  </div>
</div>

<mat-menu class="bg-white" xPosition="before" yPosition="below" #postSorter="matMenu"
>
  <ng-container>
    <button *ngFor="let sort of sortByOptions; let i = index" (click)="selectSortBy(sort)" [id]="'post-filter-sort-'+sort.param" mat-menu-item>
      <mat-icon *ngIf="sort.icon" [svgIcon]="sort.icon"></mat-icon>
      <span>{{ sort.title | transloco }}</span>
    </button>
  </ng-container>
</mat-menu>
