<svc-select class="w-full"
  [(ngModel)]="selectedId"
  [label]="label"
  [placeholder]="placeholder"
  [options]="selectOptions"
  [icon]="icon"
  [readonly]="readonly"
  [truncateTextValue]="truncateTextValue"
  [inputId]="inputId"
  [fallbackTextValue]="fallbackTextValue"
  (ngModelChange)="onSelectedIdChanged()"
></svc-select>

<svc-validation-message *ngIf="!readonly" [control]="control"></svc-validation-message>

<ng-container *ngIf="readonly">
  <div class="flex flex-col flex-auto">
    <div class="flex flex-row items-center input-label-size input-label-weight text-default">
      <mat-icon *ngIf="icon" class="icon-size-4 text-current mr-1" [svgIcon]="icon"></mat-icon>
      {{ label }}
    </div>
    <span class="text-default font-medium text-base" [class.truncate]="truncateTextValue">
      {{ selected ? selected.text : (fallbackTextValue ?? '') }}
    </span>
  </div>
</ng-container>

<div class="mt-2" *ngIf="selected?.id === defaultActions?.Customize">
  <svc-date-picker-range
    [formControl]="controlRangeDates"
    [fallbackTextValue]="fallbackTextValue"
    [readonly]="readonly"
    [showIcon]="showIcon"
    [min]="minDate"
    [max]="maxDate"
    [inputId]="inputId ? (inputId + '-picker') : null"
    [format]="format"
  ></svc-date-picker-range>
</div>
