<div class="board-viewport" [style.padding.px]="bordEdgeSize" [ngClass]="{ 'cursor-grab': mode == 'MOVING' && !isMoving, 'cursor-grabbing': isMoving }">
	<div class="board-viewport-inner"
		[ngClass]="{ 'without-grid': !grid }"
		[style.width]="boardWidth ? boardWidth + 'px' : '100%'"
		[style.height]="boardHeight ? boardHeight + 'px' : '100%'"
		[style.transform]="'scale(' + scale + ')'"
	>
		<ng-content></ng-content>
	</div>
</div>
<div *ngIf="canEdit || canZoom" class="right-bottom-navigation">
	<section *ngIf="canEdit || canZoom" class="flex gap-x-5 rounded-l-3xl bg-white shadow-lg p-2.5 pr-2">
		<ng-container *ngIf="canZoom">
			<button mat-fab color="primary" (click)="zoomIn()" class="w-12 h-12 shadow-none" [disabled]="!canZoomIn" [matTooltip]="'Zoom +' | transloco" matTooltipPosition="above">
				<mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:magnifying-glass-plus"></mat-icon>
			</button>
			<button mat-fab color="primary" (click)="zoomOut()" class="w-12 h-12 shadow-none" [disabled]="!canZoomOut" [matTooltip]="'Zoom -' | transloco" matTooltipPosition="above">
				<mat-icon class="text-current icon-size-6" svgIcon="heroicons_solid:magnifying-glass-minus"></mat-icon>
			</button>
		</ng-container>
		<button *ngIf="canEdit" mat-fab color="primary" (click)="showRightBottomOptions = !showRightBottomOptions" class="w-12 h-12 shadow-none" [matTooltip]="(showRightBottomOptions ? null : 'Opções') | transloco" matTooltipPosition="above">
			<mat-icon class="text-current icon-size-6" [svgIcon]="showRightBottomOptions ? 'mat_solid:close' : 'mat_solid:more_vert'"></mat-icon>
		</button>
	</section>
	<section *ngIf="showRightBottomOptions" class="flex flex-col gap-x-5 w-fit self-end mr-3 rounded-full bg-white shadow-lg p-2.5">
		<button *ngIf="canEdit" mat-fab color="primary" (click)="mode == 'EDITING' ? endEditingMode() : startEditingMode()" class="w-12 h-12 shadow-none" [matTooltip]="(mode == 'EDITING' ? 'Cancelar edição' : 'Editar') | transloco" matTooltipPosition="left">
			<mat-icon class="text-current icon-size-6" [svgIcon]="mode == 'EDITING' ? 'mat_solid:edit_off' : 'mat_solid:edit'"></mat-icon>
		</button>
	</section>
</div>