<div class="flex flex-col flex-auto h-full lg:h-auto lg:overflow-hidden rounded-lg" *ngIf="!allHadErrors">
  <div class="flex flex-col flex-0">
    <mat-expansion-panel
      *ngFor="let category of categories; let i = index"
      class="bg-zinc-200 mat-elevation-z0 rounded-none body-padding-0 overflow-visible mb-1.5 shadow md:mb-0 md:shadow-none"
      [ngClass]="{'border-b border-zinc-300': i < categories.length, 'rounded-tl-lg rounded-tr-lg': i === 0, 'rounded-bl-lg rounded-br-lg': i === (categories.length - 1) }"
      [disabled]="allTasks[category.enum]?.length === 0"
      [expanded]="category.opened && allTasks[category.enum]?.length > 0"
    >
      <mat-expansion-panel-header class="bg-transparent">
        <mat-panel-title class="text-base font-normal items-center">
          {{ category.title }}
          <span class="text-sm bg-zinc-400 text-white rounded-full px-2 ml-3">
            {{ allTasks[category.enum]?.length | formatNumberDefault }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <main-task [tasks]="allTasks[category.enum]"></main-task>

    </mat-expansion-panel>
  </div>
</div>

<div *ngIf="allHadErrors" class="flex items-center justify-center h-full rounded-t-md border shadow bg-white">
  <div class="flex gap-2 justify-center items-center font-normal cursor-pointer" (click)="getTasks()">
    <mat-icon class="icon-size-3.5 text-primary" svgIcon="fontawesome_solid:triangle-exclamation"></mat-icon>
    <span class="text-sm text-primary font-bold underline">{{ 'Atualize a página' | transloco }}</span>
  </div>
</div>
