import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvcBookmarkButtonComponent } from './svc-bookmark-button.component';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SvcBookmarkButtonComponent
  ],
  imports: [
    LibSharedCommonModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    TranslocoModule,
  ],
  exports: [
    SvcBookmarkButtonComponent
  ],
})
export class SvcBookmarkButtonModule { }
