import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { GetCounterViewResponse, GetCounterViewParams } from './views-counter-classes';
import { AbstractCounterBusStop } from '../abstract-counter-bus-stop';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvcViewCounterService extends AbstractCounterBusStop<GetCounterViewParams, GetCounterViewResponse> {

  constructor(
    protected appConfig: AppEnvironmentConfig,
  ) {
    super({
      apiURL: appConfig.APIs.apiUrlViews,
      registryIdFilterPropName: 'registryId',
      idSingleParamName: 'registryId',
      idBatchParamName: 'registryIds',
      idSingleRequestPath: '/View/count',
      idBatchRequestPath: '/View/count/RegistryIds',
    });
  }

  public getCountByRegistryId(registryId: number | string, params: GetCounterViewParams): Observable<number> {
    return this.getCountByIdOrUid(registryId, params).pipe(
      map((result) => result.length > 0 ? result[0].count : 0),
    );
  }
}

