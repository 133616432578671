<div *ngIf="parentTree?.showIndicator ?? true" class="entry-indicator">
  <mat-icon class="text-current icon-size-6" [svgIcon]="'heroicons_solid:' + (isVertical ? 'chevron-down' : 'chevron-right')"></mat-icon>
</div>
<section>
  <div *ngIf="parentTree?.templateRef == null" class="entry" [ngClass]="{ 'cursor-pointer': input.children?.length }" (click)="toggleVisibility()">
    {{ input.name }}
  
    <div *ngIf="!showBranches && input.children?.length" class="entry-branches-count">
      {{ input.children?.length > 9 ? '+9' : (input.children?.length ?? 0) }}
    </div>
  </div>
  <ng-container
    *ngIf="parentTree?.templateRef != null"
    [ngTemplateOutlet]="parentTree.templateRef"
    [ngTemplateOutletContext]="{ $implicit: entry, showBranches: showBranches }"
  ></ng-container>
</section>
<svc-tree-branch
  *ngIf="input.children?.length"
  [input]="input"
  [orientation]="orientation"
  [ngClass]="{ 'hidden': !showBranches }"
></svc-tree-branch>