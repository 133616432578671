<svc-dialog-config fullscreen="XS" (onDialogClose)="closeDialog()">

    <div svc-dialog-title>
        {{ 'Gestão de notificações' | transloco }}
    </div>

    <div svc-dialog-content>

      <div class="flex min-h-[200px] justify-center items-center" *ngIf="loading">
        <mat-progress-spinner
          [color]="'primary'"
          [diameter]="28"
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </div>

      <div class="flex-col divide-y p-1" *ngIf="!loading">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let app of applicationRules; first as isFirst" [expanded]="isFirst">
            <mat-expansion-panel-header *ngIf="app.rules?.length">
              <mat-panel-title><b>{{ app.name | transloco }}</b></mat-panel-title>
            </mat-expansion-panel-header>

            <table mat-table [dataSource]="app.rules" class="mat-elevation-z8" *ngIf="app?.rules?.length">

              <ng-container matColumnDef="ruleName">
                <th mat-header-cell *matHeaderCellDef> Regras </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.ruleName | transloco }}
                </td>
              </ng-container>

              <ng-container *ngFor="let column of displayedConfigColumns; let i = index" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef> {{ column | titlecase }} </th>
                <td mat-cell *matCellDef="let element">
                  <svc-slide-toggle
                    *ngIf="element?.types[i]"
                    [matTooltip]="(element.ruleDescription ?? element.ruleName)"
                    [checked]="element.types[i].active"
                    (change)="toggleRule($event, element.types[i])"
                  ></svc-slide-toggle>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </div>

    <div class="space-x-2 justify-center" svc-dialog-actions>
        <svc-button-neutral
          [label]="'Cancelar' | transloco"
          type="button"
          [processing]="loading"
          (click)="closeDialog()"
        ></svc-button-neutral>

        <svc-button-confirm
          [label]="'Salvar' | transloco"
          type="button"
          [disabled]="!ruleTypesToChange?.length || loading"
          cdkFocusInitial
          (click)="submit()"
        ></svc-button-confirm>
    </div>

</svc-dialog-config>

