<mat-icon
  class="duration-700"
  [class]="iconSizeClass || 'icon-size-6'"
  [class.text-yellow-500]="value && !(isLoading$ | async)"
  [class.text-gray-300]="!dark ? (!value  || (isLoading$ | async)) : null"
  [class.text-white]="dark ? (!value  || (isLoading$ | async)) : null"
  [class.opacity-70]="isLoading$ | async"
  [svgIcon]="(value || iconAlwaysFilled) ? 'heroicons_solid:star' : 'heroicons_outline:star-outline'"
  [matTooltip]="(readonly || (isLoading$ | async)) ? null : ((value ? 'Desfavoritar' : 'Favoritar') | transloco)"
></mat-icon>
