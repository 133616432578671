<section class="flex justify-start mx-16 mt-16">
  <div class="flex flex-col w-full h-full">
    <img class="image-info" src="assets/img/info-icon.svg" />

    <h3 class="text-[32px] font-bold text-primary">
      {{ '404 Página não encontrada' | transloco }}
    </h3>
    <p class="text-lg">
      {{ 'Por favor, confira a URL digitada e tente novamente.' | transloco }}
    </p>

    <img class="image-banner" src="assets/img/solvace-page-not-found.svg" />

    <svc-button type="button" mode="flat" color="primary" size="lg" [processing]="isBacking" (click)="back()">
      <span class="text-lg font-medium">{{ 'Voltar para home' | transloco }}</span>
    </svc-button>
  </div>
</section>

<div *ngIf="isBacking || !termsLoaded" class="overlay-loading text-primary">
  <mat-spinner class="text-current" diameter="50"></mat-spinner>
</div>
