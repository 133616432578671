<div class="flex flex-col justify-between shadow-md rounded-t-lg rounded-b-lg h-full">
  <div
    class="flex justify-center rounded-t-lg w-full text-on-primary p-2 relative"
    [ngClass]="{'bg-primary': config?.user?.active, 'bg-gray-400': !config?.user?.active}"
  >
    <img class="h-[30px] w-auto" [src]="config?.logoImageNegative" />
    <button *ngIf="config?.showClose" mat-icon-button mat-dialog-close class="absolute right-1 top-0">
      <mat-icon class="text-current icon-size-6" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
  </div>

  <div class="flex flex-col p-4 grow">

    <div class="flex flex-col w-full relative">

      <div class="flex items-center justify-center" [style.height.px]="153">
        <ng-container *ngIf="!config?.user?.pictureFile">
          <div
          class="flex flex-auto justify-center rounded-t-lg h-full w-full"
          [style.backgroundColor]="config?.user?.initialColor ?? '#d0d0d0'"
        >
          <div
            class="flex flex-column rounded-full justify-center pt-5 text-white"
            [ngClass]="'border-'"
            [style.fontSize.px]="16"
            [style.height.px]="140"
            [style.width.px]="140"
            [style.backgroundColor]="config?.user?.initialColor ?? '#d0d0d0'"
            [title]="config?.user?.firstLastName"
          >
            <div *ngIf="config?.user?.firstLastName" class="text-white" [style.lineHeight.px]="90" [style.fontSize.px]="90">
              {{config.user.firstLastName | userInitials}}
            </div>
          </div>
        </div>
        </ng-container>

        <ng-container *ngIf="config?.user?.pictureFile">
          <img
            class="rounded-t-lg h-full w-full object-cover"
            [class.grayscale]="!config.user.active"
            [src]="config.user.pictureFile"
            [title]="config.user?.firstLastName"
            (error)="config.user.pictureFile = undefined"
          />
        </ng-container>

      </div>

      <div #firstLastNameSpan *ngIf="config?.user?.firstLastName" class="text-white uppercase font-bold text-center text-base w-full absolute bottom-0 py-3 bg-gradient-to-t from-gray-900 truncate" [matTooltip]="config.user.firstLastName" [matTooltipDisabled]="firstLastNameSpan.scrollWidth === firstLastNameSpan.clientWidth">
        {{ config.user.firstLastName }}
      </div>

    </div>

    <div class="flex flex-col p-1 text-base text-default text-center">
      <div #employeePositionNameSpan class="truncate" [matTooltip]="config?.user?.employeePositionName" [matTooltipDisabled]="employeePositionNameSpan.scrollWidth === employeePositionNameSpan.clientWidth">{{ config?.user?.employeePositionName }}</div>
      <div class="flex flex-row justify-center gap-1">
        <div #siteNameSpan *ngIf="config?.user?.siteName" class="truncate" [matTooltip]="config?.user?.siteName" [matTooltipDisabled]="siteNameSpan.scrollWidth === siteNameSpan.clientWidth">{{ config?.user?.siteName }}</div>
        <div *ngIf="(config?.user?.siteName && config?.user?.areaName) || (!config?.user?.siteName && !config?.user?.areaName)"> - </div>
        <div #areaNameSpan class="truncate" [matTooltip]="config?.user?.areaName" [matTooltipDisabled]="areaNameSpan.scrollWidth === areaNameSpan.clientWidth">{{ config.user?.areaName }}</div>
      </div>
      <div #departmentNameSpan *ngIf="config?.user?.departmentName" class="truncate" [matTooltip]="config?.user?.departmentName" [matTooltipDisabled]="departmentNameSpan.scrollWidth === departmentNameSpan.clientWidth">{{ config?.user?.departmentName }}</div>
    </div>

  </div>

  <div
    class="flex flex-col w-full rounded-b-lg text-sm text-on-primary text-center p-2"
    [ngClass]="{'bg-primary': config?.user?.active, 'bg-gray-400': !config?.user?.active}"
  >
    <div #emailSpan class="truncate" [matTooltip]="config?.user?.email" [matTooltipDisabled]="emailSpan.scrollWidth === emailSpan.clientWidth">{{config?.user?.email || '-'}}</div>
    <div> {{ config?.user?.registryNumber?.length ? config.user.registryNumber : '-'}}</div>
  </div>

</div>
