import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcButtonsModule } from "../svc-buttons/svc-buttons.module";
import { SvcLoadersModule } from "../svc-loaders/svc-loaders.module";
import { SvcControlsModule } from '../svc-controls/svc-controls.module';
import { SvcViewPanelContentDirective } from './svc-view-panel-content.directive';
import { SvcViewPanelComponent } from './svc-view-panel.component';
import { SvcViewPanelExportComponent } from './components/export/svc-view-panel-export.component';
import { SvcViewToggleComponent } from './components/view-toggle/svc-view-toggle.component';
import { SvcSearchFieldModule } from '../svc-search-field/svc-search-field.module';

@NgModule({
  declarations: [
    SvcViewPanelContentDirective,
    SvcViewPanelComponent,
    SvcViewPanelExportComponent,
    SvcViewToggleComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    SvcControlsModule,
    TranslocoModule,
    SvcLoadersModule,
    SvcButtonsModule,
    SvcSearchFieldModule,
  ],
  exports: [
    SvcViewPanelComponent,
    SvcViewPanelExportComponent,
    SvcViewToggleComponent,
    SvcViewPanelContentDirective,
  ]
})
export class SvcViewPanelModule { }
