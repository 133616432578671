<div class="flex flex-wrap w-full">
	<mat-form-field #formField *ngIf="!readonly" class="w-full flex-auto svc-mat-no-subscript svc-mat-dense" [id]="inputId ?? null" [class.mat-form-field-disable]="control?.disabled ?? false" [class.cursor-default]="control?.disabled || loading" [class.collapsed]="collapsed" role="button" tabindex="0" (focus)="openOptions()" (keydown.tab)="closeOptions()" (keydown)="(($event.which || $event.keyCode) === 9 && $event.shiftKey) ? closeOptions() : null">
		<mat-icon *ngIf="icon" matPrefix [svgIcon]="icon"></mat-icon>
		<mat-label class="inline-flex input-label-size input-label-weight text-default">
			<div class="flex items-center">
				<mat-icon *ngIf="label?.icon" class="icon-size-3 text-current mr-1" [svgIcon]="label.icon"></mat-icon>
				{{ label?.text ?? label }}
			</div>
		</mat-label>

		<div class="flex h-full gap-2 w-full items-center">
			<mat-chip-grid #chipGrid class="flex-auto h-full" [class.py-1]="!collapsed" [style.width]="(collapsed && (selectedOptions ?? []).length > 1) ? 'calc(100% - 32px)' : '100%'" (click)="openOptions()">
				<div class="flex justify-center truncate" *ngFor="let option of selectedOptions" [ngClass]="collapsed ? 'h-10 py-1.5' : 'h-8 py-0.5'" [class.invisible]="loading">
					<mat-chip-row class="my-0" (removed)="selected(option)" [disabled]="control?.disabled">
						<ng-container *ngIf="!templateRef">{{ option.label }}</ng-container>
						<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option }"></ng-container>
						<button matChipRemove [attr.aria-label]="'remove option ' + option.label" [disabled]="control?.disabled">
							<mat-icon class="icon-size-3 bg-text-default text-white rounded-full" svgIcon="heroicons_solid:x-mark"></mat-icon>
						</button>
					</mat-chip-row>
				</div>
				<input
					class="ml-0 cursor-default self-center h-7 flex-0 max-w-50"
					[class.w-0]="loading || !placeholder || (selectedOptions ?? []).length > 0"
					[matChipInputFor]="chipGrid"
					[placeholder]="placeholder"
					[disabled]="control?.disabled ?? false"
					tabindex="-1"
					(focus)="openOptions()"
					(input)="$event.target.value = ''"
				>
			</mat-chip-grid>
			<div [class.invisible]="!undisplayedSelectedOptions" [class.hidden]="!collapsed || (selectedOptions ?? []).length <= 1">
				<button class="mat-mdc-chip cursor-pointer text-sm rounded-full h-6 w-6" (click)="collapsed = false">
					+{{ undisplayedSelectedOptions }}
				</button>
			</div>
		</div>
		<div matSuffix class="text-default -mr-1.5">
			<mat-icon class="dropdown-icon text-current" [svgIcon]="'fontawesome_solid:' + (optionsListIsOpened? 'chevron-up' : 'chevron-down')" (click)="!control?.disabled && openOptions()"></mat-icon>
		</div>
    <ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
	</mat-form-field>
	<ng-template #optionsListTemplate>
		<div class="mdc-menu-surface opacity-100 relative w-full flex flex-col bg-white rounded-md overflow-x-hidden">
			<div *ngIf="!selectedOptions?.length || type === 'multiple'" class="flex items-center w-full gap-2 py-1 px-2 border-b border-gray-200">
				<div class="flex-auto">
					<input 
						#searchInput
						type="text" 
						class="w-full text-sm" 
						[(ngModel)]="searchText"
						(ngModelChange)="filterOptions(searchText)"
						[placeholder]="'Pesquisar' | transloco"
						(keydown)="(($event.which || $event.keyCode) === 9 && $event.shiftKey) ? $event.preventDefault() : null"
						(keydown.arrowdown)="forceFocusItemWhenDownPressed($event)"
					/>
				</div>
				<div class="flex items-center justify-center text-gray-200 w-8 h-8 cursor-pointer" (click)="searchInput.focus()">
					<mat-icon class="text-current icon-size-5" svgIcon="mat_solid:search"></mat-icon>
				</div>
			</div>
			<div class="flex-auto overflow-y-auto">
				<ng-container *ngIf="type === 'single' && (selectedOptions?.length ?? 0) > 0">
					<div class="py-2 px-3 text-sm font-normal">
						{{ 'Você pode selecionar apenas 1 item' | transloco }}
					</div>
				</ng-container>
				<ng-container *ngIf="(type === 'single' && (selectedOptions?.length ?? 0) == 0) || type != 'single'">
					<div *ngFor="let groupedOption of filteredGroupedOptions">
						<div class="px-3 pt-1" class="font-semibold px-4 py-2 border-b border-gray-300">
							{{ groupedOption.group }}
						</div>
						<ng-container *ngFor="let option of groupedOption.options">
							<ng-container *ngIf="option['options']" >
								<div class="pl-4 pt-2 pb-1">
									{{ option.group }}
								</div>
								<div *ngFor="let childOption of option['options']" class="chip-grouped-option flex pl-8 pr-2 cursor-pointer py-2 focus:bg-gray-100 hover:bg-gray-100" matRipple (click)="selected(childOption, $event)" role="button" tabindex="0" (keydown.space)="$event.preventDefault(); selected(childOption)" (keydown.enter)="$event.preventDefault(); selected(childOption)" (keydown.arrowdown)="$event.preventDefault(); $event.target.nextElementSibling?.focus()" (keydown.arrowup)="$event.preventDefault(); $event.target.previousElementSibling?.focus()">
									<div *ngIf="type === 'multiple'" class="flex flex-col justify-center">
										<mat-checkbox [checked]="childOption.selected" (click)="selected(childOption, $event)" class="-m-1 -ml-2 -mr-3.5" tabindex="-1"></mat-checkbox>
									</div>
									<div class="flex-auto flex flex-col justify-center break-all">
										<ng-container *ngIf="!templateRef">{{ childOption.label }}</ng-container>
										<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: childOption }"></ng-container>
									</div>
									<div *ngIf="type !== 'multiple' && childOption.selected" class="text-default flex flex-col justify-center">
										<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:done"></mat-icon>
									</div>
								</div>
							</ng-container>
							<ng-container *ngIf="!option['options']">
								<div class="chip-grouped-option flex pl-4 pr-2 py-2 cursor-pointer focus:bg-gray-100 hover:bg-gray-100" matRipple (click)="selected(option, $event)" role="button" tabindex="0" (keydown.space)="$event.preventDefault(); selected(option)" (keydown.enter)="$event.preventDefault(); selected(option)" (keydown.arrowdown)="$event.preventDefault(); $event.target.nextElementSibling?.focus()" (keydown.arrowup)="$event.preventDefault(); $event.target.previousElementSibling?.focus()">
									<div *ngIf="type === 'multiple'" class="flex flex-col justify-center">
										<mat-checkbox [checked]="option.selected" (click)="selected(option, $event)" class="-m-1 -ml-2 -mr-3.5" tabindex="-1"></mat-checkbox>
									</div>
									<div class="flex-auto flex flex-col justify-center break-all">
										<ng-container *ngIf="!templateRef">{{ option.label }}</ng-container>
										<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option }"></ng-container>
									</div>
									<div *ngIf="type !== 'multiple' && option.selected" class="text-default flex flex-col justify-center">
										<mat-icon class="icon-size-5 text-current" svgIcon="mat_solid:done"></mat-icon>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-template>
	<svc-validation-message *ngIf="!readonly" [control]="control"></svc-validation-message>

	<ng-container *ngIf="readonly">
		<div class="relative flex flex-col flex-auto">
			<div *ngIf="label" class="flex flex-row items-center input-label-size input-label-weight text-default">
				<mat-icon *ngIf="icon" class="icon-size-4 text-current mr-1" [svgIcon]="icon"></mat-icon>
				{{ label }}
			</div>
			<span *ngIf="readonlyStyle === 'default' || !(selectedOptions?.length)" class="text-default font-medium text-base" [class.truncate]="truncateTextValue" [class.invisible]="loading">
				{{ (valueText ?? '') !== '' ? valueText : (fallbackTextValue ?? '') }}
			</span>
			<div *ngIf="(readonlyStyle === 'chip' || readonlyStyle === 'transparent-chip') && selectedOptions?.length" class="w-full overflow-y-hidden" [class.h-10]="collapsed" [class.invisible]="loading">
				<div class="flex gap-2 items-center w-full height-inherit relative">
					<div #readonlyOptionsContainer class="flex max-w-full gap-x-2 self-start" [style.flex-flow]="'wrap'" [style.width]="(collapsed && (selectedOptions ?? []).length > 1) ? 'calc(100% - 32px)' : '100%'">
						<div *ngFor="let option of selectedOptions" class="flex justify-center truncate" [ngClass]="collapsed ? 'h-10 py-1.5' : 'h-8 py-0.5'">
							<div class="mat-mdc-chip cursor-default py-0.5 text-base truncate" [class.px-1.5]="readonlyStyle === 'chip'" [class.bg-transparent]="readonlyStyle === 'transparent-chip'">
								<ng-container *ngIf="!templateRef">{{ option.label }}</ng-container>
								<ng-container *ngIf="templateRef" [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ option: option }"></ng-container>
							</div>
						</div>
					</div>
					<div [class.invisible]="!undisplayedSelectedOptions" [class.hidden]="!collapsed || (selectedOptions ?? []).length <= 1">
						<button class="mat-mdc-chip cursor-pointer text-sm rounded-full h-6 w-6" (click)="collapsed = false">
							+{{ undisplayedSelectedOptions }}
						</button>
					</div>
				</div>
			</div>
			<ng-container [ngTemplateOutlet]="loadingTemplate"></ng-container>
		</div>
	</ng-container>
</div>

<ng-template #loadingTemplate>
  <div *ngIf="loading" class="flex items-center absolute left-0 right-0 bottom-0 rounded-md text-default" [ngClass]="[(readonly ? 'top-0' : 'h-10 pl-2'), ((label && readonly) ? 'pt-4' : 'pt-0')]">
    <div class="absolute inset-0 bg-hite opacity-40"></div>
    <div class="droplist-shimmer"></div>
  </div>
</ng-template>
