import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { map, Observable, of, Subject } from 'rxjs';

import { SvcCustomField, SvcCustomFieldTypeResponse } from './svc-custom-field.interface';
import { SvcCustomFieldHttpClient } from './svc-custom-field-http-client';
import { ISvcSelectOption } from 'projects/lib-shared-component/src/lib/svc-controls/svc-select/svc-select.component';

@Injectable({
  providedIn: 'root'
})
export class SvcCustomFieldService {

  public form: FormGroup;

  public clearForm$ = new Subject<void>();
  public getFormByReferenceId$ = new Subject<number>();
  public createCustomField$ = new Subject<number>();
  public updateCustomField$ = new Subject<number>();
  public deleteCustomField$ = new Subject<number>();
  public completeCreateCustomField$ = new Subject<void>();
  public completeUpdateCustomField$ = new Subject<void>();
  public completeDeleteCustomField$ = new Subject<void>();

  constructor (
    private _http: SvcCustomFieldHttpClient,
  ) { }

  public getCustomFieldTypes(): Observable<ISvcSelectOption[]> {
    return this._http.get<SvcCustomFieldTypeResponse[]>('/CustomFieldType').pipe(
      map((types: SvcCustomFieldTypeResponse[]) =>
        types?.map(type => (
          {
            text: type.customFieldTypeName,
            value: type.customFieldTypeId
          }
        ))
      )
    );
  }

  public createCustomFields(customFieldRequest: SvcCustomField): Observable<boolean> {
    return this._http.post('/CustomField', {
      ...customFieldRequest,
      applicationId: this._http.applicationId,
      siteId: this._http.siteId
    });
  }

  public updateCustomFields(customFieldRequest: SvcCustomField): Observable<boolean> {
    // TODO: add endpoint when was available
    return of(true);
  }

  public getCustomField(id: number): Observable<SvcCustomField> {
    return this._http.get('/CustomField', {
      params: {
        applicationId: this._http.applicationId,
        siteId: this._http.siteId,
        feature: id,
      }
    });
  }
}
