import { NgModule } from '@angular/core';
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import { LibSharedComponentModule } from '../lib-shared-component.module';
import { LibSharedIconsModule } from 'projects/lib-shared-icons/src/public-api';
import { TranslocoModule } from '@ngneat/transloco';
import { SvcFishboneComponent } from './svc-fishbone.component';
import { SvcFishbonePartComponent } from './svc-fishbone-part/svc-fishbone-part.component';
import { SvcFishbonePartItemComponent } from './svc-fishbone-part-item/svc-fishbone-part-item.component';

@NgModule({
  declarations: [
    SvcFishboneComponent,
    SvcFishbonePartComponent,
    SvcFishbonePartItemComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    LibSharedIconsModule,
    TranslocoModule,
  ],
  exports: [
    SvcFishboneComponent,
  ]
})
export class SvcFishboneModule { }
