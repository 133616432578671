import { Injectable } from '@angular/core';
import { AppEnvironmentConfig } from 'projects/config/model/environment.config.model';
import { GetCounterCommentResponse, GetCounterCommentParams } from './comment-counter-classes';
import { AbstractCounterBusStop } from '../abstract-counter-bus-stop';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvcCommentCounterService extends AbstractCounterBusStop<GetCounterCommentParams, GetCounterCommentResponse> {

  constructor(
    protected appConfig: AppEnvironmentConfig,
  ) {
    const idPropName = 'referenceId';
    const uidPropName = 'referenceUId';
    super({
      apiURL: appConfig.APIs.apiUrlComments,
      registryIdFilterPropName: idPropName,
      registryUidFilterPropName: uidPropName,
      idSingleParamName: idPropName,
      uidSingleParamName: uidPropName,
      idBatchParamName: idPropName,
      uidBatchParamName: uidPropName,
      batchRequestParamsType: 'body',
      batchRequestMethod: 'POST',
      idSingleRequestPath: (params) => `/CommentByReferenceId/${params.externalSiteId ? 'external/': ''}counter`,
      uidSingleRequestPath: (params) => `/CommentByReferenceUId/${params.externalSiteId ? 'external/': ''}counter`,
      idBatchRequestPath: (params) => `/CommentByReferenceIds/${params.externalSiteId ? 'external/': ''}counter`,
      uidBatchRequestPath: (params) => `/CommentByReferenceUIds/${params.externalSiteId ? 'external/': ''}counter`,
    });
  }

  public getCountByRegistryId(registryId: number | string, params: GetCounterCommentParams): Observable<number> {
    return this.getCountByIdOrUid(registryId, params).pipe(
      map((result) => {
        if (result.length > 0) {
          const value = result[0];
          return ((typeof value) === 'object' ? value.count : value) as number;
        }
        return 0;
      }),
    );
  }
}

