import { AfterViewInit, Component, EventEmitter, forwardRef, HostBinding, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subject, takeUntil } from 'rxjs';
import { SvcFishboneComponent, SvcFishboneType } from '../svc-fishbone.component';
import { SvcFishbonePartItemComponent } from '../svc-fishbone-part-item/svc-fishbone-part-item.component';
import { fishbonePartDelayStep, getAnimationDelayByType, getAnimationDiffDelayByType, getNameByType, getPropNameByType } from '../svc-fishbone-functions';
import { SvcFishboneItem } from '../interfaces/svc-fishbone-item.interface';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';

@Component({
  selector: 'svc-fishbone-part',
  templateUrl: './svc-fishbone-part.component.html',
  styleUrls: ['./svc-fishbone-part.component.scss'],
  animations: [
    trigger('headerFadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-20px)' }),
        animate('{{time}}ms {{delay}}ms', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('arrowFadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translate(-30px, -30px)' }),
        animate('{{time}}ms {{delay}}ms', style({ opacity: 1, transform: 'translate(0, 0)' })),
      ]),
    ]),
  ]
})
export class SvcFishbonePartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @HostBinding('class.svc-fishbone-part') private internalClass: boolean = true;
  @HostBinding('class.fbp-pointing-up') public isUp: boolean = false;
  @HostBinding('class.fbp-pointing-down') public isDown: boolean = true;

  @Input() type!: SvcFishboneType;
  @Input() pointing!: 'up' | 'down';
  @Output() onItemAddRequested = new EventEmitter<void>();
  @Output() onItemEditRequested = new EventEmitter<SvcFishboneItem>();
  @Output() onItemDeleteRequested = new EventEmitter<SvcFishboneItem>();
  
  @ViewChildren(SvcFishbonePartItemComponent) private queryPartItems: QueryList<SvcFishbonePartItemComponent>;

  @AutoDestroy public destroy$ = new Subject<void>();
  
  protected name: string;
  protected parentItemsPropName: string;
  protected time = fishbonePartDelayStep;
  public delay = 0;
  public diffDelay = 0;
  public animationsWillFinish = false;
  public animationsDidFinish = false;

  constructor(
    @Inject(forwardRef(() => SvcFishboneComponent)) public parent: SvcFishboneComponent,
  ){}

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.queryPartItems.changes.pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.queryPartItems.toArray().forEach((item) => {
        item.updateHostWidth();
      });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('pointing' in changes) {
      this.isUp = this.pointing === 'up';
      this.isDown = !this.isUp;
    }
    if ('type' in changes) {
      this.name = getNameByType(this.type);
      this.parentItemsPropName = getPropNameByType(this.type);
      this.delay = getAnimationDelayByType(this.type);
      this.diffDelay = getAnimationDiffDelayByType(this.type);
    }
  }

  protected onAnimationsFinished() {
    this.animationsWillFinish = true;
    setTimeout(() => {
      this.animationsDidFinish = true;
    });
  }

  public getItems() {
    return this.parent[this.parentItemsPropName] ?? [];
  }

  public ngOnDestroy(): void {
  }
}
