<div class="flex flex-col flex-auto space-y-1 p-2" *screenSizes="let size">
  <div *ngFor="let feed of feeds; let i = index; let first = first; let last = last" [ngClass]="{ 'feed-bottom-border': feed !== feeds[feeds.length - 1] }">
    <div class="flex flex-col px-2 py-2 rounded-md cursor-pointer" [ngClass]="{ 'pt-3': feed === first, 'pb-3': feed === last, 'bg-zinc-100 hover:bg-zinc-100': feed === parent.selectedFeed, 'bg-white hover:bg-zinc-50': feed !== parent.selectedFeed }" (click)="loadFeed(feed)">
      <div class="flex">
        <div class="flex-auto">
          <p class="text-sm text-primary font-bold line-clamp-2" [template-tooltip]="size.isAboveSM ? tooltipTemplate : null" [tooltipPosition]="parent.expanded ? 'right' : 'left'" (click)="openFeedModule(feed, $event)">
            {{ feed.title | removeHtml }}
          </p>
          <p *ngIf="feed.subtitle" class="text-primary-300 text-xs font-bold line-clamp-1">
            {{ feed.subtitle | removeHtml }}
          </p>
        </div>
        <div *ngIf="feed.totalNoRead > 0" class="pl-2">
          <div class="flex items-center justify-center bg-primary-300 text-on-primary text-xs text-white rounded-full" [style.width.px]="30" [style.height.px]="30">
            {{ feed.totalNoRead > 99 ? '99+' : feed.totalNoRead }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="feed.commentContent">
        <div class="flex items-center gap-x-2">
          <svc-user-avatar [info]="{ name: feed.userName ?? '', picture: feed.userPictureFile ?? '', initialsColor: feed.initialColor ?? '#CCC', size: UserAvatarSize.Feed }"></svc-user-avatar>
          <div class="text-sm line-clamp-2" [innerHTML]="feed.commentContent | htmlSanitizer"></div>
        </div>
        <div *ngIf="feed.hasAttachment" class="flex text-primary gap-x-2 mt-2">
          <mat-icon class="icon-size-6 text-current" svgIcon="heroicons_solid:paper-clip"></mat-icon>
          <div class="flex-auto text-sm">{{ 'Contém anexos' | transloco }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!feed.commentContent && feed.hasAttachment">
        <div class="flex items-center gap-x-2">
          <svc-user-avatar [info]="{ name: feed.userName ?? '', picture: feed.userPictureFile ?? '', initialsColor: feed.initialColor ?? '#CCC', size: UserAvatarSize.Feed }"></svc-user-avatar>
          <div class="flex text-primary gap-x-2">
            <mat-icon class="icon-size-6 text-current" svgIcon="heroicons_solid:paper-clip"></mat-icon>
            <div class="flex-auto text-sm">{{ 'Contém anexos' | transloco }}</div>
          </div>
        </div>
      </ng-container>
      <ng-template #tooltipTemplate>
        <p [class.pb-2]="feed.subtitle">
          {{ feed.title | removeHtml }}
        </p>
        <p *ngIf="feed.subtitle" class="border-t border-white pt-2">
          {{ feed.subtitle | removeHtml }}
        </p>
      </ng-template>
    </div>
  </div>
</div>
