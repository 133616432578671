import { Component, HostBinding, Input, ElementRef, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { SvcDataRowComponent } from '../svc-data-row/svc-data-row.component';
import { SvcFunctionsHelper, isNullOrUndefined } from 'projects/lib-shared-common/src/public-api';
import { SvcMediaQuery } from 'projects/lib-shared-common/src/lib/services/svc-media-query.service';

@Component({
	selector: 'svc-data-cell',
	templateUrl: './svc-data-cell.component.html',
	styleUrls: ['./svc-data-cell.component.scss'],
})
export class SvcDataCellComponent implements OnChanges {
	@HostBinding('class.svc-data-cell') public class: boolean = true;
	@HostBinding('class.needs-to-freeze') public isFreezing: boolean = false;
	@HostBinding('class.is-last-freezed') public isLastFreezed = false;

	public get hasReorderClass(): boolean {
		return this.el.classList.contains('order-0');
	}
	public cellIndex: number = null;
	public parent: SvcDataRowComponent;


	public get col() {
		if (!isNullOrUndefined(this.cellIndex) && this.parent?.parent?.parent?.header?.cols) {
			if (this.cellIndex < this.parent.parent.parent.header.cols.length)
				return this.parent.parent.parent.header.cols[this.cellIndex];
			else {
				console.error('There are more cells than columns. Please fix this.');
				return null;
			}
		}
		return null;
	}

	private get el() {
		return this.elRef && this.elRef.nativeElement ? this.elRef.nativeElement : null;
	}
	public get height() {
		return this.el.offsetHeight;
	}

	constructor(
		private elRef: ElementRef<HTMLElement>,
		private helper: SvcFunctionsHelper,
		private cdRef: ChangeDetectorRef,
		private mediaQuery: SvcMediaQuery,
	) {
	}

	ngOnChanges(changes: SimpleChanges) {
	}

	public onRender(parent: SvcDataRowComponent, index) {
		this.cellIndex = index;
		this.parent = parent;
		this.col?.parent?.mobileMaxHeaderIndex.forEach((elem, headerIndex) => {
			if (this.cellIndex === headerIndex) {
				this.el.classList.add('header-cell', 'order-' + elem);
			}
		});
		this.cdRef.detectChanges();

		this.setWidth();
		this.checkFreezing();
	}

	public setWidth() {
		const col = this.col;
		if (this.el && col?.width) {
			const newWidth = col ? col._width : 'auto';

			this.el.style.width = newWidth;
			this.el.style.maxWidth = newWidth == 'auto' ? null : newWidth;
			this.el.style.minWidth = newWidth == 'auto' ? null : newWidth;
		}
	}

	public checkFreezing() {
		if (this.el) {
			const col = this.col;
			this.isFreezing = col?.isFreezing ?? false;
			this.isLastFreezed = col?.isLastFreezed ?? false;
			this.el.style.left = this.isFreezing ? this.parent.cells.slice(0, this.cellIndex).reduce((prev, curr) => prev + (curr.el?.clientWidth ?? 0), 0) + 'px' : null;
		}
	}
}
