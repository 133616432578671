import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AutoDestroy } from 'projects/lib-shared-common/src/lib/decorators/auto-destroy';
import { HttpErrorService } from 'projects/lib-shared-common/src/public-api';
import { ISvcChipGroupedOption, ISvcChipOption, SvcDialogService, SvcFilterDialogService, SvcFilterField, SvcFiltersComponent, SvcToastService } from 'projects/lib-shared-component/src/public-api';
import { UserHashtagsService } from 'projects/lib-shared-core/src/lib/features/user/user-hashtags/services/user-hastags.service';
import { SitesService, StaticApplicationId, SvcUserPreferenceFeatureCustomPorpertyKey, SvcUserPreferencesEvents, SvcUserPreferencesFeatures, UserPreferencesService, UserService } from 'projects/lib-shared-core/src/public-api';
import { MyworkspaceUserPreferencesFeaturesNames } from 'projects/myworkspace/src/app/board/main/models/myworkspace-preferences';
import { Subject, Subscription, catchError, debounceTime, distinctUntilChanged, finalize, forkJoin, map, skip, switchMap, takeUntil, tap } from "rxjs";
import { PostErrorMsg } from '../../models/post-error-msg';
import { PostFilters } from "../../models/post-filter";
import { PostOrdenation } from "../../models/post-ordenation";
import { PostPagination } from "../../models/post-pagination";
import { PostSort } from "../../models/post-sort";
import { PostService } from "../../services/post.service";
import { el } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'svc-post-filter',
  templateUrl: './svc-post-filter.component.html',
  styleUrls: ['./svc-post-filter.component.scss']
})
export class SvcPostFilterComponent extends SvcUserPreferencesEvents<'posts'> implements OnInit {
  @ViewChild(SvcFiltersComponent) public svcFilters: SvcFiltersComponent;

  @Input() set sortBy(param: PostOrdenation) {
    this.selectedSortByIndex = this.sortByOptions
      .findIndex(s => s.param === param);
  }

  @Output() sortedBy = new EventEmitter<PostSort>();

  searchTerm$ = new Subject<string>();
  text: string;
  textCleared: boolean = true;
  page: PostPagination;
  selectedSortByIndex: number;
  filterFavorites: boolean;
  sortByOptions: PostSort[] = [
    {
      param: "MyBookmarked",
      type: 0,
      title: 'Favoritos',
      icon: 'heroicons_solid:star'
    },
    {
      param: 'MyHashtags',
      type: 1,
      title: 'Minhas Hashtags',
      icon: 'heroicons_solid:hashtag'
    },
    {
      param: 'OnlyMySite',
      type: 2,
      title: 'Meu Site',
      icon: 'heroicons_solid:map-pin'
    },
    {
      param: 'AllSites',
      type: 3,
      title: 'Todos',
      icon: 'heroicons_solid:globe-americas'
    },
  ]
  hasFilter: boolean;
  filter!: PostFilters;
  hashTagIdClicked = false;
  loading = false;

  public filtersOptions: {
    usersOptions: ISvcChipOption[];
    siteOptions: ISvcChipOption[];
    availableHashtags: ISvcChipGroupedOption[];
  };

  private filtersAfterClosedSubscription: Subscription;
  public filtersField: SvcFilterField[];

  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dialogService: SvcDialogService,
    private _postService: PostService,
    private _userHashtagsService: UserHashtagsService,
    private _userService: UserService,
    private _siteService: SitesService,
    private _transloco: TranslocoService,
    private _svcFilterDialogService: SvcFilterDialogService,
    private _httpErrorService: HttpErrorService,
    private _toast: SvcToastService,
    private _userPreferencesService: UserPreferencesService,
    private _cd: ChangeDetectorRef,
  ) {
    super({
      featureName: MyworkspaceUserPreferencesFeaturesNames.MyWorkspacePostsTab,
      customPropertyKey: SvcUserPreferenceFeatureCustomPorpertyKey.Tab,
      customPropertyType: 'string',
      customPropertyValue: ['posts'],
      activeCustomProperty: 'posts',
      applicationId: StaticApplicationId.myworkspace,
      customFilter: 'HomePostsFilter'
    });
  }

  ngOnInit(): void {
    this.selectedSortByIndex = 0;
    this._postService.pageChanged$.subscribe(p => {
      this.page = p;
      this.selectedSortByIndex = this.sortByOptions.findIndex(s => s.param === this.page.ordenationOption);
    });

    this.searchTerm$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term: string) => {
        this._postService.setFilterText(term);
        this._postService.resetList();
        return this._postService.getPosts();
      })
    ).subscribe();

    this._postService.filterChanged$.subscribe(filter => {
      this.filter = filter;
      this.hasFilter = Object.keys(this.filter).some(k => {
        const value = this.filter[k];
        return value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0);
      });
    });

    this._userHashtagsService.filteredHashtagClicked$.pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      takeUntil(this.destroy$)
    ).subscribe((filter => {
      if (filter.length) {
        if (filter && Array.isArray(filter)) {
          this.filter = this.filter ?? {};
          this.filter.hashTagIds = Array.from(new Set(filter.map(t => t?.hashtagId))) ?? [];
        }
        this.hashTagIdClicked = true;
        this.hasFilter = true;
        this._postService.setFilter({ ...this.filter });

        const { hashTagIds, ...filterWithoutHashTagIds } = this.filter;
        this.setPreferenceFilter({ filterName: this.preferencesConfig.customFilter, filter: filterWithoutHashTagIds });
      } else {
        this.filter = this.filter ?? {};
        this.filter!.hashTagIds = [];
        this.hashTagIdClicked = false;
        this.hasFilter = false;
        this._postService.setFilter({ ...this.filter });
      }

      this._cd.detectChanges();
      this._postService.resetList();
      this._postService.getPosts().subscribe({
        error: () => {
          this._toast.error(this._transloco.translate(PostErrorMsg.defaultError))
        }
      });
    }));

    this.#getFiltersOptions();
    this.#getPreferences();
    this._cd.detectChanges();
  }

  #getPreferences() {
    this._svcFilterDialogService.setLoading(true);
    this._userPreferencesService.getByFeatureNames(StaticApplicationId.myworkspace, [
      MyworkspaceUserPreferencesFeaturesNames.MyWorkspacePostsTab, MyworkspaceUserPreferencesFeaturesNames.MyWorkspaceHashtagFilterPostsTab
    ]).pipe(
      tap((features: SvcUserPreferencesFeatures[]) => {
        let initials = {};
        const homePosts = features.find(x => x.featureName == MyworkspaceUserPreferencesFeaturesNames.MyWorkspacePostsTab);
        const homeHashtags = features.find(x => x.featureName == MyworkspaceUserPreferencesFeaturesNames.MyWorkspaceHashtagFilterPostsTab);

        if (homePosts) {
          const ff = (homePosts.featureFilters ?? []).find(x => x.filterName == 'HomePostsFilter');
          if (ff) {
            initials = {
              ...initials,
              originatorIds: ff?.filters?.find(x => x.filterKey === 'originatorIds')?.filterValue,
              siteIds: ff?.filters?.find(x => x.filterKey === 'siteIds')?.filterValue.map(x => parseInt(x)),
              hashTagIds: ff?.filters?.find(x => x.filterKey === 'hashTagIds')?.filterValue.map(x => parseInt(x)),
            };
            if (ff?.filters?.filter(x => ['inclusionStartDate', 'inclusionEndDate'].includes(x.filterKey) && x.filterValue?.length > 0)?.length > 1) {
              initials = {
                ...initials,
                inclusionStartDate: ff.filters.find(x => x.filterKey === 'inclusionStartDate').filterValue[0],
                inclusionEndDate: ff.filters.find(x => x.filterKey === 'inclusionEndDate').filterValue[0],
              };
            }
          }
        }

        if (homeHashtags) {
          const ff = (homeHashtags.featureFilters ?? []).find(x => x.filterName == 'HomeHashtagFilterPostsTab');
          if (ff) {
            this.hashTagIdClicked = true;
            initials = {
              ...initials,
              hashTagIds: ff?.filters?.find(x => x.filterKey === 'hashTagIds')?.filterValue.map(x => parseInt(x)),
            };
          }
        }

        this.filter = initials;
        this.hasFilter = Object.keys(this.filter).some(key => ['originatorIds', 'siteIds', 'hashTagIds', 'inclusionStartDate', 'inclusionEndDate'].includes(key));
        this.#mountFilters(initials);
        this._postService.setFilter(initials);
        this._postService.resetList();
        this._postService.getPosts().subscribe({
          error: () => {
            this._toast.error(this._transloco.translate(PostErrorMsg.defaultError))
          }
        });
      }),
      catchError((error) => {
        this._toast.error(this._transloco.translate(this._httpErrorService.getErrorMessage(error)));
        return error;
      }),
      finalize(() => this._svcFilterDialogService.setLoading(false))
    ).subscribe();
  }

  #mountFilters(initials?: {
    originatorIds?: string[];
    siteIds?: number[];
    hashTagIds?: string[];
    inclusionStartDate?: string;
    inclusionEndDate?: string;
  }): void {
    let initialOriginatorIds = [...(initials?.originatorIds ?? [])];
    let initialSiteIds = [...(initials?.siteIds ?? [])];
    let initialHashTagIds = [...(initials?.hashTagIds ?? [])];
    let initialInclusionStartDate = initials?.inclusionStartDate;
    let initialInclusionEndDate = initials?.inclusionEndDate;

    this.filtersField = [
      {
        name: 'originatorIds',
        label: this._transloco.translate('Usuários'),
        type: 'chip',
        initialValue: initialOriginatorIds,
        config: {
          options: this.filtersOptions?.usersOptions,
        },
      },
      {
        name: 'siteIds',
        label: this._transloco.translate('Sites'),
        type: 'chip',
        initialValue: initialSiteIds,
        config: {
          options: this.filtersOptions?.siteOptions,
        },
      },
      {
        name: 'inclusionStartDate',
        label: this._transloco.translate('Data de envio'),
        initialValue: initialInclusionStartDate,
        type: 'date',
        dependsAnother: 'inclusionEndDate',
        config: {
          maxDate: new Date(),
        },
      },
      {
        name: 'inclusionEndDate',
        initialValue: initialInclusionEndDate,
        label: ' ',
        type: 'date',
        dependsAnother: 'inclusionStartDate',
        config: {
          maxDate: new Date()
        },
      },
      {
        name: 'hashTagIds',
        label: this._transloco.translate('Hashtags'),
        type: 'chip-grouped',
        initialValue: initialHashTagIds,
        config: {
          showCheckbox: true,
          options: this.filtersOptions?.availableHashtags,
        },
        disabled: this.hashTagIdClicked,
      },
    ]
  }

  selectSortBy(sort: PostSort) {
    this.selectedSortByIndex = this.sortByOptions.findIndex(s => s.param === sort.param);
    this._postService.resetList();
    this._postService.setOrder(sort.param);
    this.sortedBy.emit(sort);
    this._postService.getPosts().subscribe();
  }

  toggleFilters() {
    this.#getFiltersOptions();

    this._svcFilterDialogService.openFilterModal(
      {
        loading: !(this.filtersOptions),
        filtersField: [
          {
            name: 'originatorIds',
            label: this._transloco.translate('Usuários'),
            type: 'chip',
            initialValue: this.filter?.originatorIds?.map(t => t) ?? [],
            config: {
              options: this.filtersOptions?.usersOptions,
            },
          },
          {
            name: 'siteIds',
            label: this._transloco.translate('Sites'),
            type: 'chip',
            initialValue: this.filter?.siteIds?.map(t => t) ?? [],
            config: {
              options: this.filtersOptions?.siteOptions,
            },
          },
          {
            name: 'inclusionStartDate',
            label: this._transloco.translate('Data de envio'),
            initialValue: this.filter?.inclusionStartDate,
            type: 'date',
            dependsAnother: 'inclusionEndDate',
            config: {
              maxDate: new Date(),
            },
          },
          {
            name: 'inclusionEndDate',
            initialValue: this.filter?.inclusionEndDate,
            label: ' ',
            type: 'date',
            dependsAnother: 'inclusionStartDate',
            config: {
              maxDate: new Date()
            },
          },
          {
            name: 'hashTagIds',
            label: this._transloco.translate('Hashtags'),
            type: 'chip-grouped',
            initialValue: this.filter?.hashTagIds?.map(t => t) ?? [],
            config: {
              showCheckbox: true,
              options: this.filtersOptions?.availableHashtags,
            },
            disabled: this.hashTagIdClicked,
          },
        ],
        fieldsPerRow: {
          xs: 1,
          sm: 2,
          md: 2,
          xl: 2,
          lg: 2,
        },
      }
    );

    this.filtersAfterClosedSubscription = this._svcFilterDialogService.onFilterDefined.subscribe(filter => {
      this.filtersAfterClosedSubscription.unsubscribe();
      if (filter.reason === "clear") {
        this._postService.setFilter({});
        this.clearPreferenceFilter(this.preferencesConfig.customFilter);
        this._userHashtagsService.clearHashtagPreferenceFilter(true);

        this._postService.resetList();
        this._postService.getPosts().subscribe({
          error: () => {
            this._toast.error(this._transloco.translate(PostErrorMsg.defaultError))
          }
        });
      }
      else if (filter.reason === "define") {
        this._postService.setFilter(filter.filter.values);
        // Se estiver disable é porque veio do filtro de hashtag, portanto não salva como preferência
        const filters = filter.filter.filters;
        const hasDisabledHashTag = filters.some(element => element.name === 'hashTagIds' && element.disabled);
        const filterToApply = hasDisabledHashTag ? (() => {
          const { hashTagIds, ...filterWithoutHashTagIds } = this.filter;
          return filterWithoutHashTagIds;
        })() : this.filter;

        this.setPreferenceFilter({ filterName: this.preferencesConfig.customFilter, filter: filterToApply });
        this._postService.resetList();
        this._postService.getPosts().subscribe({
          error: () => {
            this._toast.error(this._transloco.translate(PostErrorMsg.defaultError))
          }
        });
      }
    });
  }

  #getFiltersOptions(): void {
    this._svcFilterDialogService.setLoading(true);
    forkJoin({
      users: this._userService.getUsers(),
      sites: this._siteService.getAll(),
      hashtags: this._userHashtagsService.getAvailableHashtags()
    }).pipe(
      map((response) => {
        return {
          usersOptions: this.#mapUsers(response.users),
          siteOptions: this.#mapSites(response.sites),
          availableHashtags: this.#mapHashtags(response.hashtags),
        };
      }),
      tap((filtersOptions) => {
        this.filtersOptions = filtersOptions;
        this._svcFilterDialogService.setFilterOptions('originatorIds', this.filtersOptions?.usersOptions);
        this._svcFilterDialogService.setFilterOptions('siteIds', this.filtersOptions?.siteOptions);
        this._svcFilterDialogService.setFilterOptions('hashTagIds', this.filtersOptions?.availableHashtags);
      }),
      catchError((error) => {
        this._svcFilterDialogService.setLoading(false);
        this._httpErrorService.showErrorInToast(error);
        return error;
      }),
      finalize(() => {
        this._svcFilterDialogService.setLoading(false);
      })
    ).subscribe();
  }

  #mapUsers(users) {
    return users.map(r => ({
      value: r.userId,
      label: r.name
    }));
  }

  #mapSites(sites) {
    return sites.map(r => ({
      value: r.siteId,
      label: r.siteName
    }));
  }

  #mapHashtags(hashtags) {
    return hashtags.map(group => ({
      group: group.siteName,
      options: group.types.map(t => ({
        group: t.typeName,
        options: t.hashtags.map((ht) => ({
          label: ht.name,
          value: ht.hashtagId,
        })),
      })),
    }));
  }

  filterByText(searchTerm: string) {
    const term = this.#clearText(searchTerm);
    if (term.length < 3) {
      if (!this.textCleared) {
        this.textCleared = true;
        this._postService.setFilterText(null);
        this._postService.resetList();
        this._postService.getPosts().subscribe();
      }
      return;
    }

    this.textCleared = false;
    this.searchTerm$.next(term);
  }

  toggleFavorites() {
    this.filterFavorites = !this.filterFavorites;
    this._postService.setFilterFavorites(this.filterFavorites);
    this._postService.resetList();
    this._postService.getPosts().subscribe();
  }

  #clearText(string: string) {
    if (string.charAt(0) === '@' || string.charAt(0) === '#') {
      return string.substring(1);
    }
    return string;
  }
}
