import { NgModule } from '@angular/core';
import { TranslocoModule } from "@ngneat/transloco";
import { AgVirtualScrollModule } from "ag-virtual-scroll";
import { QuillEditorComponent } from "ngx-quill";
import { LibSharedCommonModule } from 'projects/lib-shared-common/src/public-api';
import {
  LibSharedComponentModule,
  SvcButtonsModule,
  SvcCarouselModule,
  SvcControlsModule,
  SvcDialogsModule,
  SvcFiltersModule,
  SvcLoadersModule,
  SvcPriorityButtonModule,
  SvcSearchFieldModule,
  SvcUserAvatarModule,
  SvcValidationMessageModule
} from 'projects/lib-shared-component/src/public-api';
import { UserModule } from 'projects/lib-shared-core/src/public-api';
import { SvcCommentsModule } from "../svc-comments/svc-comments.module";
import { SvcReactionModule } from "../svc-reaction/svc-reaction.module";
import { SvcTotalsStatusModule } from '../svc-totals-status/svc-totals-status.module';
import { SvcModalShareComponent } from "./components/svc-modal-share/svc-modal-share.component";
import { SvcPostFilterComponent } from "./components/svc-post-filter/svc-post-filter.component";
import { SvcPostFormModalComponent } from "./components/svc-post-form-modal/svc-post-form-modal.component";
import { SvcPostFormComponent } from "./components/svc-post-form/svc-post-form.component";
import { SvcPostItemFooterComponent } from './components/svc-post-item-footer/svc-post-item-footer.component';
import { SvcPostItemTitleComponent } from './components/svc-post-item-title/svc-post-item-title.component';
import { SvcPostItemComponent } from "./components/svc-post-item/svc-post-item.component";
import { SvcPostMentionComponent } from './components/svc-post-mention/svc-post-mention.component';
import { SvcPostPageComponent } from "./components/svc-post-page/svc-post-page.component";
import { SvcPostTranslateComponent } from './components/svc-post-translate/svc-post-translate.component';
import { SvcPostViewModalComponent } from './components/svc-post-view-modal/svc-post-view-modal.component';
import { SvcSharedListModalComponent } from './components/svc-shared-list-modal/svc-shared-list-modal.component';
import { PostService } from "./services/post.service";
import { SvcPostsHttpClient } from "./svc-posts-http-client";
import { SvcSmartUserAvatarModule } from '../svc-smart-user-avatar/svc-smart-user-avatar.module';
import { SvcBookmarkButtonModule } from '../svc-bookmark-button/svc-bookmark-button.module';


@NgModule({
  declarations: [
    SvcPostFormComponent,
    SvcPostPageComponent,
    SvcPostFilterComponent,
    SvcPostItemComponent,
    SvcPostFormModalComponent,
    SvcModalShareComponent,
    SvcPostViewModalComponent,
    SvcPostItemTitleComponent,
    SvcPostItemFooterComponent,
    SvcPostMentionComponent,
    SvcPostTranslateComponent,
    SvcSharedListModalComponent,
  ],
  imports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcPriorityButtonModule,
    SvcDialogsModule,
    SvcButtonsModule,
    SvcControlsModule,
    SvcUserAvatarModule,
    SvcCarouselModule,
    SvcReactionModule,
    SvcLoadersModule,
    AgVirtualScrollModule,
    SvcFiltersModule,
    UserModule,
    QuillEditorComponent,
    SvcValidationMessageModule,
    SvcCommentsModule,
    SvcTotalsStatusModule,
    SvcSearchFieldModule,
    SvcSmartUserAvatarModule,
    SvcBookmarkButtonModule,
  ],
  exports: [
    LibSharedCommonModule,
    LibSharedComponentModule,
    TranslocoModule,
    SvcPriorityButtonModule,
    SvcDialogsModule,
    SvcButtonsModule,
    SvcControlsModule,
    SvcUserAvatarModule,
    SvcCarouselModule,
    SvcReactionModule,
    SvcLoadersModule,
    AgVirtualScrollModule,
    SvcFiltersModule,
    UserModule,
    QuillEditorComponent,
    SvcValidationMessageModule,
    SvcCommentsModule,
    SvcPostFormComponent,
    SvcPostPageComponent,
    SvcPostFilterComponent,
    SvcPostItemComponent,
    SvcPostFormModalComponent,
    SvcModalShareComponent,
    SvcPostViewModalComponent,
    SvcPostItemTitleComponent,
    SvcPostItemFooterComponent,
    SvcPostMentionComponent,
    SvcPostTranslateComponent,
    SvcSharedListModalComponent,
  ],
  providers: [
    SvcPostsHttpClient,
    PostService
  ]
})
export class SvcPostsStandaloneModule { }
